import { useState, useEffect, useContext, createContext } from "react";

import axios from "axios";
import arrivals_data from '../data/arrival.js';
import city_data from '../data/data.js';

const ArrivalContext = createContext();
const filter_str = [
    "British Airways",//ok
    "Air France",//OK
    "Alitalia",//
    "SWISS",//ok
    "South Africa Airways",//
    "Ghana Airways",//
    "Ethiopian Airlines",//ok
    "American Airlines",//ok
    "Delta Air lines",//ok
    "KLM",//ok
    "Royal Air Maroc",//ok
    "Air India",//
    "Air Cote Divoire",//
    "EMIRATES",//
    "Singapore Airlines",//
    "Qatar Airways",//ok
    "Turkish Airlines",//ok
    "Air Peace",
    "Lufthansa"//ok
];

const ArrivalProvider = ({ children }) => {
    //company name,arrival,image_path,airport
    const [idata, setidata] = useState([]);

    useEffect(() => {
        updateData();
        setInterval(() => {
            updateData();
        }, 600000);
        // console.log(arrivals_data.flightRecords)
        // let temp = [...arrivals_data.flightRecords.filter(val => filter_str.findIndex((str) => str === val.airlineName) !== -1)];
        // let temp1 = [...city_data];
        // for (let index = 0; index < temp.length; index++) {
        //     const element = temp[index];
        //     let name = element.destinationAirportCode;
        //     let indexNumber = temp1.findIndex(val => val["IATA code"] === name);
        //     if (indexNumber !== -1) {
        //         temp[index].destinationAirportCode = temp1[indexNumber]["City/Airport"]
        //     }
        // }
        // temp.sort(function (a, b) { return (new Date(a.scheduledTime) - new Date(b.scheduledTime)) });
        // temp.reverse();
        // setidata(temp);

    }, []);

    const updateData = () => {
        fetchDataWithToken();
    }

    const fetchDataWithToken = async () => {
        const token = await getClientCredentialsToken();

        if (!token) {
            console.log('Failed to retrieve access token.');
            return;
        }

        const apiUrl = 'https://sitaopen.api.aero/fids/v1/LOS/A?includeAirlines=BA%2CAF%2CLX%2CSA%2CET%2CAA%2CDL%2CUA%2CKL%2CAT%2CAI%2CP4%2CEK%2CSQ%2CQR%2CTK%2CLH%2CUR%2CQI%2CAW&pastWindow=4&futureWindow=8&maxFlights=100&lateMinutes=15&showCargo=false&includeCodeShares=true';

        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // console.log('Type of fetched data:', typeof response.data);
            console.log('Data fetched successfully:', response.data);
            let temp = [...response.data["flightRecords"].filter(val => filter_str.findIndex((str) => {
                if (str === val.airlineName) {
                    return true;
                }
            }) !== -1)];
            let temp1 = [...city_data];
            let returnArray = [];
            for (let index = 0; index < temp.length; index++) {
                const element = temp[index];
                let name = element.originAirportCode;
                let indexNumber = temp1.findIndex(val => val["IATA code"] === name);
                if (indexNumber !== -1) {
                    let returnvalue = { ...temp[index], displayname: temp1[indexNumber]["City/Airport"] }
                    returnArray.push(returnvalue);
                    // returnArray.push(temp[index]);
                    // temp[index].originAirportCode = temp1[indexNumber]["City/Airport"]
                }
            }
            // temp.sort(function (a, b) { return (new Date(b.scheduledTime) - new Date(a.scheduledTime)) });
            // temp.reverse()
            returnArray.sort(function (a, b) { return (new Date(b.scheduledTime) - new Date(a.scheduledTime)) });
            returnArray.reverse()
            setidata(returnArray);

        } catch (error) {
            console.error('Error fetching data:', error.response ? error.response.data : error.message);
        }
    }

    const getClientCredentialsToken = async () => {
        const tokenUrl = 'https://sitaopen.api.aero/fids/oauth/token';
        const clientId = "QFyfLTt3fTKKM2FYOByE8AIv4cYwX8NW";     // Use environment variables in production
        const clientSecret = "le4N1Nz9ICapKRk8";

        const headers = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${clientId}:${clientSecret}`)}`
            }
        };

        const data = 'grant_type=client_credentials';

        try {
            const response = await axios.post(tokenUrl, data, headers);
            // console.log("Access Token Retrieved Successfully:", response.data.access_token);
            console.log("token success success");
            return response.data.access_token;  // Return the access token
        } catch (error) {
            console.error('Error fetching OAuth token', error.response ? error.response.data : error.message);
            return null;  // Return null if there is an error
        }
    };

    return (
        <ArrivalContext.Provider value={[idata, setidata]}>
            {children}
        </ArrivalContext.Provider>
    );
};

const useArrival = () => useContext(ArrivalContext);

export { useArrival, ArrivalProvider };
