// import { Airport } from "@mui/icons-material";

const initialState = {
    Airport: {
        airport: "",
        terminal: "",
        wing: "",
        set: false
    }
}

const AirportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "Set_Airport_Data":
            return {
                ...state,
                Airport: action.data
            }
        default:
            return state;
    }
}

export default AirportReducer;