import * as React from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useState } from 'react';

import { AppBar, Box, Toolbar, Button, Grid, MenuItem, Select, Checkbox, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  UploadBox, ShowUploadFilesBox, Search,
  StyledSelect, SearchIconWrapper, StyledInputBase,
  StyledTextFiled, StyledTableCell, StyledTableRow
} from './styled'

import { updateImagesData } from '../data/other';


const UpdateStatus = () => {

  const statusArray = ["Boarding", "Gate Closed", "Last Call","Departed"];
  const [status, setStatus] = useState(0);
  const [FlightNumber, setFlightNumber] = useState("");
  const [updateImages, setUpdateImages] = useState(updateImagesData);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (FlightNumber === "") {
      toast.error("You must fill input");
    }
    else {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/status`, { FlightNumber: FlightNumber, Status: statusArray[status] }).then(res => {
        if (res.data.success) {
          toast.success("Success change flight number");
        }

      }).catch(err => {
        console.error(err)
      })
    }
    setFlightNumber("");
  }

  const handleImageStatusChange = (id, index) => {
    const array = updateImages;
    array[index].status = !array[index].status;
    setUpdateImages([...array])
  }


  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{backgroundColor:"#36414f"}}>
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Toolbar>
        </AppBar>
      </Box>
      {/* {images} */}
      <Box mt={4}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {
            updateImages?.map((img, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "4", justifyContent: "center" }}>
                    <img src={"/image/updateImages/" + img.AirName + ".png"} alt={img.AirName} style={{ border: "3px solid #6b6e72", borderRadius: "7px", width: "50%" }} />
                    <Checkbox
                      checked={img.status}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 60 } }}
                      onChange={() => handleImageStatusChange(img._id, index)}
                    />
                  </Box>
                </Grid>
              )
            })
          }
        </Grid>
      </Box >
      {/* select option */}
      < Box mt={5}>
        <Typography variant='caption'>Enter Flight Number</Typography>
        <StyledTextFiled value={FlightNumber} placeholder='Enter Flight Number' sx={{ mt: 1, mb: 2 }} onChange={(e) => { setFlightNumber(e.target.value) }}></StyledTextFiled>
      </Box>
      <Box mt={5}>
        <Typography sx={{ fontSize: 25, color: "#5f656c" }}>Select Status</Typography>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={status}
          label="Age"
          sx={{ width: "100%" }}
          onChange={handleStatusChange}
        >
          {
            statusArray.map((status, index) => {
              return (
                <MenuItem key={index} value={index}>{statusArray[index]}</MenuItem>
              )
            })
          }
        </Select>
      </Box>
      {/* {Buttons} */}
      <Grid sx={{ flexGrow: 1, marginTop: 5, display: 'inline-block' }} container spacing={1}>
        <Grid item sx={{ float: 'right' }}>
          <Button variant='contained' sx={{ width: '150px', padding: '10px' }} type='submit' onClick={handleSubmit}>Save</Button>
        </Grid>
        <Grid item sx={{ float: 'right' }}>
          <Button variant='outlined' sx={{ width: '150px', padding: '10px' }} >Cancel</Button>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateStatus;
