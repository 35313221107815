import axios from "axios";
import { io } from 'socket.io-client';
import toast from "react-hot-toast";

const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });
const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

export const getDepartureData = () => {
    return dispath => {
        AxiosAPI.get('/api/departure').then((res) => {
            if (res.data.success) {
                let data_array = [...res.data.data];                
                dispath({
                    type: "Set_Departure_Data",
                    data: data_array
                })
            }
            else {
                toast.success(res.data.message);
            }
        });
    }

}

export const setDepartureData = (returnArray) => {
    return dispatch => {
        let data_array = [...returnArray];
        dispatch({
            type: "Set_Departure_Data",
            data: data_array
        })
    }


}

export const deleteDepartureData = (id) => {
    return dispath => {
        AxiosAPI.delete(`/api/departure/${id}`).then(res => {
            if (res.data.success) {
                dispath({
                    type: "Set_Departure_Data",
                    data: res.data.data
                })
            }
        });
    }
}

export const addDepartureData = (formData) => {
    return dispath => {
        AxiosAPI.post(`/api/upload`, formData).then(res => {
            let resp = [...res.data.data];
            let array_data = [];
            resp.forEach((file, index) => {
                let data = {
                    Id: resp._id,
                    CompanyName: resp.companyName,
                    Airport: resp.Airport,
                    Wing: "arrival",
                    Image: resp.uploadFile,
                    Type: resp.type
                };
                array_data.push(data);
                if (index === (resp.length - 1)) {
                    dispath({
                        type: "Set_Departure_Data",
                        data: array_data
                    })
                }
            })
        });

    }
}