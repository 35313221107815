import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/Auth';
import toast from 'react-hot-toast';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Grid, Typography } from '@mui/material';

import {
  UploadBox, ShowUploadFilesBox, Search,
  StyledSelect, SearchIconWrapper, StyledInputBase,
  StyledTextFiled, StyledTableCell, StyledTableRow
} from './styled'

const Login = () => {

  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [auth, setAuth] = useAuth();

  const handleSubmit = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
      email: email,
      password: password
    }).then(res => {
      if (res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.data.email,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/admin");
      }
      else {
        toast.error(res.data.message);
      }
    }).catch(err => {
      toast.error(err.response.data.message);
    });

  }

  return (
    <Box sx={{ ml: '25%', mr: '25%' }}>
      <Grid container width="100%">
        <Grid container width="100%">
          <Grid container width="100%" columns={2} spacing={10}>
            <Grid item width="30%">
              <Typography sx={{ width: '100%', fontSize: '24px', marginTop: "10px" }}>Username</Typography>
            </Grid>
            <Grid item width="70%">
              <StyledTextFiled value={email} onChange={e => setemail(e.target.value)} type='email' placeholder='Enter Username' sx={{ mt: 1, mb: 2 }} required></StyledTextFiled>
            </Grid>
          </Grid>
          <Grid container width="100%" columns={2} spacing={10}>
            <Grid item width="30%">
              <Typography sx={{ fontSize: '24px', marginTop: "10px" }}>Password</Typography>
            </Grid>
            <Grid item width="70%">
              <StyledTextFiled value={password} onChange={e => setpassword(e.target.value)} type='password' placeholder='Enter Password' sx={{ mt: 1, mb: 2 }} required></StyledTextFiled>
            </Grid>
          </Grid>
          <Grid sx={{ flexGrow: 1, marginTop: 3, marginRight: '80px', display: 'inline-block' }} container spacing={1}>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='outlined' sx={{ width: '150px', padding: '10px' }} >Cancel</Button>
            </Grid>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='contained' sx={{ width: '150px', padding: '10px' }} onClick={handleSubmit}>Login</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login;