// import { Announcement } from "@mui/icons-material";

const initialState = {
    Announcement: []
}

const addArray = (state,array) => {
    let originArray = [...state];

    for (let index = 0; index < array.length; index++) {
        const element = array[index];
        originArray.push(element)
    }

    return originArray;
}

const AnnouncementReducer = (state = initialState, action) => {
    switch (action.type) {
        case "Set_Announcement_Data":
            return {
                ...state,
                Announcement: [...action.data]
            }
        case "Add_Announcement_Data":
            return {
                ...state,
                Announcement: addArray(state,action.data)
            }
        default:
            return state;
    }
}

export default AnnouncementReducer;