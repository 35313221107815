import * as React from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';

import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { DeleteForever } from '@mui/icons-material';
import { Edit } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar, Box, Paper, Toolbar, Table, TableBody, Button, TableContainer,
  TableHead, Grid, TableRow, MenuItem, IconButton, Input, Typography
} from '@mui/material';

import {
  UploadBox, ShowUploadFilesBox, Search,
  StyledSelect, SearchIconWrapper, StyledInputBase,
  StyledTextFiled, StyledTableCell, StyledTableRow
} from './styled';

import {
  getAnnouncementData,
  deleteAnnouncementData,
  addAnnouncementData
} from "../Redux/Actions/Announcement/index.js";

import { rows, columns } from '../data/other';


const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

const Special = () => {
  const Announcement = useSelector((state) => state.Announcement.Announcement);
  const dispatch = useDispatch();

  const [Post, setPost] = useState("");
  const [Topic, setTopic] = useState("");
  const [Type, setType] = useState("");
  const [numberType, setnumberType] = useState("hour");
  const [Expire, setExpire] = useState(0);
  const [Type_array, setType_array] = useState(["Special Announcement", "Emergency"]);
  const [AirportInfo, setAirportInfo] = useState([]);

  const handleNumber = (e) => {
    setExpire(Number(e.target.value));
    e.target.value = Number(Expire).toString();
  }

  const [state, setstate] = useState({
    title: "Save",
    color: "blue"
  });

  useEffect(() => {
    dispatch(getAnnouncementData());
  }, []);

  const deleteSubmit = (e, id) => {
    e.preventDefault();
    dispatch(deleteAnnouncementData(id));
  }

  const EditSubmit = (e, row) => {
    e.preventDefault();
    setstate({
      title: "Update",
      color: "red"
    })
    setTopic(row.Topic);
    setPost(row.Post);
    setType(row.Type);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Post === "" || Topic === "" || Type === "" || Expire === "") {
      toast.error("you must fill input");
    }
    else {
      let newArray = []

      for (let index = 0; index < AirportInfo.length; index++) {
        const element = rows[AirportInfo[index] - 1];
        newArray.push(element)
      }

      if (state.title === "Update") {
        setstate({ title: "Save", color: "blue" });
      }

      dispatch(addAnnouncementData(Topic, Expire, Post, Type, newArray));

      setTopic("");
      setPost("");
      setType("");
      setExpire("");

    }
  }


  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {/* <ThemeProvider theme={darkTheme}> */}
        <AppBar position="static" sx={{backgroundColor:"#36414f"}} >
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Toolbar>
        </AppBar>
        {/* </ThemeProvider> */}
      </Box>
      {/* Setting Box */}
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        < Box sx={{ pr: 20, pl: 20, marginTop: 5 }}>
          <Typography variant='caption'>Topic</Typography>
          <StyledTextFiled value={Topic} placeholder='Enter Topic' sx={{ mt: 1, mb: 2 }} onChange={(e) => { setTopic(e.target.value) }}></StyledTextFiled>
          <Typography variant='caption'>Post</Typography>
          <StyledTextFiled value={Post} placeholder='Enter Post Value' sx={{ mt: 1, mb: 2 }} onChange={(e) => { setPost(e.target.value) }}></StyledTextFiled>
          <Typography variant='caption'>Type</Typography>
          <StyledSelect
            value={Type}
            onChange={(e) => { setType(e.target.value) }}>
            {Type_array.map((row, index) => <MenuItem key={index} value={row}>{row}</MenuItem>)}
          </StyledSelect>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Typography variant='caption'>Expire Date</Typography>
            <Input type='number' style={{ marginLeft: "10vh" }} value={Expire} onChange={e => handleNumber(e)} />
            <div style={{ width: "100px" }}>
              <StyledSelect
                value={numberType}
                onChange={(e) => { setnumberType(e.target.value) }}>
                <MenuItem value={"hour"}>hour</MenuItem>
                <MenuItem value={"minute"}>minute</MenuItem>
              </StyledSelect>
            </div>
          </div>


          <Grid sx={{ marginTop: "30px" }}>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={8}
                // hideFooterSelectedRowCount
                hideFooterPagination
                disableColumnMenu
                onRowSelectionModelChange={e => setAirportInfo(e)}
                pageSizeOptions={[5]}
                checkboxSelection
              />
            </div>
          </Grid>
          <Grid sx={{ flexGrow: 1, marginTop: 3, display: 'inline-block' }} container spacing={1}>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='contained' sx={{ background: state.color, width: '150px', padding: '10px' }} type='submit'>{state.title}</Button>
            </Grid>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='outlined' sx={{ width: '150px', padding: '10px' }} >Cancel</Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      {/* Table */}

      < Box sx={{ pr: 3, pl: 3, marginTop: 8 }}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Topic</StyledTableCell>
                <StyledTableCell>Post Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Expire Date</StyledTableCell>
                <StyledTableCell>Edit</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Announcement.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.Topic}</StyledTableCell>
                  <StyledTableCell>{row.PostDate}</StyledTableCell>
                  <StyledTableCell>
                    {/* <ThemeProvider theme={darkTheme(row.Stauts)}> */}
                    <Button variant='outlined' size='small'>
                      {row.Stauts}
                    </Button>
                    {/* </ThemeProvider> */}
                  </StyledTableCell>
                  <StyledTableCell>{new Date(row.Expire).toString()}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={e => EditSubmit(e, row)}>
                      <Edit />
                    </IconButton>
                  </StyledTableCell>

                  <StyledTableCell>
                    <IconButton disabled={state.title === "Update"} onClick={e => deleteSubmit(e, row._id)}>
                      <DeleteForever />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              )) }
            </TableBody>
          </Table>
        </TableContainer>
      </Box >
    </>
  );
}

export default Special;

