import { Routes, Route } from "react-router-dom";

import Departure from './user/Departure';
import Arrival from './user/Arrival';
import FirstPage from "./user/FirstPage";
import AdminPage from "./admin/AdminPage";
import Login from "./admin/Login";

import { ArrivalProvider } from "./context/Arrival";
import { DepartureProvider } from "./context/Departure";
import { AuthProvider } from "./context/Auth";
import PrivateRoute from "./admin/Private/Private";

import { Provider } from "react-redux";

import store from "./Redux/StoreConfig/store.js";


export default function Router() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <ArrivalProvider>
          <DepartureProvider>
            <Routes>
              <Route path="/" element={<FirstPage />} />
              <Route path="/departure" element={<Departure />} />
              <Route path="/arrival" element={<Arrival />} />
              <Route path="/admin" element={<PrivateRoute />} >
                <Route path="" element={<AdminPage />} />
              </Route>
              <Route path="/login" element={< Login />} />
            </Routes>
          </DepartureProvider>
        </ArrivalProvider>
      </AuthProvider>
    </Provider>

  );
}
