// import { Departure } from "@mui/icons-material";

const initialState = {
    Departure: []
}

const DepartureReducer = (state = initialState, action) => {
    switch (action.type) {
        case "Set_Departure_Data":
            return {
                ...state,
                Departure: action.data
            }
        default:
            return state;
    }
}

export default DepartureReducer;