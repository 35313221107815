import * as React from 'react';
import { useDropzone } from "react-dropzone"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import axios from 'axios';

import SearchIcon from '@mui/icons-material/Search';
import { DeleteForever } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import {
  AppBar, Box, Paper, Toolbar, Table, TableBody, Button, TableContainer,
  TableHead, Grid, TableRow, MenuItem, Checkbox, Typography
} from '@mui/material';
import {
  UploadBox, ShowUploadFilesBox, Search,
  StyledSelect, SearchIconWrapper, StyledInputBase,
  StyledTextFiled, StyledTableCell, StyledTableRow
} from './styled'

import { getDepartureData, deleteDepartureData } from '../Redux/Actions/Departure/index.js';
import { getArrivalData, addArrivalData, deleteArrivalData } from '../Redux/Actions/Arrival/index.js';

import PasswordDialog from '../components/PasswordDialog';
import FuseSvgIcon from '../@fuse/FuseSvgIcon';


const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });


const ManageAds = () => {
  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    { field: "airport", headerName: "Airport", width: 130 },
    {
      field: "wing",
      headerName: "Wing",
      width: 130,
      renderCell: (r, v, f) => {
        return (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row.wing}</span>
            <Checkbox
              checked={isWingChecked(r.row, r.row[r.field])}
              onClick={(e) => handleWingClick(e.target.checked, r.row)}
            />
          </span>
        );
      },
    },
    {
      field: "screenName1",
      headerName: "Screen Name",
      width: 130,
      renderCell: (r, v, f) => {
        return r.row[r.field] ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row[r.field]}</span>
            <Checkbox
              checked={isScreenChecked(r.row, r.row[r.field])}
              onClick={(e) =>
                handleScreenClick(e.target.checked, r.row, r.row[r.field])
              }
            />
          </span>
        ) : (
          ""
        );
      },
    },
    {
      field: "screenName2",
      headerName: "Screen Name",
      width: 130,
      renderCell: (r, v, f) => {
        return r.row[r.field] ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row[r.field]}</span>
            <Checkbox
              checked={isScreenChecked(r.row, r.row[r.field])}
              onClick={(e) =>
                handleScreenClick(e.target.checked, r.row, r.row[r.field])
              }
            />
          </span>
        ) : (
          ""
        );
      },
    },
    {
      field: "screenName3",
      headerName: "Screen Name",
      width: 130,
      renderCell: (r, v, f) => {
        return r.row[r.field] ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row[r.field]}</span>
            <Checkbox
              checked={isScreenChecked(r.row, r.field)}
              onClick={(e) =>
                handleScreenClick(e.target.checked, r.row, r.field)
              }
            />
          </span>
        ) : (
          ""
        );
      },
    },
    {
      field: "screenName4",
      headerName: "Screen Name",
      width: 130,
      renderCell: (r, v, f) => {
        return r.row[r.field] ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row[r.field]}</span>
            <Checkbox
              checked={isScreenChecked(r.row, r.field)}
              onClick={(e) =>
                handleScreenClick(e.target.checked, r.row, r.field)
              }
            />
          </span>
        ) : (
          ""
        );
      },
    },
    {
      field: "screenName5",
      headerName: "Screen Name",
      width: 130,
      renderCell: (r, v, f) => {
        return r.row[r.field] ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row[r.field]}</span>
            <Checkbox
              checked={isScreenChecked(r.row, r.field)}
              onClick={(e) =>
                handleScreenClick(e.target.checked, r.row, r.field)
              }
            />
          </span>
        ) : (
          ""
        );
      },
    },
    {
      field: "screenName6",
      headerName: "Screen Name",
      width: 130,
      renderCell: (r, v, f) => {
        return r.row[r.field] ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row[r.field]}</span>
            <Checkbox
              checked={isScreenChecked(r.row, r.field)}
              onClick={(e) =>
                handleScreenClick(e.target.checked, r.row, r.field)
              }
            />
          </span>
        ) : (
          ""
        );
      },
    },
    {
      field: "screenName7",
      headerName: "Screen Name",
      width: 130,
      renderCell: (r, v, f) => {
        return r.row[r.field] ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{r.row[r.field]}</span>
            <Checkbox
              checked={isScreenChecked(r.row, r.field)}
              onClick={(e) =>
                handleScreenClick(e.target.checked, r.row, r.field)
              }
            />
          </span>
        ) : (
          ""
        );
      },
    },
  ]);
  const [rows, setRows] = useState([
    {
      id: 1,
      airport: "MMA",
      wing: "arrival",
      screenName1: "sa1",
      screenName2: "sa2",
      screenName3: "sa3",
      screenName4: "sa4",
      screenName5: "sa5",
      screenName6: "sa6",

    },
    {
      id: 2,
      airport: "MMA",
      wing: "departure",
      screenName1: "td1",
      screenName2: "td2",
      screenName3: "td3",
      screenName4: "td4",
      screenName5: "td5",
      screenName6: "td6",
    },
    {
      id: 3,
      airport: "Abuja",
      wing: "arrival",
      screenName1: "ba1",
      screenName2: "ba2",
      screenName3: "ba3",
      screenName4: "ba4",
      screenName5: "ba5",
      screenName6: "ba6",
      screenName7: "ba7",
    },
    {
      id: 4, airport: "Abuja", wing: "departure",
      screenName1: "bd1",
      screenName2: "bd2",
      screenName3: "bd3",
      screenName4: "bd4",
      screenName5: "bd5",
    },
    {
      id: 5, airport: "Kano", wing: "arrival",
      screenName1: "ka1",
      screenName2: "ka2",
      screenName3: "ka3",
      screenName4: "ka4",
      screenName5: "ka5"
    },
    {
      id: 6, airport: "Kano", wing: "departure",
      screenName1: "kd1",
      screenName2: "kd2",
      screenName3: "kd3",
      screenName4: "kd4",
      screenName5: "kd5",
      screenName6: "kd6",
    },
    {
      id: 7, airport: "PHC", wing: "arrival",
      screenName1: "pa1",
      screenName2: "pa2",
      screenName3: "pa3",
      screenName4: "pa4",
      screenName5: "pa5",
      screenName6: "pa6",
    },
    {
      id: 8, airport: "PHC", wing: "departure",
      screenName1: "pd1",
      screenName2: "pd2",
      screenName3: "pd3",
      screenName4: "pd4",
      screenName5: "pd5",
      screenName6: "pd6",
    },
  ]);

  const navigate = useNavigate();
  const dispath = useDispatch();

  const [files, setFiles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [UploadType, setUploadType] = useState("image");//true=>image

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: `${UploadType}/*`,
    onDrop: acceptedFiles => {
      setFiles([...files,
      ...acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )])
    }
  });
  const thumbs = files.map((file, index) => (
    <div className="dz-thumb" key={index}>
      <div className="dz-thumb-inner">
        <img src={file.preview} className="dz-img" alt={file.name} />
      </div>
    </div>
  ));

  const isWingChecked = (row, field) => {
    return row.screens ? row.screens.length > 0 : false;
  };
  const isScreenChecked = (row, field) => {
    return row.screens ? row.screens.indexOf(field) > -1 : false;
  };
  const handleScreenClick = (flag, row, field) => {
    setRows((prev) => {
      const nitem = prev.map((item) => {
        if (item.airport == row.airport && item.wing == row.wing) {
          item.screens = flag
            ? item.screens
              ? item.screens.indexOf(field) >= 0
                ? item.screens
                : [...item.screens, field]
              : [field]
            : item.screens
              ? item.screens.filter((screen) => screen != field)
              : [];
        }
        return item;
      });
      return nitem;
    });
  };
  useEffect(() => {
    const nrows = rows.filter((row) =>
      row.screens ? row.screens.length > 0 : false
    );
    const info = nrows.map((row) => {
      return { airport: row.airport, wing: row.wing, screens: row.screens };
    });
    setAirportInfo(info)
  }, [rows]);
  const handleWingClick = (flag, row) => {
    setRows((prev) => {
      const nitem = prev.map((item) => {
        if (item.airport == row.airport && item.wing == row.wing) {
          item.screens = flag
            ? Object.keys(item).filter(
              (key) =>
                key != "id" &&
                key != "airport" &&
                key != "wing" &&
                key != "screens"
            ).map(key => item[key])
            : [];
        }
        return item;
      });
      return nitem;
    });
  };
  const [Company, setCompany] = useState([]);
  const ArrivalCompany = useSelector((state) => state.Arrival.Arrival);
  const DepartureCompany = useSelector((state) => state.Departure.Departure);

  const [companyName, setCompanyName] = useState("");
  const [AirportInfo, setAirportInfo] = useState([]);



  useEffect(() => {
    dispath(getArrivalData());
    dispath(getDepartureData());
  }, []);

  // useEffect(() => {
  // }, [ArrivalCompany]);

  useEffect(() => {
    let newArray = [];
    newArray = ArrivalCompany.concat(DepartureCompany);
    setCompany(newArray);
    // toast.success("Success connect to database");
  }, [ArrivalCompany, DepartureCompany]);

  const deleteSubmit = (id, wing) => {
    if (wing === "arrival") {
      dispath(deleteArrivalData(id));
    }
    else {
      dispath(deleteDepartureData(id));
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (companyName === "" || files.length === 0) {
      toast.error("You must fill input");
    }
    else {
      let newArray = [...AirportInfo]
      dispath(addArrivalData(files, newArray, UploadType, companyName));
      setCompanyName("");
      setFiles([]);
    }

  }
  return (
    <>
      <PasswordDialog open={openDialog} handleClose={handleCloseDialog} />
      <Box sx={{ flexGrow: 1 }}>
        {/* <ThemeProvider theme={darkTheme}> */}
        <AppBar position="static" sx={{ backgroundColor: "#36414f" }}>
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            <Button variant='contained' sx={{ marginLeft: '20px', backgroundColor: 'red', float: 'right', width: '300px', padding: '10px' }} type='submit'
              onClick={handleOpenDialog}>Change Password</Button>
            <Button variant='contained' sx={{ marginLeft: '20px', float: 'right', width: '150px', padding: '10px' }} onClick={handleLogout}>Logout</Button>
          </Toolbar>
        </AppBar>
        {/* </ThemeProvider> */}
      </Box>
      {/* Setting Box */}
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        < Box sx={{ pr: 20, pl: 20, marginTop: 5 }}>
          <Typography variant='caption'>Company name</Typography>
          <StyledTextFiled value={companyName} placeholder='Enter Company name' sx={{ mt: 1, mb: 2 }} onChange={(e) => { setCompanyName(e.target.value) }}></StyledTextFiled>
          <Typography variant='caption'>Upload File Type</Typography>
          <StyledSelect
            value={UploadType}
            onChange={(e) => { setUploadType(e.target.value) }}>
            <MenuItem key={0} value={"image"}>image</MenuItem>
            <MenuItem key={1} value={"video"}>video</MenuItem>
          </StyledSelect>
          <Typography variant='caption'>Upload file</Typography>
          <section style={{ marginTop: '10px' }}>
            <div style={{ width: '60%', }} {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} type='file' id='upload_files' />
              <UploadBox>
                <Box sx={{ marginLeft: '20px' }}>
                  <FuseSvgIcon className="text-48" size={64} color="action">feather:upload</FuseSvgIcon>
                </Box>
                <Box sx={{ width: '70%' }}>
                  <Typography sx={{ width: '100%', textAlign: 'center' }}>Choose a file or drag & drop it here</Typography>
                  <Typography sx={{ width: '100%', textAlign: 'center', color: '#ddd' }}>JPEG, PNG, PDG, and MP4 formats, up to 50MB</Typography>
                </Box>
              </UploadBox>
            </div>
            <ShowUploadFilesBox>
              {thumbs}
            </ShowUploadFilesBox>
          </section>
          <Grid sx={{ marginTop: "30px" }}>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={8}
                hideFooterSelectedRowCount
                hideFooterPagination
                disableColumnMenu
                // onRowSelectionModelChange={e => setAirportInfo(e)}
                pageSizeOptions={[5]}
              />
            </div>
          </Grid>
          <Grid sx={{ flexGrow: 1, marginTop: 3, display: 'inline-block' }} container spacing={1}>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='contained' sx={{ width: '150px', padding: '10px' }} type='submit'>Save</Button>
            </Grid>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='outlined' sx={{ width: '150px', padding: '10px' }} >Cancel</Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      {/* Table */}
      < Box sx={{ pr: 3, pl: 3, marginTop: 8 }}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Company Name</StyledTableCell>
                <StyledTableCell>Airport</StyledTableCell>
                <StyledTableCell>Wing</StyledTableCell>
                <StyledTableCell>File</StyledTableCell>
                <StyledTableCell>File Type</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Company.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.companyName}</StyledTableCell>
                  <StyledTableCell>{row.AirportInfo.airport}</StyledTableCell>
                  <StyledTableCell>
                    <Button variant='outlined' size='small'>
                      {row.AirportInfo.wing}
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>{row.uploadFile}</StyledTableCell>
                  <StyledTableCell>{row.type}</StyledTableCell>
                  <StyledTableCell>
                    <DeleteForever onClick={e => deleteSubmit(row._id, row.AirportInfo.wing)} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box >
    </>
  );
}

export default ManageAds;
