import { combineReducers } from "redux";

import AirportReducer from "./Airport/index.js";
import ArrivalReducer from "./Arrival/index.js";
import DepartureReducer from "./Departure/index.js";
import AnnouncementReducer from "./Announcement/index.js";



const rootReducer = combineReducers({
    Airport: AirportReducer,
    Arrival: ArrivalReducer,
    Departure: DepartureReducer,
    Announcement: AnnouncementReducer
});

export default rootReducer;