import axios from "axios";
import { io } from 'socket.io-client';
import toast from "react-hot-toast";

const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);
const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

export const getArrivalData = () => {
    return dispatch => {
        AxiosAPI.get('/api/arrival').then((res) => {
            if (res.data.success) {
                let data_array = [...res.data.data];
                dispatch({
                    type: "Set_Arrival_Data",
                    data: data_array
                })
            }
            else {
                // Snackbar(res.data.message);
                toast(res.data.message);
            }
        });
    }

}

export const setArrivalData = (returnArray) => {
    return dispatch => {
        let data_array = [...returnArray];

        dispatch({
            type: "Set_Arrival_Data",
            data: data_array
        })
    }
}

export const deleteArrivalData = (id) => {
    return dispatch => {
        AxiosAPI.delete(`/api/arrival/${id}`).then(res => {
            if (res.data.success) {
                let data_array = [...res.data.data];
                dispatch({
                    type: "Set_Arrival_Data",
                    data: data_array
                })
            }
        });
    }
}

export const addArrivalData = (files, newArray, UploadType, CompanyName) => {
    return dispatch => {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('photos', file);
        });
        formData.append('airportInfo', JSON.stringify(newArray));
        formData.append('fileType', UploadType);
        formData.append('companyName', CompanyName);


        AxiosAPI.post(`/api/upload`, formData).then(res => {
            let arrivalArray = [];
            let DepartureArray = [];

            let data_array = [...res.data.data];

            for (var i = 0; i < data_array.length; i++) {

                if (data_array[i].AirportInfo.wing === "arrival") {
                    arrivalArray.push(data_array[i])
                }
                else {
                    DepartureArray.push(data_array[i])
                }
            }
            dispatch({
                type: "Set_Arrival_Data",
                data: arrivalArray
            })
            dispatch({
                type: "Set_Departure_Data",
                data: DepartureArray
            })
        });

    }
}