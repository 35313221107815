import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

import SearchIcon from '@mui/icons-material/Search';
import { DeleteForever } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { blue } from '@mui/material/colors';

import {
  AppBar, Box, Paper, Toolbar, Table, TableBody, Button, TableContainer,
  TableHead, Grid, TableRow, IconButton, Typography
} from '@mui/material';

import {
  Search, SearchIconWrapper, StyledInputBase,
  StyledTextFiled, StyledTableCell, StyledTableRow
} from './styled'

import { rows, columns } from '../data/other';

const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

const Accessibility = () => {

  const [Number, setNumber] = useState("");
  const [AirportInfo, setAirportInfo] = useState([]);
  const [tableData, settableData] = useState([]);

  const update = () => {
    AxiosAPI.get('/api/number').then((res) => {
      if (res.data.success) {
        let data_array = [...res.data.data];
        settableData(data_array);
      }
      else {
        alert(res.data.message);
      }
    });
  }

  useEffect(() => {
    toast.success("Success connect to database");
    update();
  }, []);


  const deleteSubmit = (id) => {

    AxiosAPI.delete(`/api/number/${id}`).then(res => {
      if (res.data.success) {
        settableData(tableData.filter(val => val._id !== id))
      }
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let newArray = []
    if (Number === "") {
      toast.error("You must fill input");
    }
    else {
      for (let index = 0; index < AirportInfo.length; index++) {
        const element = rows[AirportInfo[index] - 1];
        newArray.push(element)
      }

      AxiosAPI.post("/api/number", { number: Number, airportInfo: newArray }).then(res => {
        if (res.data.success) {
          settableData([...tableData].concat(res.data.data))
        }
      }).catch(err => {
        toast.error("check your input data");
      })
      setNumber("");
    }
  }
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{backgroundColor:"#36414f"}}>
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Toolbar>
        </AppBar>
      </Box>
      {/* Setting Box */}
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        < Box sx={{ pr: 20, pl: 20, marginTop: 5 }}>
          <Typography variant='caption'>Enter Accessibility Number</Typography>
          <StyledTextFiled value={Number} placeholder='Enter Accessibility Number' sx={{ mt: 1, mb: 2 }} onChange={(e) => { setNumber(e.target.value) }}></StyledTextFiled>
          <Grid sx={{ marginTop: "30px" }}>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={8}
                // hideFooterSelectedRowCount
                hideFooterPagination
                disableColumnMenu
                onRowSelectionModelChange={e => setAirportInfo(e)}
                pageSizeOptions={[5]}
                checkboxSelection
              />
            </div>
          </Grid>
          <Grid sx={{ flexGrow: 1, marginTop: 3, display: 'inline-block' }} container spacing={1}>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='contained' sx={{ background: blue, width: '150px', padding: '10px' }} type='submit'>Save</Button>
            </Grid>
            <Grid item sx={{ float: 'right' }}>
              <Button variant='outlined' sx={{ width: '150px', padding: '10px' }} >Cancel</Button>
            </Grid>
          </Grid>
        </Box>
      </form>
      {/* Table */}

      < Box sx={{ pr: 3, pl: 3, marginTop: 8 }}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Number</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(tableData) ? tableData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.Number}</StyledTableCell>

                  <StyledTableCell>
                    <IconButton onClick={e => deleteSubmit(row._id)}>
                      <DeleteForever />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              )) : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </Box >
    </>
  );
}

export default Accessibility;

