import React from 'react';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { io } from 'socket.io-client';
import { useSelector, useDispatch } from "react-redux";

import {
    getAnnouncementData,
    setAnnouncementData,
} from "../Redux/Actions/Announcement/index.js";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

const Announcement = () => {

    const dispatch = useDispatch();

    const [Emergency, setEmergency] = useState([]);
    const [tableData, settableData] = useState([]);
    const [number, setNumber] = useState([]);

    const Announcement = useSelector((state) => state.Announcement.Announcement);
    const Airport = useSelector((state) => state.Airport.Airport);

    useEffect(() => {
        dispatch(getAnnouncementData());
        const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

        socket.on("Announcement", (data) => {
            dispatch(setAnnouncementData(JSON.parse(data)));
        })

        socket.on("number", (data) => {
            let data_array = JSON.parse(data);
            let nwArr = data_array.filter(val => {
                if (val.AirportInfo.airport === Airport.airport &&
                    val.AirportInfo.wing === Airport.wing) {
                    return true;
                }
                else {
                    return false;
                }
            })
            setNumber(nwArr);
        })

    }, [])

    useEffect(() => {
        if (Announcement.length !== 0) {
            let nwArr = Announcement.filter(val => {
                if (val.AirportInfo.airport === Airport.airport &&
                    val.AirportInfo.wing === Airport.wing) {
                    return true;
                }
                else {
                    return false;
                }
            })
            settableData(nwArr.filter((val) => val.Type !== "Emergency"));
            setEmergency(nwArr.filter((val) => val.Type === "Emergency"));
        }
        else {
            settableData(Announcement.filter((val) => val.Type !== "Emergency"));
            setEmergency(Announcement.filter((val) => val.Type === "Emergency"));
        }
    }, [Announcement, Airport])


    return (
        <div style={{ display: "flex", height: '90px', placeItems: 'center' }}>
            {
                Emergency.length !== 0 ?
                    <Box sx={{ flex: 2, backgroundColor: 'black', color: 'white', zIndex: '1' }}>
                        <div style={{ border: "3px solid white", height: "87px" }}>
                            <div style={{ fontWeight: 'bold', fontSize: '50px', textAlign: 'center', width: '100%', height: '100%' }}>Emergency</div>
                        </div>
                    </Box> :
                    <Box sx={{ flex: 2, backgroundColor: 'black', color: 'white' }}>
                        <div style={{ border: "3px solid white", height: "87px" }}>
                            <div style={{ fontWeight: 'bold', fontSize: '35px', textAlign: 'center', width: '100%', height: '50%' }}>Special</div>
                            <div style={{ fontWeight: 'bold', fontSize: '35px', textAlign: 'center', width: '100%', height: '50%' }}>Announcement</div>
                        </div>
                    </Box>
            }
            {
                Emergency.length !== 0 ?
                    <Box sx={{ flex: 6, backgroundColor: "red", color: 'white', height: '90px', overflowY: "hidden", display: "flex", alignItems: "center", fontSize: '60px' }}>
                        <marquee>
                            <span>{Emergency[0].Post}</span>
                        </marquee>
                    </Box>
                    :
                    <Box sx={{ flex: 6, backgroundColor: "black", color: 'white', overflowY: "hidden", display: "flex", alignItems: "center", height: "87px", border: "3px solid white" }}>
                        <marquee>
                            {tableData.map(({ Post }, index) =>
                                <span key={index} style={{ marginRight: '50px', fontSize: '60px', }}>{Post}</span>
                            )}
                        </marquee>
                    </Box>
            }
                <AutoPlaySwipeableViews interval={20000} id='borderWidth' className='number' style={{ height: '93px', overflow: "hidden" }}>
                    <div style={{ overflow: "hidden", border: " 3px solid white" }}>
                        < img src={`/image/Accessibility-new.png`} alt="" style={{ objectFit: 'fill', height: '87px', width: '100%', zIndex: '1' }} />
                    </div>
                    {
                        number.map((val, index) =>
                            <div key={index} style={{ height: "87px", overflow: "hidden", border: " 3px solid white", backgroundColor: 'black', color: 'white' }}>
                                <p style={{ fontSize: "55px", backgroundColor: 'black', textAlign: "center", margin: 0 }}>{val.Number}</p>
                            </div>
                        )
                    }

                </AutoPlaySwipeableViews>

        </div >
    )
}

export default Announcement;