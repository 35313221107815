import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';

export const UploadBox = styled(Box)(({ theme }) => ({
    border: '3px dashed #ddd',
    display: "flex",
    borderRadius: 10,
    padding: '40px 35px',
    cursor: 'pointer',
}));

export const ShowUploadFilesBox = styled(Box)(({ theme }) => ({
    display: 'inline-block',
    marginTop: '30px',
    width: '100%'
}));

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 7,
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    left: 10,
    width: 400,
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'gray',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'gray',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

export const StyledTextFiled = styled(InputBase)(({ theme }) => ({
    width: '100%',
    '& .MuiInputBase-input': {
        borderRadius: '7px',
        border: '1px solid #ced4da',
        padding: '15px 12px',
    }
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c6c6c6',
        color: 'black',
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#f5f5f5',
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#ffffff',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));