const city_data = [
  {
    "City/Airport": "Aarhus",
    "Country": "Denmark",
    "IATA code": "AAR"
  },
  {
    "City/Airport": "Abadan",
    "Country": "Iran",
    "IATA code": "ABD"
  },
  {
    "City/Airport": "Abeche",
    "Country": "Chad",
    "IATA code": "AEH"
  },
  {
    "City/Airport": "Aberdeen",
    "Country": "United Kingdom",
    "IATA code": "ABZ"
  },
  {
    "City/Airport": "Aberdeen",
    "Country": "USA",
    "IATA code": "ABR"
  },
  {
    "City/Airport": "Abidjan",
    "Country": "Cote d'Ivoire",
    "IATA code": "ABJ"
  },
  {
    "City/Airport": "Abilene (TX)",
    "Country": "USA",
    "IATA code": "ABI"
  },
  {
    "City/Airport": "Abu Dhabi",
    "Country": "United Arab Emirates",
    "IATA code": "AUH"
  },
  {
    "City/Airport": "Abuja",
    "Country": "Nigeria",
    "IATA code": "ABV"
  },
  {
    "City/Airport": "Abu Rudeis",
    "Country": "Egypt",
    "IATA code": "AUE"
  },
  {
    "City/Airport": "Abu Simbel",
    "Country": "Egypt",
    "IATA code": "ABS"
  },
  {
    "City/Airport": "Acapulco",
    "Country": "Mexico",
    "IATA code": "ACA"
  },
  {
    "City/Airport": "Accra",
    "Country": "Ghana",
    "IATA code": "ACC"
  },
  {
    "City/Airport": "Adana",
    "Country": "Turkey",
    "IATA code": "ADA"
  },
  {
    "City/Airport": "Addis Ababa",
    "Country": "Ethiopia",
    "IATA code": "ADD"
  },
  {
    "City/Airport": "Adelaide",
    "Country": "Australia",
    "IATA code": "ADL"
  },
  {
    "City/Airport": "Aden",
    "Country": "Yemen",
    "IATA code": "ADE"
  },
  {
    "City/Airport": "Adiyaman",
    "Country": "Turkey",
    "IATA code": "ADF"
  },
  {
    "City/Airport": "Adler/Sochi",
    "Country": "Russia",
    "IATA code": "AER"
  },
  {
    "City/Airport": "Agades",
    "Country": "Niger",
    "IATA code": "AJY"
  },
  {
    "City/Airport": "Agadir",
    "Country": "Morocco",
    "IATA code": "AGA"
  },
  {
    "City/Airport": "Agana (Hagåtña)",
    "Country": "Guam",
    "IATA code": "SUM"
  },
  {
    "City/Airport": "Aggeneys",
    "Country": "South Africa",
    "IATA code": "AGZ"
  },
  {
    "City/Airport": "Aguadilla",
    "Country": "Puerto Rico",
    "IATA code": "BQN"
  },
  {
    "City/Airport": "Aguascaliente",
    "Country": "Mexico",
    "IATA code": "AGU"
  },
  {
    "City/Airport": "Ahmedabad",
    "Country": "India",
    "IATA code": "AMD"
  },
  {
    "City/Airport": "Aiyura",
    "Country": "Papua New Guinea",
    "IATA code": "AYU"
  },
  {
    "City/Airport": "Ajaccio",
    "Country": "France",
    "IATA code": "AJA"
  },
  {
    "City/Airport": "Akita",
    "Country": "Japan",
    "IATA code": "AXT"
  },
  {
    "City/Airport": "Akron (OH)",
    "Country": "USA",
    "IATA code": "CAK"
  },
  {
    "City/Airport": "Akrotiri",
    "Country": "Cyprus",
    "IATA code": "AKT"
  },
  {
    "City/Airport": "Al Ain",
    "Country": "United Arab Emirates",
    "IATA code": "AAN"
  },
  {
    "City/Airport": "Al Arish",
    "Country": "Egypt",
    "IATA code": "AAC"
  },
  {
    "City/Airport": "Albany",
    "Country": "Australia",
    "IATA code": "ALH"
  },
  {
    "City/Airport": "Albany (GA)",
    "Country": "USA",
    "IATA code": "ABY"
  },
  {
    "City/Airport": "Albany",
    "Country": "USA",
    "IATA code": "ALB"
  },
  {
    "City/Airport": "Albi",
    "Country": "France",
    "IATA code": "LBI"
  },
  {
    "City/Airport": "Alborg",
    "Country": "Denmark",
    "IATA code": "AAL"
  },
  {
    "City/Airport": "Albuquerque (NM)",
    "Country": "USA",
    "IATA code": "ABQ"
  },
  {
    "City/Airport": "Albury",
    "Country": "Australia",
    "IATA code": "ABX"
  },
  {
    "City/Airport": "Alderney",
    "Country": "Channel Islands",
    "IATA code": "ACI"
  },
  {
    "City/Airport": "Aleppo",
    "Country": "Syria",
    "IATA code": "ALP"
  },
  {
    "City/Airport": "Alesund",
    "Country": "Norway",
    "IATA code": "AES"
  },
  {
    "City/Airport": "Alexander Bay",
    "Country": "South Africa",
    "IATA code": "ALJ"
  },
  {
    "City/Airport": "Alexandria",
    "Country": "Egypt",
    "IATA code": "HBH"
  },
  {
    "City/Airport": "Alexandria",
    "Country": "Egypt",
    "IATA code": "ALY"
  },
  {
    "City/Airport": "Alexandria",
    "Country": "USA (LA)",
    "IATA code": "ESF"
  },
  {
    "City/Airport": "Alfujairah",
    "Country": "United Arab Emirates",
    "IATA code": "FJR"
  },
  {
    "City/Airport": "Alghero Sassari",
    "Country": "Italy",
    "IATA code": "AHO"
  },
  {
    "City/Airport": "Algiers",
    "Country": "Algeria",
    "IATA code": "ALG"
  },
  {
    "City/Airport": "Al Hoceima",
    "Country": "Morocco",
    "IATA code": "AHU"
  },
  {
    "City/Airport": "Alicante",
    "Country": "Spain",
    "IATA code": "ALC"
  },
  {
    "City/Airport": "Alice Springs",
    "Country": "Australia",
    "IATA code": "ASP"
  },
  {
    "City/Airport": "Alldays",
    "Country": "South Africa",
    "IATA code": "ADY"
  },
  {
    "City/Airport": "Allentown (PA)",
    "Country": "USA",
    "IATA code": "ABE"
  },
  {
    "City/Airport": "Almaty",
    "Country": "Kazakhstan",
    "IATA code": "ALA"
  },
  {
    "City/Airport": "Almeria",
    "Country": "Spain",
    "IATA code": "LEI"
  },
  {
    "City/Airport": "Alta",
    "Country": "Norway",
    "IATA code": "ALF"
  },
  {
    "City/Airport": "Altay",
    "Country": "PR China",
    "IATA code": "AAT"
  },
  {
    "City/Airport": "Altenrhein",
    "Country": "Switzerland",
    "IATA code": "ACH"
  },
  {
    "City/Airport": "Altoona (PA)",
    "Country": "USA",
    "IATA code": "AOO"
  },
  {
    "City/Airport": "Altus",
    "Country": "USA",
    "IATA code": "AXS"
  },
  {
    "City/Airport": "Amami",
    "Country": "Japan",
    "IATA code": "ASJ"
  },
  {
    "City/Airport": "Amarillo (TX)",
    "Country": "USA",
    "IATA code": "AMA"
  },
  {
    "City/Airport": "Amazon Bay",
    "Country": "Papua New Guinea",
    "IATA code": "AZB"
  },
  {
    "City/Airport": "Amman",
    "Country": "Jordan",
    "IATA code": "AMM"
  },
  {
    "City/Airport": "Amman",
    "Country": "Jordan",
    "IATA code": "ADJ"
  },
  {
    "City/Airport": "Amritsar",
    "Country": "India",
    "IATA code": "ATQ"
  },
  {
    "City/Airport": "Amsterdam",
    "Country": "Netherlands",
    "IATA code": "AMS"
  },
  {
    "City/Airport": "Anand",
    "Country": "India",
    "IATA code": "QNB"
  },
  {
    "City/Airport": "Anchorage (AK)",
    "Country": "USA",
    "IATA code": "ANC"
  },
  {
    "City/Airport": "Ancona",
    "Country": "Italy",
    "IATA code": "AOI"
  },
  {
    "City/Airport": "Andorra La Vella",
    "Country": "Andorra",
    "IATA code": "ALV"
  },
  {
    "City/Airport": "Anguilla",
    "Country": "Anguilla",
    "IATA code": "AXA"
  },
  {
    "City/Airport": "Anjouan",
    "Country": "Comoros (Comores)",
    "IATA code": "AJN"
  },
  {
    "City/Airport": "Ankara",
    "Country": "Turkey",
    "IATA code": "ANK"
  },
  {
    "City/Airport": "Ankara",
    "Country": "Turkey",
    "IATA code": "ESB"
  },
  {
    "City/Airport": "Annaba",
    "Country": "Algeria",
    "IATA code": "AAE"
  },
  {
    "City/Airport": "Ann Arbor (MI)",
    "Country": "USA",
    "IATA code": "ARB"
  },
  {
    "City/Airport": "Annecy",
    "Country": "France",
    "IATA code": "NCY"
  },
  {
    "City/Airport": "Anniston (AL)",
    "Country": "USA",
    "IATA code": "ANB"
  },
  {
    "City/Airport": "Antalya",
    "Country": "Turkey",
    "IATA code": "AYT"
  },
  {
    "City/Airport": "Antananarivo (Tanannarive)",
    "Country": "Madagascar",
    "IATA code": "TNR"
  },
  {
    "City/Airport": "Antigua, V.C. Bird International",
    "Country": "Antigua and Barbuda",
    "IATA code": "ANU"
  },
  {
    "City/Airport": "Antwerp",
    "Country": "Belgium",
    "IATA code": "ANR"
  },
  {
    "City/Airport": "Aomori",
    "Country": "Japan",
    "IATA code": "AOJ"
  },
  {
    "City/Airport": "Apia",
    "Country": "Samoa",
    "IATA code": "APW"
  },
  {
    "City/Airport": "Appelton/Neenah/Menasha (WI)",
    "Country": "USA",
    "IATA code": "ATW"
  },
  {
    "City/Airport": "Aqaba",
    "Country": "Jordan",
    "IATA code": "AQJ"
  },
  {
    "City/Airport": "Aracaju",
    "Country": "Brazil",
    "IATA code": "AJU"
  },
  {
    "City/Airport": "Arkhangelsk",
    "Country": "Russia",
    "IATA code": "ARH"
  },
  {
    "City/Airport": "Arusha",
    "Country": "Tanzania",
    "IATA code": "ARK"
  },
  {
    "City/Airport": "Araxos",
    "Country": "Greece",
    "IATA code": "GPA"
  },
  {
    "City/Airport": "Arlit",
    "Country": "Niger",
    "IATA code": "RLT"
  },
  {
    "City/Airport": "Arrecife/Lanzarote",
    "Country": "Spain",
    "IATA code": "ACE"
  },
  {
    "City/Airport": "Aruba",
    "Country": "Aruba",
    "IATA code": "AUA"
  },
  {
    "City/Airport": "Asheville (NC)",
    "Country": "USA",
    "IATA code": "AVL"
  },
  {
    "City/Airport": "Ashgabat",
    "Country": "Turkmenistan",
    "IATA code": "ASB"
  },
  {
    "City/Airport": "Asmara",
    "Country": "Eritrea",
    "IATA code": "ASM"
  },
  {
    "City/Airport": "Aspen, (CO)",
    "Country": "USA",
    "IATA code": "ASE"
  },
  {
    "City/Airport": "Assiut",
    "Country": "Egypt",
    "IATA code": "ATZ"
  },
  {
    "City/Airport": "Astana",
    "Country": "Kazakhstan",
    "IATA code": "TSE"
  },
  {
    "City/Airport": "Asuncion",
    "Country": "Paraguay",
    "IATA code": "ASU"
  },
  {
    "City/Airport": "Aswan",
    "Country": "Egypt",
    "IATA code": "ASW"
  },
  {
    "City/Airport": "Athens",
    "Country": "Greece",
    "IATA code": "ATH"
  },
  {
    "City/Airport": "Athens, Hellinikon Airport",
    "Country": "Greece",
    "IATA code": "HEW"
  },
  {
    "City/Airport": "Athens (GA)",
    "Country": "USA",
    "IATA code": "AHN"
  },
  {
    "City/Airport": "Athens (OH)",
    "Country": "USA",
    "IATA code": "ATO"
  },
  {
    "City/Airport": "Atlanta (GA)",
    "Country": "USA",
    "IATA code": "ATL"
  },
  {
    "City/Airport": "Atlantic City (NJ)",
    "Country": "USA",
    "IATA code": "ACY"
  },
  {
    "City/Airport": "Attawapiskat, NT",
    "Country": "Canada",
    "IATA code": "YAT"
  },
  {
    "City/Airport": "Auckland",
    "Country": "New Zealand",
    "IATA code": "AKL"
  },
  {
    "City/Airport": "Augsburg",
    "Country": "Germany",
    "IATA code": "AGB"
  },
  {
    "City/Airport": "Augusta (GA)",
    "Country": "USA",
    "IATA code": "AGS"
  },
  {
    "City/Airport": "Augusta (ME)",
    "Country": "USA",
    "IATA code": "AUG"
  },
  {
    "City/Airport": "Aurillac",
    "Country": "France",
    "IATA code": "AUR"
  },
  {
    "City/Airport": "Austin (TX)",
    "Country": "USA",
    "IATA code": "AUS"
  },
  {
    "City/Airport": "Ayawasi",
    "Country": "Indonesia",
    "IATA code": "AYW"
  },
  {
    "City/Airport": "Ayers Rock",
    "Country": "Australia",
    "IATA code": "AYQ"
  },
  {
    "City/Airport": "Ayr",
    "Country": "Australia",
    "IATA code": "AYR"
  },
  {
    "City/Airport": "B"
  },
  {
    "City/Airport": "Badajoz",
    "Country": "Spain",
    "IATA code": "BJZ"
  },
  {
    "City/Airport": "Bagdad",
    "Country": "Iraq",
    "IATA code": "BGW"
  },
  {
    "City/Airport": "Bagdogra",
    "Country": "India",
    "IATA code": "IXB"
  },
  {
    "City/Airport": "Bahamas",
    "Country": "The Bahamas",
    "IATA code": "NAS"
  },
  {
    "City/Airport": "Bahawalpur",
    "Country": "Pakistan",
    "IATA code": "BHV"
  },
  {
    "City/Airport": "Bahrain",
    "Country": "Bahrain",
    "IATA code": "BAH"
  },
  {
    "City/Airport": "Bakersfield (CA)",
    "Country": "USA",
    "IATA code": "BFL"
  },
  {
    "City/Airport": "Baku",
    "Country": "Azerbaijan",
    "IATA code": "BAK"
  },
  {
    "City/Airport": "Ballina",
    "Country": "Australia",
    "IATA code": "BNK"
  },
  {
    "City/Airport": "Baltimore (MD)",
    "Country": "USA",
    "IATA code": "BWI"
  },
  {
    "City/Airport": "Bamaga",
    "Country": "Australia",
    "IATA code": "ABM"
  },
  {
    "City/Airport": "Bamako",
    "Country": "Mali",
    "IATA code": "BKO"
  },
  {
    "City/Airport": "Bambari",
    "Country": "Central African Republic",
    "IATA code": "BBY"
  },
  {
    "City/Airport": "Bandar Seri Begawan",
    "Country": "Brunei",
    "IATA code": "BWN"
  },
  {
    "City/Airport": "Bandung",
    "Country": "Indonesia",
    "IATA code": "BDO"
  },
  {
    "City/Airport": "Bangalore",
    "Country": "India",
    "IATA code": "BLR"
  },
  {
    "City/Airport": "Bangassou",
    "Country": "Central African Republic",
    "IATA code": "BGU"
  },
  {
    "City/Airport": "Bangkok, Don Muang",
    "Country": "Thailand",
    "IATA code": "DMK"
  },
  {
    "City/Airport": "Bangkok, Suvarnabhumi International",
    "Country": "Thailand",
    "IATA code": "BKK"
  },
  {
    "City/Airport": "Bangor (ME)",
    "Country": "USA",
    "IATA code": "BGR"
  },
  {
    "City/Airport": "Bangui",
    "Country": "Central African Republic",
    "IATA code": "BGF"
  },
  {
    "City/Airport": "Banjul",
    "Country": "Gambia",
    "IATA code": "BJL"
  },
  {
    "City/Airport": "Bannu",
    "Country": "Pakistan",
    "IATA code": "BNP"
  },
  {
    "City/Airport": "Barcelona",
    "Country": "Spain",
    "IATA code": "BCN"
  },
  {
    "City/Airport": "Barcelona",
    "Country": "Venezuela",
    "IATA code": "BLA"
  },
  {
    "City/Airport": "Bardufoss",
    "Country": "Norway",
    "IATA code": "BDU"
  },
  {
    "City/Airport": "Bari",
    "Country": "Italy",
    "IATA code": "BRI"
  },
  {
    "City/Airport": "Barisal",
    "Country": "Bangladesh",
    "IATA code": "BZL"
  },
  {
    "City/Airport": "Baroda",
    "Country": "India",
    "IATA code": "BDQ"
  },
  {
    "City/Airport": "Barra (the famous tidal beach landing)",
    "Country": "United Kingdom",
    "IATA code": "BRR"
  },
  {
    "City/Airport": "Barranquilla",
    "Country": "Colombia",
    "IATA code": "BAQ"
  },
  {
    "City/Airport": "Basel",
    "Country": "Switzerland",
    "IATA code": "BSL"
  },
  {
    "City/Airport": "Basel/Mulhouse",
    "Country": "Switzerland/France",
    "IATA code": "EAP"
  },
  {
    "City/Airport": "Basra, Basrah",
    "Country": "Iraq",
    "IATA code": "BSR"
  },
  {
    "City/Airport": "Bass",
    "Country": "Guadeloupe",
    "IATA code": "PTP"
  },
  {
    "City/Airport": "Basseterre",
    "Country": "Saint Kitts and Nevis",
    "IATA code": "SKB"
  },
  {
    "City/Airport": "Bastia",
    "Country": "France",
    "IATA code": "BIA"
  },
  {
    "City/Airport": "Baton Rouge (LA)",
    "Country": "USA",
    "IATA code": "BTR"
  },
  {
    "City/Airport": "Bayreuth",
    "Country": "Germany",
    "IATA code": "BYU"
  },
  {
    "City/Airport": "Beaumont/Pt. Arthur (TX)",
    "Country": "USA",
    "IATA code": "BPT"
  },
  {
    "City/Airport": "Beckley (WV)",
    "Country": "USA",
    "IATA code": "BKW"
  },
  {
    "City/Airport": "Beef Island",
    "Country": "Virgin Islands (British)",
    "IATA code": "EIS"
  },
  {
    "City/Airport": "Beijing",
    "Country": "China",
    "IATA code": "PEK"
  },
  {
    "City/Airport": "Beijing",
    "Country": "China",
    "IATA code": "NAY"
  },
  {
    "City/Airport": "Beira",
    "Country": "Mozambique",
    "IATA code": "BEW"
  },
  {
    "City/Airport": "Beirut",
    "Country": "Lebanon",
    "IATA code": "BEY"
  },
  {
    "City/Airport": "Belem",
    "Country": "Brazil",
    "IATA code": "BEL"
  },
  {
    "City/Airport": "Belfast",
    "Country": "United Kingdom",
    "IATA code": "BHD"
  },
  {
    "City/Airport": "Belfast",
    "Country": "United Kingdom",
    "IATA code": "BFS"
  },
  {
    "City/Airport": "Belgaum",
    "Country": "India",
    "IATA code": "IXG"
  },
  {
    "City/Airport": "Belgrad (Beograd)",
    "Country": "Serbia",
    "IATA code": "BEG"
  },
  {
    "City/Airport": "Belize City",
    "Country": "Belize",
    "IATA code": "BZE"
  },
  {
    "City/Airport": "Bellingham (WA)",
    "Country": "USA",
    "IATA code": "BLI"
  },
  {
    "City/Airport": "Belo Horizonte",
    "Country": "Brazil",
    "IATA code": "CNF"
  },
  {
    "City/Airport": "Bemidji (MN)",
    "Country": "USA",
    "IATA code": "BJI"
  },
  {
    "City/Airport": "Benbecula",
    "Country": "United Kingdom",
    "IATA code": "BEB"
  },
  {
    "City/Airport": "Benghazi (Bengasi)",
    "Country": "Libya",
    "IATA code": "BEN"
  },
  {
    "City/Airport": "Benguela",
    "Country": "Angola",
    "IATA code": "BUG"
  },
  {
    "City/Airport": "Benton Harbour (MI)",
    "Country": "USA",
    "IATA code": "BEH"
  },
  {
    "City/Airport": "Berberati",
    "Country": "Central African Republic",
    "IATA code": "BBT"
  },
  {
    "City/Airport": "Bergamo/Milan",
    "Country": "Italy",
    "IATA code": "BGY"
  },
  {
    "City/Airport": "Bergen",
    "Country": "Norway",
    "IATA code": "BGO"
  },
  {
    "City/Airport": "Bergerac",
    "Country": "France",
    "IATA code": "EGC"
  },
  {
    "City/Airport": "Berlin",
    "Country": "Germany",
    "IATA code": "BER"
  },
  {
    "City/Airport": "Berlin, Schoenefeld",
    "Country": "Germany",
    "IATA code": "SXF"
  },
  {
    "City/Airport": "Berlin, Tegel",
    "Country": "Germany",
    "IATA code": "TXL"
  },
  {
    "City/Airport": "Berlin, Tempelhof (ceased operating in 2008)",
    "Country": "Germany",
    "IATA code": "THF"
  },
  {
    "City/Airport": "Bermuda",
    "Country": "Bermuda",
    "IATA code": "BDA"
  },
  {
    "City/Airport": "Berne, Ber",
    "Country": "Switzerland",
    "IATA code": "BRN"
  },
  {
    "City/Airport": "Berne, Railway Service",
    "Country": "Switzerland",
    "IATA code": "ZDJ"
  },
  {
    "City/Airport": "Bethel (AK)",
    "Country": "USA",
    "IATA code": "BET"
  },
  {
    "City/Airport": "Bhopal",
    "Country": "India",
    "IATA code": "BHO"
  },
  {
    "City/Airport": "Bhubaneswar",
    "Country": "India",
    "IATA code": "BBI"
  },
  {
    "City/Airport": "Biarritz",
    "Country": "France",
    "IATA code": "BIQ"
  },
  {
    "City/Airport": "Bilbao",
    "Country": "Spain",
    "IATA code": "BIO"
  },
  {
    "City/Airport": "Billings (MT)",
    "Country": "USA",
    "IATA code": "BIL"
  },
  {
    "City/Airport": "Billund",
    "Country": "Denmark",
    "IATA code": "BLL"
  },
  {
    "City/Airport": "Bintulu",
    "Country": "Malaysia",
    "IATA code": "BTU"
  },
  {
    "City/Airport": "Biraro",
    "Country": "Central African Republic",
    "IATA code": "IRO"
  },
  {
    "City/Airport": "Birmingham",
    "Country": "United Kingdom",
    "IATA code": "BHX"
  },
  {
    "City/Airport": "Birmingham (AL)",
    "Country": "USA",
    "IATA code": "BHM"
  },
  {
    "City/Airport": "Bishkek",
    "Country": "Kyrgyzstan",
    "IATA code": "FRU"
  },
  {
    "City/Airport": "Bismarck (ND)",
    "Country": "USA",
    "IATA code": "BIS"
  },
  {
    "City/Airport": "Bissau",
    "Country": "Guine",
    "IATA code": "BXO"
  },
  {
    "City/Airport": "Blackpool",
    "Country": "United Kingdom",
    "IATA code": "BLK"
  },
  {
    "City/Airport": "Blackwater",
    "Country": "Australia",
    "IATA code": "BLT"
  },
  {
    "City/Airport": "Blantyre (Mandala)",
    "Country": "Malawi",
    "IATA code": "BLZ"
  },
  {
    "City/Airport": "Blenheim",
    "Country": "New Zealand",
    "IATA code": "BHE"
  },
  {
    "City/Airport": "Bloemfontein",
    "Country": "South Africa",
    "IATA code": "BFN"
  },
  {
    "City/Airport": "Bloomington (IL)",
    "Country": "USA",
    "IATA code": "BMI"
  },
  {
    "City/Airport": "Bloomington (IN)",
    "Country": "USA",
    "IATA code": "BMG"
  },
  {
    "City/Airport": "Bluefield (WV)",
    "Country": "USA",
    "IATA code": "BLF"
  },
  {
    "City/Airport": "Boa Vista",
    "Country": "Brazil",
    "IATA code": "BVB"
  },
  {
    "City/Airport": "Bobo/Dioulasso",
    "Country": "Burkina Faso",
    "IATA code": "BOY"
  },
  {
    "City/Airport": "Bodo",
    "Country": "Norway",
    "IATA code": "BOO"
  },
  {
    "City/Airport": "Bodrum",
    "Country": "Turkey",
    "IATA code": "BJV"
  },
  {
    "City/Airport": "Bogota",
    "Country": "Colombia",
    "IATA code": "BOG"
  },
  {
    "City/Airport": "Boise (ID)",
    "Country": "USA",
    "IATA code": "BOI"
  },
  {
    "City/Airport": "Bologna",
    "Country": "Italy",
    "IATA code": "BLQ"
  },
  {
    "City/Airport": "Bombay (Mumbai)",
    "Country": "India",
    "IATA code": "BOM"
  },
  {
    "City/Airport": "Bonaire",
    "Country": "Netherlands Antilles",
    "IATA code": "BON"
  },
  {
    "City/Airport": "Bonaventure, PQ",
    "Country": "Canada",
    "IATA code": "YVB"
  },
  {
    "City/Airport": "Bora Bora",
    "Country": "French Polynesia",
    "IATA code": "BOB"
  },
  {
    "City/Airport": "Bordeaux",
    "Country": "France",
    "IATA code": "BOD"
  },
  {
    "City/Airport": "Borrego Springs (CA)",
    "Country": "USA",
    "IATA code": "BXS"
  },
  {
    "City/Airport": "Boston (MA)",
    "Country": "USA",
    "IATA code": "BOS"
  },
  {
    "City/Airport": "Bouake",
    "Country": "Cote d'Ivoire",
    "IATA code": "BYK"
  },
  {
    "City/Airport": "Bourgas/Burgas",
    "Country": "Bulgaria",
    "IATA code": "BOJ"
  },
  {
    "City/Airport": "Bournemouth",
    "Country": "United Kingdom",
    "IATA code": "BOH"
  },
  {
    "City/Airport": "Bowen",
    "Country": "Australia",
    "IATA code": "ZBO"
  },
  {
    "City/Airport": "Bozeman (MT)",
    "Country": "USA",
    "IATA code": "BZN"
  },
  {
    "City/Airport": "Bradford/Warren (PA) /Olean (NY)",
    "Country": "USA",
    "IATA code": "BFD"
  },
  {
    "City/Airport": "Brainerd (MN)",
    "Country": "USA",
    "IATA code": "BRD"
  },
  {
    "City/Airport": "Brampton Island",
    "Country": "Australia",
    "IATA code": "BMP"
  },
  {
    "City/Airport": "Brasilia",
    "Country": "Brazil",
    "IATA code": "BSB"
  },
  {
    "City/Airport": "Bratislava",
    "Country": "Slovakia",
    "IATA code": "BTS"
  },
  {
    "City/Airport": "Brazzaville",
    "Country": "Congo (ROC)",
    "IATA code": "BZV"
  },
  {
    "City/Airport": "Bremen",
    "Country": "Germany",
    "IATA code": "BRE"
  },
  {
    "City/Airport": "Brescia, Montichiari",
    "Country": "Italy",
    "IATA code": "VBS"
  },
  {
    "City/Airport": "Brest",
    "Country": "France",
    "IATA code": "BES"
  },
  {
    "City/Airport": "Bria",
    "Country": "Central African Republic",
    "IATA code": "BIV"
  },
  {
    "City/Airport": "Bridgeport (CT)",
    "Country": "USA",
    "IATA code": "BDR"
  },
  {
    "City/Airport": "Bridgetown",
    "Country": "Barbados",
    "IATA code": "BGI"
  },
  {
    "City/Airport": "Brindisi",
    "Country": "Italy",
    "IATA code": "BDS"
  },
  {
    "City/Airport": "Brisbane",
    "Country": "Australia",
    "IATA code": "BNE"
  },
  {
    "City/Airport": "Bristol",
    "Country": "United Kingdom",
    "IATA code": "BRS"
  },
  {
    "City/Airport": "Broennoeysund",
    "Country": "Norway",
    "IATA code": "BNN"
  },
  {
    "City/Airport": "Broken Hill",
    "Country": "Australia",
    "IATA code": "BHQ"
  },
  {
    "City/Airport": "Brookings (SD)",
    "Country": "USA",
    "IATA code": "BKX"
  },
  {
    "City/Airport": "Broome",
    "Country": "Australia",
    "IATA code": "BME"
  },
  {
    "City/Airport": "Brunswick (GA)",
    "Country": "USA",
    "IATA code": "BQK"
  },
  {
    "City/Airport": "Brussels",
    "Country": "Belgium",
    "IATA code": "BRU"
  },
  {
    "City/Airport": "Bucaramanga",
    "Country": "Colombia",
    "IATA code": "BGA"
  },
  {
    "City/Airport": "Bucharest",
    "Country": "Romania",
    "IATA code": "BUH"
  },
  {
    "City/Airport": "Bucharest",
    "Country": "Romania",
    "IATA code": "OTP"
  },
  {
    "City/Airport": "Budapest",
    "Country": "Hungary",
    "IATA code": "BUD"
  },
  {
    "City/Airport": "Buenos Aires",
    "Country": "Argentina",
    "IATA code": "BUE"
  },
  {
    "City/Airport": "Buenos Aires, Ezeiza International Airport",
    "Country": "Argentina",
    "IATA code": "EZE"
  },
  {
    "City/Airport": "Buenos Aires, Jorge Newbery",
    "Country": "Argentina",
    "IATA code": "AEP"
  },
  {
    "City/Airport": "Buffalo Range",
    "Country": "Zimbabwe",
    "IATA code": "BFO"
  },
  {
    "City/Airport": "Buffalo/Niagara Falls (NY)",
    "Country": "USA",
    "IATA code": "BUF"
  },
  {
    "City/Airport": "Bujumbura",
    "Country": "Burundi",
    "IATA code": "BJM"
  },
  {
    "City/Airport": "Bulawayo",
    "Country": "Zimbabwe",
    "IATA code": "BUQ"
  },
  {
    "City/Airport": "Bullhead City (NV)",
    "Country": "USA",
    "IATA code": "BHC"
  },
  {
    "City/Airport": "Bundaberg",
    "Country": "Australia",
    "IATA code": "BDB"
  },
  {
    "City/Airport": "Burbank (CA)",
    "Country": "USA",
    "IATA code": "BUR"
  },
  {
    "City/Airport": "Burlington IA",
    "Country": "USA",
    "IATA code": "BRL"
  },
  {
    "City/Airport": "Burlington (VT)",
    "Country": "USA",
    "IATA code": "BTV"
  },
  {
    "City/Airport": "Burnie (Wynyard)",
    "Country": "Australia",
    "IATA code": "BWT"
  },
  {
    "City/Airport": "Butte (MT)",
    "Country": "USA",
    "IATA code": "BTM"
  },
  {
    "City/Airport": "Cabinda",
    "Country": "Angola",
    "IATA code": "CAB"
  },
  {
    "City/Airport": "Cagliari",
    "Country": "Italy",
    "IATA code": "CAG"
  },
  {
    "City/Airport": "Cairns",
    "Country": "Australia",
    "IATA code": "CNS"
  },
  {
    "City/Airport": "Cairo",
    "Country": "Egypt",
    "IATA code": "CAI"
  },
  {
    "City/Airport": "Calama",
    "Country": "Chile",
    "IATA code": "CJC"
  },
  {
    "City/Airport": "Calcutta (Kolkata)",
    "Country": "India",
    "IATA code": "CCU"
  },
  {
    "City/Airport": "Calgary",
    "Country": "Canada",
    "IATA code": "YYC"
  },
  {
    "City/Airport": "Cali",
    "Country": "Colombia",
    "IATA code": "CLO"
  },
  {
    "City/Airport": "Calicut",
    "Country": "India",
    "IATA code": "CCJ"
  },
  {
    "City/Airport": "Calvi",
    "Country": "France",
    "IATA code": "CLY"
  },
  {
    "City/Airport": "Cambridge Bay",
    "Country": "Canada",
    "IATA code": "YCB"
  },
  {
    "City/Airport": "Cambrigde",
    "Country": "United Kingdom",
    "IATA code": "CBG"
  },
  {
    "City/Airport": "Campbeltown",
    "Country": "United Kingdom",
    "IATA code": "CAL"
  },
  {
    "City/Airport": "Campo Grande",
    "Country": "Brazil",
    "IATA code": "CGR"
  },
  {
    "City/Airport": "Canberra",
    "Country": "Australia",
    "IATA code": "CBR"
  },
  {
    "City/Airport": "Cancun",
    "Country": "Mexico",
    "IATA code": "CUN"
  },
  {
    "City/Airport": "Cannes – Mandelieu Airport",
    "Country": "France",
    "IATA code": "CEQ"
  },
  {
    "City/Airport": "Canouan (island)",
    "Country": "Saint Vincent & the Grenadines",
    "IATA code": "CIW"
  },
  {
    "City/Airport": "Cape Town",
    "Country": "South Africa",
    "IATA code": "CPT"
  },
  {
    "City/Airport": "Caracas",
    "Country": "Venezuela",
    "IATA code": "CCS"
  },
  {
    "City/Airport": "Cardiff",
    "Country": "United Kingdom",
    "IATA code": "CWL"
  },
  {
    "City/Airport": "Carlsbad (CA)",
    "Country": "USA",
    "IATA code": "CLD"
  },
  {
    "City/Airport": "Carnarvon",
    "Country": "Australia",
    "IATA code": "CVQ"
  },
  {
    "City/Airport": "Carnot",
    "Country": "Central African Republic",
    "IATA code": "CRF"
  },
  {
    "City/Airport": "Carson City (NV)",
    "Country": "USA",
    "IATA code": "CSN"
  },
  {
    "City/Airport": "Casablanca",
    "Country": "Morocco",
    "IATA code": "CAS"
  },
  {
    "City/Airport": "Casablanca, Mohamed V",
    "Country": "Morocco",
    "IATA code": "CMN"
  },
  {
    "City/Airport": "Casa de Campo",
    "Country": "Dominican Republic",
    "IATA code": "LRM"
  },
  {
    "City/Airport": "Casino",
    "Country": "Australia",
    "IATA code": "CSI"
  },
  {
    "City/Airport": "Casper (WY)",
    "Country": "USA",
    "IATA code": "CPR"
  },
  {
    "City/Airport": "Castaway",
    "Country": "Fiji",
    "IATA code": "CST"
  },
  {
    "City/Airport": "Cartagena",
    "Country": "Colombia",
    "IATA code": "CTG"
  },
  {
    "City/Airport": "Castries",
    "Country": "Saint Lucia",
    "IATA code": "SLU"
  },
  {
    "City/Airport": "Catania",
    "Country": "Italy",
    "IATA code": "CTA"
  },
  {
    "City/Airport": "Cayenne",
    "Country": "French Guiana",
    "IATA code": "CAY"
  },
  {
    "City/Airport": "Cottbus",
    "Country": "Germany",
    "IATA code": "CBU"
  },
  {
    "City/Airport": "Cebu City",
    "Country": "Philippines",
    "IATA code": "CEB"
  },
  {
    "City/Airport": "Cedar City (UT)",
    "Country": "USA",
    "IATA code": "CDC"
  },
  {
    "City/Airport": "Cedar Rapids IA",
    "Country": "USA",
    "IATA code": "CID"
  },
  {
    "City/Airport": "Ceduna",
    "Country": "Australia",
    "IATA code": "CED"
  },
  {
    "City/Airport": "Cessnock",
    "Country": "Australia",
    "IATA code": "CES"
  },
  {
    "City/Airport": "Chabarovsk (Khabarovsk)",
    "Country": "Russia",
    "IATA code": "KHV"
  },
  {
    "City/Airport": "Chambery",
    "Country": "France",
    "IATA code": "CMF"
  },
  {
    "City/Airport": "Champaign (IL)",
    "Country": "USA",
    "IATA code": "CMI"
  },
  {
    "City/Airport": "Chandigarh",
    "Country": "India",
    "IATA code": "IXC"
  },
  {
    "City/Airport": "Changchun",
    "Country": "Jilin, PR China",
    "IATA code": "CGQ"
  },
  {
    "City/Airport": "Chania",
    "Country": "Greece",
    "IATA code": "CHQ"
  },
  {
    "City/Airport": "Chaoyang, Beijing",
    "Country": "PR China",
    "IATA code": "CHG"
  },
  {
    "City/Airport": "Charleston (SC)",
    "Country": "USA",
    "IATA code": "CHS"
  },
  {
    "City/Airport": "Charleston (WV)",
    "Country": "USA",
    "IATA code": "CRW"
  },
  {
    "City/Airport": "Charlotte (NC)",
    "Country": "USA",
    "IATA code": "CLT"
  },
  {
    "City/Airport": "Charlottesville (VA)",
    "Country": "USA",
    "IATA code": "CHO"
  },
  {
    "City/Airport": "Charters Towers",
    "Country": "Australia",
    "IATA code": "CXT"
  },
  {
    "City/Airport": "Chattanooga (TN)",
    "Country": "USA",
    "IATA code": "CHA"
  },
  {
    "City/Airport": "Chengdu",
    "Country": "Sichuan, PR China",
    "IATA code": "CTU"
  },
  {
    "City/Airport": "Chennai (Madras)",
    "Country": "India",
    "IATA code": "MAA"
  },
  {
    "City/Airport": "Cheyenne (WY)",
    "Country": "USA",
    "IATA code": "CYS"
  },
  {
    "City/Airport": "Chiang Mai",
    "Country": "Thailand",
    "IATA code": "CNX"
  },
  {
    "City/Airport": "Chiba City",
    "Country": "Japan",
    "IATA code": "QCB"
  },
  {
    "City/Airport": "Chicago (IL), Midway",
    "Country": "USA",
    "IATA code": "MDW"
  },
  {
    "City/Airport": "Chicago (IL), O'Hare International Airport",
    "Country": "USA",
    "IATA code": "ORD"
  },
  {
    "City/Airport": "Chicago (IL)",
    "Country": "USA",
    "IATA code": "CHI"
  },
  {
    "City/Airport": "Chichen Itza",
    "Country": "Mexico",
    "IATA code": "CZA"
  },
  {
    "City/Airport": "Chico (CA)",
    "Country": "USA",
    "IATA code": "CIC"
  },
  {
    "City/Airport": "Chihuahua",
    "Country": "Mexico",
    "IATA code": "CUU"
  },
  {
    "City/Airport": "Chios",
    "Country": "Greece",
    "IATA code": "JKH"
  },
  {
    "City/Airport": "Chipata",
    "Country": "Zambia",
    "IATA code": "CIP"
  },
  {
    "City/Airport": "Chisinau",
    "Country": "Moldova",
    "IATA code": "KIV"
  },
  {
    "City/Airport": "Chita (Tschita)",
    "Country": "Russia",
    "IATA code": "HTA"
  },
  {
    "City/Airport": "Sapporo",
    "Country": "Japan",
    "IATA code": "CTS"
  },
  {
    "City/Airport": "Chitral",
    "Country": "Pakistan",
    "IATA code": "CJL"
  },
  {
    "City/Airport": "Chittagong",
    "Country": "Bangladesh",
    "IATA code": "CGP"
  },
  {
    "City/Airport": "Chongqing",
    "Country": "Sichuan, PR China",
    "IATA code": "CKG"
  },
  {
    "City/Airport": "Christchurch",
    "Country": "New Zealand",
    "IATA code": "CHC"
  },
  {
    "City/Airport": "Chub Cay",
    "Country": "Bahamas",
    "IATA code": "CCZ"
  },
  {
    "City/Airport": "Churchill",
    "Country": "Canada",
    "IATA code": "YYQ"
  },
  {
    "City/Airport": "Cienfuegos",
    "Country": "Cuba",
    "IATA code": "CFG"
  },
  {
    "City/Airport": "Cincinnati (OH)",
    "Country": "USA",
    "IATA code": "CVG"
  },
  {
    "City/Airport": "Ciudad Del Carmen",
    "Country": "Mexico",
    "IATA code": "CME"
  },
  {
    "City/Airport": "Ciudad Guayana",
    "Country": "Venezuela",
    "IATA code": "CGU"
  },
  {
    "City/Airport": "Ciudad Juarez",
    "Country": "Mexico",
    "IATA code": "CJS"
  },
  {
    "City/Airport": "Ciudad Obregon",
    "Country": "Mexico",
    "IATA code": "CEN"
  },
  {
    "City/Airport": "Ciudad Victoria",
    "Country": "Mexico",
    "IATA code": "CVM"
  },
  {
    "City/Airport": "Clarksburg (WV)",
    "Country": "USA",
    "IATA code": "CKB"
  },
  {
    "City/Airport": "Clermont",
    "Country": "Australia",
    "IATA code": "CMQ"
  },
  {
    "City/Airport": "Clermont Ferrand",
    "Country": "France",
    "IATA code": "CFE"
  },
  {
    "City/Airport": "Cleveland (OH) , Burke Lakefront",
    "Country": "USA",
    "IATA code": "BKL"
  },
  {
    "City/Airport": "Cleveland (OH)",
    "Country": "USA",
    "IATA code": "CLE"
  },
  {
    "City/Airport": "Cochabamba",
    "Country": "Bolivia",
    "IATA code": "CBB"
  },
  {
    "City/Airport": "Cochin",
    "Country": "India",
    "IATA code": "COK"
  },
  {
    "City/Airport": "Cody/Powell/Yellowstone (WY)",
    "Country": "USA",
    "IATA code": "COD"
  },
  {
    "City/Airport": "Coffmann Cove (AK)",
    "Country": "USA",
    "IATA code": "KCC"
  },
  {
    "City/Airport": "Coffs Harbour",
    "Country": "Australia",
    "IATA code": "CFS"
  },
  {
    "City/Airport": "Coimbatore",
    "Country": "India",
    "IATA code": "CJB"
  },
  {
    "City/Airport": "Colima",
    "Country": "Mexico",
    "IATA code": "CLQ"
  },
  {
    "City/Airport": "College Station/Bryan (TX)",
    "Country": "USA",
    "IATA code": "CLL"
  },
  {
    "City/Airport": "Collinsville",
    "Country": "Australia",
    "IATA code": "KCE"
  },
  {
    "City/Airport": "Cologne",
    "Country": "Germany",
    "IATA code": "CGN"
  },
  {
    "City/Airport": "Colombo",
    "Country": "Sri Lanka",
    "IATA code": "CMB"
  },
  {
    "City/Airport": "Colorado Springs (CO)",
    "Country": "USA",
    "IATA code": "COS"
  },
  {
    "City/Airport": "Columbia (SC)",
    "Country": "USA",
    "IATA code": "CAE"
  },
  {
    "City/Airport": "Columbus (GA)",
    "Country": "USA",
    "IATA code": "CSG"
  },
  {
    "City/Airport": "Columbus (OH)",
    "Country": "USA",
    "IATA code": "CMH"
  },
  {
    "City/Airport": "Conakry",
    "Country": "Guinea",
    "IATA code": "CKY"
  },
  {
    "City/Airport": "Concord (CA)",
    "Country": "USA",
    "IATA code": "CCR"
  },
  {
    "City/Airport": "Concord (NH)",
    "Country": "USA",
    "IATA code": "CON"
  },
  {
    "City/Airport": "Constantine",
    "Country": "Algeria",
    "IATA code": "CZL"
  },
  {
    "City/Airport": "Constanta (Constanța)",
    "Country": "Romania",
    "IATA code": "CND"
  },
  {
    "City/Airport": "Coober Pedy",
    "Country": "Australia",
    "IATA code": "CPD"
  },
  {
    "City/Airport": "Cooktown",
    "Country": "Australia",
    "IATA code": "CTN"
  },
  {
    "City/Airport": "Cooma",
    "Country": "Australia",
    "IATA code": "OOM"
  },
  {
    "City/Airport": "Copenhagen",
    "Country": "Denmark",
    "IATA code": "CPH"
  },
  {
    "City/Airport": "Cordoba",
    "Country": "Argentina",
    "IATA code": "COR"
  },
  {
    "City/Airport": "Cordoba",
    "Country": "Spain",
    "IATA code": "ODB"
  },
  {
    "City/Airport": "Cordova (AK)",
    "Country": "USA",
    "IATA code": "CDV"
  },
  {
    "City/Airport": "Corfu",
    "Country": "Greece",
    "IATA code": "CFU"
  },
  {
    "City/Airport": "Cork",
    "Country": "Ireland",
    "IATA code": "ORK"
  },
  {
    "City/Airport": "Corpus Christi (TX)",
    "Country": "USA",
    "IATA code": "CRP"
  },
  {
    "City/Airport": "Cotonou",
    "Country": "Benin",
    "IATA code": "COO"
  },
  {
    "City/Airport": "Coventry",
    "Country": "United Kingdom",
    "IATA code": "CVT"
  },
  {
    "City/Airport": "Cozmel",
    "Country": "Mexico",
    "IATA code": "CZM"
  },
  {
    "City/Airport": "Craig (AK)",
    "Country": "USA",
    "IATA code": "CGA"
  },
  {
    "City/Airport": "Crescent City (CA)",
    "Country": "USA",
    "IATA code": "CEC"
  },
  {
    "City/Airport": "Cuiaba",
    "Country": "Brazil",
    "IATA code": "CGB"
  },
  {
    "City/Airport": "Culiacan",
    "Country": "Mexico",
    "IATA code": "CUL"
  },
  {
    "City/Airport": "Curacao",
    "Country": "Netherlands Antilles",
    "IATA code": "CUR"
  },
  {
    "City/Airport": "Curitiba",
    "Country": "Brazil",
    "IATA code": "CWB"
  },
  {
    "City/Airport": "Cuyo",
    "Country": "Philippines",
    "IATA code": "CYU"
  },
  {
    "City/Airport": "Dakar",
    "Country": "Senegal",
    "IATA code": "DKR"
  },
  {
    "City/Airport": "Dalaman",
    "Country": "Turkey",
    "IATA code": "DLM"
  },
  {
    "City/Airport": "Dalby",
    "Country": "Australia",
    "IATA code": "DBY"
  },
  {
    "City/Airport": "Dalian",
    "Country": "Liaoning, PR China",
    "IATA code": "DLC"
  },
  {
    "City/Airport": "Dallas (TX) , Love Field",
    "Country": "USA",
    "IATA code": "DAL"
  },
  {
    "City/Airport": "Dallas/Ft. Worth (TX)",
    "Country": "USA",
    "IATA code": "DFW"
  },
  {
    "City/Airport": "Daloa",
    "Country": "Cote d'Ivoire",
    "IATA code": "DJO"
  },
  {
    "City/Airport": "Damascus, International",
    "Country": "Syria",
    "IATA code": "DAM"
  },
  {
    "City/Airport": "Dammam, King Fahad International",
    "Country": "Saudi Arabien",
    "IATA code": "DMM"
  },
  {
    "City/Airport": "Danville (VA)",
    "Country": "USA",
    "IATA code": "DAN"
  },
  {
    "City/Airport": "Dar es Salam (Daressalam)",
    "Country": "Tanzania",
    "IATA code": "DAR"
  },
  {
    "City/Airport": "Darwin",
    "Country": "Australia",
    "IATA code": "DRW"
  },
  {
    "City/Airport": "Daydream Island",
    "Country": "Australia",
    "IATA code": "DDI"
  },
  {
    "City/Airport": "Dayton (OH)",
    "Country": "USA",
    "IATA code": "DAY"
  },
  {
    "City/Airport": "Daytona Beach (FL)",
    "Country": "USA",
    "IATA code": "DAB"
  },
  {
    "City/Airport": "Decatur (IL)",
    "Country": "USA",
    "IATA code": "DEC"
  },
  {
    "City/Airport": "Deer Lake/Corner Brook",
    "Country": "Canada",
    "IATA code": "YDF"
  },
  {
    "City/Airport": "Delhi",
    "Country": "India",
    "IATA code": "DEL"
  },
  {
    "City/Airport": "Den Haag (The Hague)",
    "Country": "Netherlands",
    "IATA code": "HAG"
  },
  {
    "City/Airport": "Denizli",
    "Country": "Turkey",
    "IATA code": "DNZ"
  },
  {
    "City/Airport": "Denpasar/Bali",
    "Country": "Indonesia",
    "IATA code": "DPS"
  },
  {
    "City/Airport": "Denver (CO)",
    "Country": "USA",
    "IATA code": "DEN"
  },
  {
    "City/Airport": "Dera Ismail Khan",
    "Country": "Pakistan",
    "IATA code": "DSK"
  },
  {
    "City/Airport": "Derby",
    "Country": "Australia",
    "IATA code": "DRB"
  },
  {
    "City/Airport": "Derry (Londonderry)",
    "Country": "United Kingdom",
    "IATA code": "LDY"
  },
  {
    "City/Airport": "Des Moines (IA)",
    "Country": "USA",
    "IATA code": "DSM"
  },
  {
    "City/Airport": "Detroit (MI) , Coleman A. Young Municipal",
    "Country": "USA",
    "IATA code": "DET"
  },
  {
    "City/Airport": "Detroit (MI) , Wayne County Airport",
    "Country": "USA",
    "IATA code": "DTW"
  },
  {
    "City/Airport": "Detroit (MI) , Metropolitan Area",
    "Country": "USA",
    "IATA code": "DTT"
  },
  {
    "City/Airport": "Devils Lake (ND)",
    "Country": "USA",
    "IATA code": "DVL"
  },
  {
    "City/Airport": "Devonport",
    "Country": "Australia",
    "IATA code": "DPO"
  },
  {
    "City/Airport": "Dhahran",
    "Country": "Saudi Arabia",
    "IATA code": "DHA"
  },
  {
    "City/Airport": "Dhaka",
    "Country": "Bangladesh",
    "IATA code": "DAC"
  },
  {
    "City/Airport": "Dili",
    "Country": "Timor Leste (East Timor)",
    "IATA code": "DIL"
  },
  {
    "City/Airport": "Dillingham (AK)",
    "Country": "USA",
    "IATA code": "DLG"
  },
  {
    "City/Airport": "Dinard",
    "Country": "France",
    "IATA code": "DNR"
  },
  {
    "City/Airport": "Disneyland Paris",
    "Country": "France",
    "IATA code": "DLP"
  },
  {
    "City/Airport": "Djerba",
    "Country": "Tunisia",
    "IATA code": "DJE"
  },
  {
    "City/Airport": "Djibouti (city)",
    "Country": "Djibouti",
    "IATA code": "JIB"
  },
  {
    "City/Airport": "Dodoma",
    "Country": "Tanzania",
    "IATA code": "DOD"
  },
  {
    "City/Airport": "Doha",
    "Country": "Qatar",
    "IATA code": "DOH"
  },
  {
    "City/Airport": "Doncaster/Sheffield, Robin Hood International Airport",
    "Country": "United Kingdom",
    "IATA code": "DSA"
  },
  {
    "City/Airport": "Donegal (Carrickfin)",
    "Country": "Ireland",
    "IATA code": "CFN"
  },
  {
    "City/Airport": "Dortmund",
    "Country": "Germany",
    "IATA code": "DTM"
  },
  {
    "City/Airport": "Dothan (AL)",
    "Country": "USA",
    "IATA code": "DHN"
  },
  {
    "City/Airport": "Douala",
    "Country": "Cameroon",
    "IATA code": "DLA"
  },
  {
    "City/Airport": "Dresden",
    "Country": "Germany",
    "IATA code": "DRS"
  },
  {
    "City/Airport": "Dubai",
    "Country": "United Arab Emirates",
    "IATA code": "DXB"
  },
  {
    "City/Airport": "Dubbo",
    "Country": "Australia",
    "IATA code": "DBO"
  },
  {
    "City/Airport": "Dublin",
    "Country": "Ireland",
    "IATA code": "DUB"
  },
  {
    "City/Airport": "Dubois (PA)",
    "Country": "USA",
    "IATA code": "DUJ"
  },
  {
    "City/Airport": "Dubrovnik",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "DBV"
  },
  {
    "City/Airport": "Dubuque IA",
    "Country": "USA",
    "IATA code": "DBQ"
  },
  {
    "City/Airport": "Duesseldorf",
    "Country": "Germany",
    "IATA code": "DUS"
  },
  {
    "City/Airport": "Duluth (MN) /Superior (WI)",
    "Country": "USA",
    "IATA code": "DLH"
  },
  {
    "City/Airport": "Dundee",
    "Country": "United Kingdom",
    "IATA code": "DND"
  },
  {
    "City/Airport": "Dunedin",
    "Country": "New Zealand",
    "IATA code": "DUD"
  },
  {
    "City/Airport": "Dunk Island",
    "Country": "Australia",
    "IATA code": "DKI"
  },
  {
    "City/Airport": "Durango (CO)",
    "Country": "USA",
    "IATA code": "DRO"
  },
  {
    "City/Airport": "Durban",
    "Country": "South Africa",
    "IATA code": "DUR"
  },
  {
    "City/Airport": "Dushanbe (Duschanbe)",
    "Country": "Tajikistan",
    "IATA code": "DYU"
  },
  {
    "City/Airport": "Dutch Harbor (AK)",
    "Country": "USA",
    "IATA code": "DUT"
  },
  {
    "City/Airport": "Dysart",
    "Country": "Australia",
    "IATA code": "DYA"
  },
  {
    "City/Airport": "Dzaoudzi",
    "Country": "Mayotte",
    "IATA code": "DZA"
  },
  {
    "City/Airport": "East London",
    "Country": "South Africa",
    "IATA code": "ELS"
  },
  {
    "City/Airport": "Easter Island",
    "Country": "Chile",
    "IATA code": "IPC"
  },
  {
    "City/Airport": "Eau Clarie (WI)",
    "Country": "USA",
    "IATA code": "EAU"
  },
  {
    "City/Airport": "Edinburgh",
    "Country": "Scotland, UK",
    "IATA code": "EDI"
  },
  {
    "City/Airport": "Edmonton",
    "Country": "Canada",
    "IATA code": "YEA"
  },
  {
    "City/Airport": "Edmonton, International",
    "Country": "Canada",
    "IATA code": "YEG"
  },
  {
    "City/Airport": "Edmonton, Municipal",
    "Country": "Canada",
    "IATA code": "YXD"
  },
  {
    "City/Airport": "Egilsstadir",
    "Country": "Iceland",
    "IATA code": "EGS"
  },
  {
    "City/Airport": "Eindhoven",
    "Country": "Netherlands",
    "IATA code": "EIN"
  },
  {
    "City/Airport": "Samana",
    "Country": "Dominican Republic",
    "IATA code": "AZS"
  },
  {
    "City/Airport": "Elba Island, Marina Di Campo",
    "Country": "Italy",
    "IATA code": "EBA"
  },
  {
    "City/Airport": "Elat",
    "Country": "Israel",
    "IATA code": "ETH"
  },
  {
    "City/Airport": "Elat, Ovula",
    "Country": "Israel",
    "IATA code": "VDA"
  },
  {
    "City/Airport": "Elkhart (IN)",
    "Country": "USA",
    "IATA code": "EKI"
  },
  {
    "City/Airport": "Elko (NV)",
    "Country": "USA",
    "IATA code": "EKO"
  },
  {
    "City/Airport": "Ellisras",
    "Country": "South Africa",
    "IATA code": "ELL"
  },
  {
    "City/Airport": "El Minya",
    "Country": "Egypt",
    "IATA code": "EMY"
  },
  {
    "City/Airport": "Elmira (NY)",
    "Country": "USA",
    "IATA code": "ELM"
  },
  {
    "City/Airport": "El Paso (TX)",
    "Country": "USA",
    "IATA code": "ELP"
  },
  {
    "City/Airport": "Ely (NV)",
    "Country": "USA",
    "IATA code": "ELY"
  },
  {
    "City/Airport": "Emerald",
    "Country": "Australia",
    "IATA code": "EDR"
  },
  {
    "City/Airport": "Emerald",
    "Country": "Australia",
    "IATA code": "EMD"
  },
  {
    "City/Airport": "Enontekioe",
    "Country": "Finland",
    "IATA code": "ENF"
  },
  {
    "City/Airport": "Entebbe",
    "Country": "Uganda",
    "IATA code": "EBB"
  },
  {
    "City/Airport": "Erfurt",
    "Country": "Germany",
    "IATA code": "ERF"
  },
  {
    "City/Airport": "Erie (PA)",
    "Country": "USA",
    "IATA code": "ERI"
  },
  {
    "City/Airport": "Eriwan (Yerevan, Jerevan)",
    "Country": "Armenia",
    "IATA code": "EVN"
  },
  {
    "City/Airport": "Erzincan",
    "Country": "Turkey",
    "IATA code": "ERC"
  },
  {
    "City/Airport": "Erzurum",
    "Country": "Turkey",
    "IATA code": "ERZ"
  },
  {
    "City/Airport": "Esbjerg",
    "Country": "Denmark",
    "IATA code": "EBJ"
  },
  {
    "City/Airport": "Escanaba (MI)",
    "Country": "USA",
    "IATA code": "ESC"
  },
  {
    "City/Airport": "Esperance",
    "Country": "Australia",
    "IATA code": "EPR"
  },
  {
    "City/Airport": "Eugene (OR)",
    "Country": "USA",
    "IATA code": "EUG"
  },
  {
    "City/Airport": "Eureka (CA)",
    "Country": "USA",
    "IATA code": "ACV"
  },
  {
    "City/Airport": "Evansville (IN)",
    "Country": "USA",
    "IATA code": "EVV"
  },
  {
    "City/Airport": "Evenes",
    "Country": "Norway",
    "IATA code": "EVE"
  },
  {
    "City/Airport": "Exeter",
    "Country": "United Kingdom",
    "IATA code": "EXT"
  },
  {
    "City/Airport": "Fairbanks (AK)",
    "Country": "USA",
    "IATA code": "FAI"
  },
  {
    "City/Airport": "Fair Isle (Shetland)",
    "Country": "United Kingdom",
    "IATA code": "FIE"
  },
  {
    "City/Airport": "Faisalabad",
    "Country": "Pakistan",
    "IATA code": "LYP"
  },
  {
    "City/Airport": "Fargo (ND) (MN)",
    "Country": "USA",
    "IATA code": "FAR"
  },
  {
    "City/Airport": "Farmington (NM)",
    "Country": "USA",
    "IATA code": "FMN"
  },
  {
    "City/Airport": "Faro",
    "Country": "Portugal",
    "IATA code": "FAO"
  },
  {
    "City/Airport": "Faroer",
    "Country": "Denmark",
    "IATA code": "FAE"
  },
  {
    "City/Airport": "Fayetteville (AR)",
    "Country": "USA",
    "IATA code": "FYV"
  },
  {
    "City/Airport": "Fayetteville/Ft. Bragg (NC)",
    "Country": "USA",
    "IATA code": "FAY"
  },
  {
    "City/Airport": "Fes",
    "Country": "Morocco",
    "IATA code": "FEZ"
  },
  {
    "City/Airport": "Figari",
    "Country": "France",
    "IATA code": "FSC"
  },
  {
    "City/Airport": "Flagstaff (AZ)",
    "Country": "USA",
    "IATA code": "FLG"
  },
  {
    "City/Airport": "Flin Flon",
    "Country": "Canada",
    "IATA code": "YFO"
  },
  {
    "City/Airport": "Flint (MI)",
    "Country": "USA",
    "IATA code": "FNT"
  },
  {
    "City/Airport": "Florence (Firenze)",
    "Country": "Italy",
    "IATA code": "FLR"
  },
  {
    "City/Airport": "Florence (SC)",
    "Country": "USA",
    "IATA code": "FLO"
  },
  {
    "City/Airport": "Florianopolis",
    "Country": "Brazil",
    "IATA code": "FLN"
  },
  {
    "City/Airport": "Floro",
    "Country": "Norway",
    "IATA code": "FRO"
  },
  {
    "City/Airport": "Fort Albert",
    "Country": "Canada",
    "IATA code": "YFA"
  },
  {
    "City/Airport": "Fortaleza",
    "Country": "Brazil",
    "IATA code": "FOR"
  },
  {
    "City/Airport": "Fort de France",
    "Country": "Martinique",
    "IATA code": "FDF"
  },
  {
    "City/Airport": "Fort Dodge IA",
    "Country": "USA",
    "IATA code": "FOD"
  },
  {
    "City/Airport": "Fort Huachuca/Sierra Vista (AZ)",
    "Country": "USA",
    "IATA code": "FHU"
  },
  {
    "City/Airport": "Fort Lauderdale/Hollywood (FL)",
    "Country": "USA",
    "IATA code": "FLL"
  },
  {
    "City/Airport": "Fort McMurray",
    "Country": "Canada",
    "IATA code": "YMM"
  },
  {
    "City/Airport": "Fort Myers, Metropolitan Area (FL)",
    "Country": "USA",
    "IATA code": "FMY"
  },
  {
    "City/Airport": "Fort Myers, Southwest Florida Reg (FL)",
    "Country": "USA",
    "IATA code": "RSW"
  },
  {
    "City/Airport": "Fort Riley (KS)",
    "Country": "USA",
    "IATA code": "FRI"
  },
  {
    "City/Airport": "Fort Smith",
    "Country": "Canada",
    "IATA code": "YSM"
  },
  {
    "City/Airport": "Fort Smith (AR)",
    "Country": "USA",
    "IATA code": "FSM"
  },
  {
    "City/Airport": "Fort St. John",
    "Country": "Canada",
    "IATA code": "YXJ"
  },
  {
    "City/Airport": "Fort Walton Beach (FL)",
    "Country": "USA",
    "IATA code": "VPS"
  },
  {
    "City/Airport": "Fort Wayne (IN)",
    "Country": "USA",
    "IATA code": "FWA"
  },
  {
    "City/Airport": "Fort Worth (TX)",
    "Country": "USA",
    "IATA code": "DFW"
  },
  {
    "City/Airport": "Foula (Shetland)",
    "Country": "United Kingdom",
    "IATA code": "FOU"
  },
  {
    "City/Airport": "Francistown",
    "Country": "Botswana",
    "IATA code": "FRW"
  },
  {
    "City/Airport": "Frankfurt/Main",
    "Country": "Germany",
    "IATA code": "FRA"
  },
  {
    "City/Airport": "Frankfurt/Hahn",
    "Country": "Germany",
    "IATA code": "HHN"
  },
  {
    "City/Airport": "Franklin/Oil City (PA)",
    "Country": "USA",
    "IATA code": "FKL"
  },
  {
    "City/Airport": "Fredericton",
    "Country": "Canada",
    "IATA code": "YFC"
  },
  {
    "City/Airport": "Freeport",
    "Country": "Bahamas",
    "IATA code": "FPO"
  },
  {
    "City/Airport": "Freetown",
    "Country": "Sierra Leone",
    "IATA code": "FNA"
  },
  {
    "City/Airport": "Frejus",
    "Country": "France",
    "IATA code": "FRJ"
  },
  {
    "City/Airport": "Fresno (CA)",
    "Country": "USA",
    "IATA code": "FAT"
  },
  {
    "City/Airport": "Friedrichshafen",
    "Country": "Germany",
    "IATA code": "FDH"
  },
  {
    "City/Airport": "Fuerteventura",
    "Country": "Spain",
    "IATA code": "FUE"
  },
  {
    "City/Airport": "Fujairah, International Airport",
    "Country": "United Arab Emirates",
    "IATA code": "FJR"
  },
  {
    "City/Airport": "Fukuoka",
    "Country": "Japan",
    "IATA code": "FUK"
  },
  {
    "City/Airport": "Fukushima",
    "Country": "Japan",
    "IATA code": "FKS"
  },
  {
    "City/Airport": "Funchal",
    "Country": "Portugal",
    "IATA code": "FNC"
  },
  {
    "City/Airport": "Futuna",
    "Country": "Wallis and Futuna Islands",
    "IATA code": "FUT"
  },
  {
    "City/Airport": "Gaborone",
    "Country": "Botswana",
    "IATA code": "GBE"
  },
  {
    "City/Airport": "Gadsden (AL)",
    "Country": "USA",
    "IATA code": "GAD"
  },
  {
    "City/Airport": "Gainesville (FL)",
    "Country": "USA",
    "IATA code": "GNV"
  },
  {
    "City/Airport": "Galway",
    "Country": "Ireland",
    "IATA code": "GWY"
  },
  {
    "City/Airport": "Gander",
    "Country": "Canada",
    "IATA code": "YQX"
  },
  {
    "City/Airport": "Garoua",
    "Country": "Cameroon",
    "IATA code": "GOU"
  },
  {
    "City/Airport": "Gaza City",
    "Country": "Palestinian Territory",
    "IATA code": "GZA"
  },
  {
    "City/Airport": "Gaziantep",
    "Country": "Turkey",
    "IATA code": "GZT"
  },
  {
    "City/Airport": "Gdansk",
    "Country": "Poland",
    "IATA code": "GDN"
  },
  {
    "City/Airport": "Geelong",
    "Country": "Australia",
    "IATA code": "GEX"
  },
  {
    "City/Airport": "Geneva",
    "Country": "Switzerland",
    "IATA code": "GVA"
  },
  {
    "City/Airport": "Genoa",
    "Country": "Italy",
    "IATA code": "GOA"
  },
  {
    "City/Airport": "George",
    "Country": "South Africa",
    "IATA code": "GRJ"
  },
  {
    "City/Airport": "Georgetown",
    "Country": "Guyana",
    "IATA code": "GEO"
  },
  {
    "City/Airport": "Geraldton",
    "Country": "Australia",
    "IATA code": "GET"
  },
  {
    "City/Airport": "Gerona",
    "Country": "Spain",
    "IATA code": "GRO"
  },
  {
    "City/Airport": "Ghent (Gent)",
    "Country": "Belgium",
    "IATA code": "GNE"
  },
  {
    "City/Airport": "Gibraltar",
    "Country": "Gibraltar",
    "IATA code": "GIB"
  },
  {
    "City/Airport": "Gilette (WY)",
    "Country": "USA",
    "IATA code": "GCC"
  },
  {
    "City/Airport": "Gilgit",
    "Country": "Pakistan",
    "IATA code": "GIL"
  },
  {
    "City/Airport": "Gillam",
    "Country": "Canada",
    "IATA code": "YGX"
  },
  {
    "City/Airport": "Gladstone",
    "Country": "Australia",
    "IATA code": "GLT"
  },
  {
    "City/Airport": "Glasgow, Prestwick",
    "Country": "United Kingdom",
    "IATA code": "PIK"
  },
  {
    "City/Airport": "Glasgow",
    "Country": "United Kingdom",
    "IATA code": "GLA"
  },
  {
    "City/Airport": "Glasgow (MT)",
    "Country": "USA",
    "IATA code": "GGW"
  },
  {
    "City/Airport": "Glendive (MT)",
    "Country": "USA",
    "IATA code": "GDV"
  },
  {
    "City/Airport": "Goa",
    "Country": "India",
    "IATA code": "GOI"
  },
  {
    "City/Airport": "Goiania, Santa Genoveva Airport",
    "Country": "Brazil",
    "IATA code": "GYN"
  },
  {
    "City/Airport": "Gold Coast",
    "Country": "Australia",
    "IATA code": "OOL"
  },
  {
    "City/Airport": "Goondiwindi",
    "Country": "Australia",
    "IATA code": "GOO"
  },
  {
    "City/Airport": "Goose Bay",
    "Country": "Canada",
    "IATA code": "YYR"
  },
  {
    "City/Airport": "Gorna",
    "Country": "Bulgaria",
    "IATA code": "GOZ"
  },
  {
    "City/Airport": "Gothenburg (Göteborg)",
    "Country": "Sweden",
    "IATA code": "GOT"
  },
  {
    "City/Airport": "Gove (Nhulunbuy)",
    "Country": "Australia",
    "IATA code": "GOV"
  },
  {
    "City/Airport": "Govenors Harbour",
    "Country": "Bahamas",
    "IATA code": "GHB"
  },
  {
    "City/Airport": "Granada",
    "Country": "Spain",
    "IATA code": "GRX"
  },
  {
    "City/Airport": "Grand Bahama International",
    "Country": "Bahamas",
    "IATA code": "FPO"
  },
  {
    "City/Airport": "Grand Canyon (AZ)",
    "Country": "USA",
    "IATA code": "GCN"
  },
  {
    "City/Airport": "Grand Cayman",
    "Country": "Cayman Islands",
    "IATA code": "GCM"
  },
  {
    "City/Airport": "Grand Forks (ND)",
    "Country": "USA",
    "IATA code": "GFK"
  },
  {
    "City/Airport": "Grand Junction (CO)",
    "Country": "USA",
    "IATA code": "GJT"
  },
  {
    "City/Airport": "Grand Rapids (MI)",
    "Country": "USA",
    "IATA code": "GRR"
  },
  {
    "City/Airport": "Grand Rapids (MN)",
    "Country": "USA",
    "IATA code": "GPZ"
  },
  {
    "City/Airport": "Graz",
    "Country": "Austria",
    "IATA code": "GRZ"
  },
  {
    "City/Airport": "Great Falls (MT)",
    "Country": "USA",
    "IATA code": "GTF"
  },
  {
    "City/Airport": "Great Keppel Island",
    "Country": "Australia",
    "IATA code": "GKL"
  },
  {
    "City/Airport": "Green Bay (WI)",
    "Country": "USA",
    "IATA code": "GRB"
  },
  {
    "City/Airport": "Greenbrier/Lewisburg (WV)",
    "Country": "USA",
    "IATA code": "LWB"
  },
  {
    "City/Airport": "Greensboro/Winston Salem (NC)",
    "Country": "USA",
    "IATA code": "GSO"
  },
  {
    "City/Airport": "Greenville (MS)",
    "Country": "USA",
    "IATA code": "GLH"
  },
  {
    "City/Airport": "Greenville (NC)",
    "Country": "USA",
    "IATA code": "PGV"
  },
  {
    "City/Airport": "Greenville/Spartanburg (SC)",
    "Country": "USA",
    "IATA code": "GSP"
  },
  {
    "City/Airport": "Grenada",
    "Country": "Grenada",
    "IATA code": "GND"
  },
  {
    "City/Airport": "Grenoble",
    "Country": "France",
    "IATA code": "GNB"
  },
  {
    "City/Airport": "Griffith",
    "Country": "Australia",
    "IATA code": "GFF"
  },
  {
    "City/Airport": "Groningen",
    "Country": "Netherlands",
    "IATA code": "GRQ"
  },
  {
    "City/Airport": "Groote Eylandt",
    "Country": "Australia",
    "IATA code": "GTE"
  },
  {
    "City/Airport": "Groton/New London (CT)",
    "Country": "USA",
    "IATA code": "GON"
  },
  {
    "City/Airport": "Guadalajara",
    "Country": "Mexico",
    "IATA code": "GDL"
  },
  {
    "City/Airport": "Guadalcanal",
    "Country": "Solomon Islands",
    "IATA code": "GSI"
  },
  {
    "City/Airport": "Guam",
    "Country": "Guam",
    "IATA code": "GUM"
  },
  {
    "City/Airport": "Guangzhou (Canton)",
    "Country": "Guangdong, PR China",
    "IATA code": "CAN"
  },
  {
    "City/Airport": "Sao Paulo",
    "Country": "Brazil",
    "IATA code": "GRU"
  },
  {
    "City/Airport": "Guatemala City",
    "Country": "Guatemala",
    "IATA code": "GUA"
  },
  {
    "City/Airport": "Guayaquil",
    "Country": "Ecuador",
    "IATA code": "GYE"
  },
  {
    "City/Airport": "Guernsey",
    "Country": "Channel Islands",
    "IATA code": "GCI"
  },
  {
    "City/Airport": "Guettin",
    "Country": "Germany",
    "IATA code": "GTI"
  },
  {
    "City/Airport": "Gulfport/Biloxi (MS)",
    "Country": "USA",
    "IATA code": "GPT"
  },
  {
    "City/Airport": "Guilin",
    "Country": "Guangxi, PR China",
    "IATA code": "KWL"
  },
  {
    "City/Airport": "Gulu",
    "Country": "Uganda",
    "IATA code": "ULU"
  },
  {
    "City/Airport": "Gunnison/Crested Butte (CO)",
    "Country": "USA",
    "IATA code": "GUC"
  },
  {
    "City/Airport": "Guwahati",
    "Country": "India",
    "IATA code": "GAU"
  },
  {
    "City/Airport": "Gwadar",
    "Country": "Pakistan",
    "IATA code": "GWD"
  },
  {
    "City/Airport": "Gweru",
    "Country": "Zimbabwe",
    "IATA code": "GWE"
  },
  {
    "City/Airport": "Gympie",
    "Country": "Australia",
    "IATA code": "GYP"
  },
  {
    "City/Airport": "Hachijo Jima",
    "Country": "Japan",
    "IATA code": "HAC"
  },
  {
    "City/Airport": "Hagåtña",
    "Country": "Guam",
    "IATA code": "GUM"
  },
  {
    "City/Airport": "Haifa",
    "Country": "Israel",
    "IATA code": "HFA"
  },
  {
    "City/Airport": "Haines (AK)",
    "Country": "USA",
    "IATA code": "HNS"
  },
  {
    "City/Airport": "Hakodate",
    "Country": "Japan",
    "IATA code": "HKD"
  },
  {
    "City/Airport": "Halifax International",
    "Country": "Canada",
    "IATA code": "YHZ"
  },
  {
    "City/Airport": "Hall Beach",
    "Country": "Canada",
    "IATA code": "YUX"
  },
  {
    "City/Airport": "Hamburg",
    "Country": "Germany",
    "IATA code": "HAM"
  },
  {
    "City/Airport": "Hamilton",
    "Country": "Australia",
    "IATA code": "HLT"
  },
  {
    "City/Airport": "Hamilton",
    "Country": "Canada",
    "IATA code": "YHM"
  },
  {
    "City/Airport": "Hamilton",
    "Country": "New Zealand",
    "IATA code": "HLZ"
  },
  {
    "City/Airport": "Hamilton Island",
    "Country": "Australia",
    "IATA code": "HTI"
  },
  {
    "City/Airport": "Hammerfest",
    "Country": "Norway",
    "IATA code": "HFT"
  },
  {
    "City/Airport": "Hancock (MI)",
    "Country": "USA",
    "IATA code": "CMX"
  },
  {
    "City/Airport": "Hangchow (Hangzhou)",
    "Country": "Zhejiang, PR China",
    "IATA code": "HGH"
  },
  {
    "City/Airport": "Hannover",
    "Country": "Germany",
    "IATA code": "HAJ"
  },
  {
    "City/Airport": "Hanoi",
    "Country": "Vietnam",
    "IATA code": "HAN"
  },
  {
    "City/Airport": "Harare",
    "Country": "Zimbabwe",
    "IATA code": "HRE"
  },
  {
    "City/Airport": "Harbin (Haerbin)",
    "Country": "Heilongjiang, PR China",
    "IATA code": "HRB"
  },
  {
    "City/Airport": "Harlingen/South Padre Island (TX)",
    "Country": "USA",
    "IATA code": "HRL"
  },
  {
    "City/Airport": "Harrington Harbour, PQ",
    "Country": "Canada",
    "IATA code": "YHR"
  },
  {
    "City/Airport": "Harrisburg (PA)",
    "Country": "USA",
    "IATA code": "HAR"
  },
  {
    "City/Airport": "Harrisburg (PA)",
    "Country": "USA",
    "IATA code": "MDT"
  },
  {
    "City/Airport": "Hartford (CT) /Springfield (MA)",
    "Country": "USA",
    "IATA code": "BDL"
  },
  {
    "City/Airport": "Hatyai (Hat Yai)",
    "Country": "Thailand",
    "IATA code": "HDY"
  },
  {
    "City/Airport": "Haugesund",
    "Country": "Norway",
    "IATA code": "HAU"
  },
  {
    "City/Airport": "Havana",
    "Country": "Cuba",
    "IATA code": "HAV"
  },
  {
    "City/Airport": "Havre (MT)",
    "Country": "USA",
    "IATA code": "HVR"
  },
  {
    "City/Airport": "Hayman Island",
    "Country": "Australia",
    "IATA code": "HIS"
  },
  {
    "City/Airport": "Helena (MT)",
    "Country": "USA",
    "IATA code": "HLN"
  },
  {
    "City/Airport": "Helsingborg",
    "Country": "Sweden",
    "IATA code": "JHE"
  },
  {
    "City/Airport": "Helsinki",
    "Country": "Finland",
    "IATA code": "HEL"
  },
  {
    "City/Airport": "Heraklion",
    "Country": "Greece",
    "IATA code": "HER"
  },
  {
    "City/Airport": "Hermosillo",
    "Country": "Mexico",
    "IATA code": "HMO"
  },
  {
    "City/Airport": "Hervey Bay",
    "Country": "Australia",
    "IATA code": "HVB"
  },
  {
    "City/Airport": "Hibbing (MN)",
    "Country": "USA",
    "IATA code": "HIB"
  },
  {
    "City/Airport": "Hickory (NC)",
    "Country": "USA",
    "IATA code": "HKY"
  },
  {
    "City/Airport": "Hilo (HI)",
    "Country": "USA",
    "IATA code": "ITO"
  },
  {
    "City/Airport": "Hilton Head Island (SC)",
    "Country": "USA",
    "IATA code": "HHH"
  },
  {
    "City/Airport": "Hinchinbrook Island",
    "Country": "Australia",
    "IATA code": "HNK"
  },
  {
    "City/Airport": "Hiroshima International",
    "Country": "Japan",
    "IATA code": "HIJ"
  },
  {
    "City/Airport": "Ho Chi Minh City (Saigon)",
    "Country": "Viet Nam",
    "IATA code": "SGN"
  },
  {
    "City/Airport": "Hobart",
    "Country": "Australia",
    "IATA code": "HBA"
  },
  {
    "City/Airport": "Hof",
    "Country": "Germany",
    "IATA code": "HOQ"
  },
  {
    "City/Airport": "Holguin",
    "Country": "Cuba",
    "IATA code": "HOG"
  },
  {
    "City/Airport": "Home Hill",
    "Country": "Australia",
    "IATA code": "HMH"
  },
  {
    "City/Airport": "Homer (AK)",
    "Country": "USA",
    "IATA code": "HOM"
  },
  {
    "City/Airport": "Hong Kong",
    "Country": "Hong Kong",
    "IATA code": "HKG"
  },
  {
    "City/Airport": "Hong Kong",
    "Country": "Hong Kong",
    "IATA code": "ZJK"
  },
  {
    "City/Airport": "Honiara Henderson International",
    "Country": "Solomon Islands",
    "IATA code": "HIR"
  },
  {
    "City/Airport": "Honolulu (HI)",
    "Country": "USA",
    "IATA code": "HNL"
  },
  {
    "City/Airport": "Hoonah (AK)",
    "Country": "USA",
    "IATA code": "HNH"
  },
  {
    "City/Airport": "Horta",
    "Country": "Portugal",
    "IATA code": "HOR"
  },
  {
    "City/Airport": "Houston (TX) , Hobby",
    "Country": "USA",
    "IATA code": "HOU"
  },
  {
    "City/Airport": "Houston, TX",
    "Country": "USA",
    "IATA code": "IAH"
  },
  {
    "City/Airport": "Huahine",
    "Country": "French Polynesia",
    "IATA code": "HUH"
  },
  {
    "City/Airport": "Huatulco",
    "Country": "Mexico",
    "IATA code": "HUX"
  },
  {
    "City/Airport": "Hue",
    "Country": "Viet Nam",
    "IATA code": "HUI"
  },
  {
    "City/Airport": "Humberside",
    "Country": "United Kingdom",
    "IATA code": "HUY"
  },
  {
    "City/Airport": "Huntington (WV)",
    "Country": "USA",
    "IATA code": "HTS"
  },
  {
    "City/Airport": "Huntsville (AL)",
    "Country": "USA",
    "IATA code": "HSV"
  },
  {
    "City/Airport": "Hurghada International",
    "Country": "Egypt",
    "IATA code": "HRG"
  },
  {
    "City/Airport": "Huron (SD)",
    "Country": "USA",
    "IATA code": "HON"
  },
  {
    "City/Airport": "Hwange National Park",
    "Country": "Zimbabwe",
    "IATA code": "HWN"
  },
  {
    "City/Airport": "Hyannis (MA)",
    "Country": "USA",
    "IATA code": "HYA"
  },
  {
    "City/Airport": "Hydaburg (AK)",
    "Country": "USA",
    "IATA code": "HYG"
  },
  {
    "City/Airport": "Hyderabad",
    "Country": "India",
    "IATA code": "HYD"
  },
  {
    "City/Airport": "Hyderabad",
    "Country": "Pakistan",
    "IATA code": "HDD"
  },
  {
    "City/Airport": "Ibiza",
    "Country": "Ibiza/Spain",
    "IATA code": "IBZ"
  },
  {
    "City/Airport": "Idaho Falls (ID)",
    "Country": "USA",
    "IATA code": "IDA"
  },
  {
    "City/Airport": "Iguazu, Cataratas",
    "Country": "Argentina",
    "IATA code": "IGR"
  },
  {
    "City/Airport": "Ile des Pins",
    "Country": "New Caledonia",
    "IATA code": "ILP"
  },
  {
    "City/Airport": "Ile Ouen",
    "Country": "New Caledonia",
    "IATA code": "IOU"
  },
  {
    "City/Airport": "Iliamna (AK)",
    "Country": "USA",
    "IATA code": "ILI"
  },
  {
    "City/Airport": "Imperial (CA)",
    "Country": "USA",
    "IATA code": "IPL"
  },
  {
    "City/Airport": "Incercargill",
    "Country": "New Zealand",
    "IATA code": "IVC"
  },
  {
    "City/Airport": "Incheon, Incheon International Airport",
    "Country": "Korea South",
    "IATA code": "ICN"
  },
  {
    "City/Airport": "Indianapolis (IN) International",
    "Country": "USA",
    "IATA code": "IND"
  },
  {
    "City/Airport": "Ingham",
    "Country": "Australia",
    "IATA code": "IGH"
  },
  {
    "City/Airport": "Innisfail",
    "Country": "Australia",
    "IATA code": "IFL"
  },
  {
    "City/Airport": "Innsbruck",
    "Country": "Austria",
    "IATA code": "INN"
  },
  {
    "City/Airport": "International Falls (MN)",
    "Country": "USA",
    "IATA code": "INL"
  },
  {
    "City/Airport": "Inuvik",
    "Country": "Canada",
    "IATA code": "YEV"
  },
  {
    "City/Airport": "Invercargill",
    "Country": "New Zealand",
    "IATA code": "IVC"
  },
  {
    "City/Airport": "Inverness",
    "Country": "United Kingdom",
    "IATA code": "INV"
  },
  {
    "City/Airport": "Inykern (CA)",
    "Country": "USA",
    "IATA code": "IYK"
  },
  {
    "City/Airport": "Iqaluit (Frobisher Bay)",
    "Country": "Canada",
    "IATA code": "YFB"
  },
  {
    "City/Airport": "Iquitos",
    "Country": "Peru",
    "IATA code": "IQT"
  },
  {
    "City/Airport": "Irkutsk",
    "Country": "Russia",
    "IATA code": "IKT"
  },
  {
    "City/Airport": "Ishigaki",
    "Country": "Japan",
    "IATA code": "ISG"
  },
  {
    "City/Airport": "Islamabad",
    "Country": "Pakistan",
    "IATA code": "ISB"
  },
  {
    "City/Airport": "Islay",
    "Country": "United Kingdom",
    "IATA code": "ILY"
  },
  {
    "City/Airport": "Isle of Man",
    "IATA code": "IOM"
  },
  {
    "City/Airport": "Istanbul",
    "Country": "Turkey",
    "IATA code": "IST"
  },
  {
    "City/Airport": "Istanbul",
    "Country": "Turkey",
    "IATA code": "SAW"
  },
  {
    "City/Airport": "Ithaca/Cortland (NY)",
    "Country": "USA",
    "IATA code": "ITH"
  },
  {
    "City/Airport": "Ivalo",
    "Country": "Finland",
    "IATA code": "IVL"
  },
  {
    "City/Airport": "Ixtapa/Zihuatenejo",
    "Country": "Mexico",
    "IATA code": "ZIH"
  },
  {
    "City/Airport": "Izmir",
    "Country": "Turkey",
    "IATA code": "IZM"
  },
  {
    "City/Airport": "Izmir",
    "Country": "Turkey",
    "IATA code": "ADB"
  },
  {
    "City/Airport": "Jackson Hole (WY)",
    "Country": "USA",
    "IATA code": "JAC"
  },
  {
    "City/Airport": "Jackson (MI)",
    "Country": "USA",
    "IATA code": "JXN"
  },
  {
    "City/Airport": "Jackson, MN",
    "Country": "USA",
    "IATA code": "MJQ"
  },
  {
    "City/Airport": "Jackson (MS)",
    "Country": "USA",
    "IATA code": "JAN"
  },
  {
    "City/Airport": "Jackson (MS)",
    "Country": "USA",
    "IATA code": "HKS"
  },
  {
    "City/Airport": "Jackson (TN)",
    "Country": "USA",
    "IATA code": "MKL"
  },
  {
    "City/Airport": "Jackson Hole (WY)",
    "Country": "USA",
    "IATA code": "JAC"
  },
  {
    "City/Airport": "Jacksonville (AR) Little Rock AFB",
    "Country": "USA",
    "IATA code": "LRF"
  },
  {
    "City/Airport": "Jacksonville (FL)",
    "Country": "USA",
    "IATA code": "NZC"
  },
  {
    "City/Airport": "Jacksonville (FL) Jacksonville NAS",
    "Country": "USA",
    "IATA code": "NIP"
  },
  {
    "City/Airport": "Jacksonville (FL)",
    "Country": "USA",
    "IATA code": "JAX"
  },
  {
    "City/Airport": "Jacksonville (FL)",
    "Country": "USA",
    "IATA code": "CRG"
  },
  {
    "City/Airport": "Jacksonville (IL)",
    "Country": "USA",
    "IATA code": "IJX"
  },
  {
    "City/Airport": "Jacksonville (NC)",
    "Country": "USA",
    "IATA code": "OAJ"
  },
  {
    "City/Airport": "Jacksonville (TX)",
    "Country": "USA",
    "IATA code": "JKV"
  },
  {
    "City/Airport": "Jacmel",
    "Country": "Haiti",
    "IATA code": "JAK"
  },
  {
    "City/Airport": "Jacobabad",
    "Country": "Pakistan",
    "IATA code": "JAG"
  },
  {
    "City/Airport": "Jacobina",
    "Country": "Brazil",
    "IATA code": "JCM"
  },
  {
    "City/Airport": "Jacquinot Bay",
    "Country": "Papua New Guinea",
    "IATA code": "JAQ"
  },
  {
    "City/Airport": "Jaffna",
    "Country": "Sri Lanka",
    "IATA code": "JAF"
  },
  {
    "City/Airport": "Jagdalpur",
    "Country": "India",
    "IATA code": "JGB"
  },
  {
    "City/Airport": "Jaipur",
    "Country": "India",
    "IATA code": "JAI"
  },
  {
    "City/Airport": "Jaisalmer",
    "Country": "India",
    "IATA code": "JSA"
  },
  {
    "City/Airport": "Jakarta",
    "Country": "Indonesia",
    "IATA code": "HLP"
  },
  {
    "City/Airport": "Jakarta",
    "Country": "Indonesia",
    "IATA code": "JKT"
  },
  {
    "City/Airport": "Jakarta",
    "Country": "Indonesia",
    "IATA code": "CGK"
  },
  {
    "City/Airport": "Jalalabad",
    "Country": "Afghanistan",
    "IATA code": "JAA"
  },
  {
    "City/Airport": "Jalandhar",
    "Country": "India",
    "IATA code": "JLR"
  },
  {
    "City/Airport": "Jalapa",
    "Country": "Mexico",
    "IATA code": "JAL"
  },
  {
    "City/Airport": "Jales",
    "Country": "Brazil",
    "IATA code": "JLS"
  },
  {
    "City/Airport": "Jaluit Island",
    "Country": "Marshall Islands",
    "IATA code": "UIT"
  },
  {
    "City/Airport": "Jamba",
    "Country": "Angola",
    "IATA code": "JMB"
  },
  {
    "City/Airport": "Jambi",
    "Country": "Indonesia",
    "IATA code": "DJB"
  },
  {
    "City/Airport": "Jambol",
    "Country": "Bulgaria",
    "IATA code": "JAM"
  },
  {
    "City/Airport": "Jamestown (ND)",
    "Country": "USA",
    "IATA code": "JMS"
  },
  {
    "City/Airport": "Jamestown (NY)",
    "Country": "USA",
    "IATA code": "JHW"
  },
  {
    "City/Airport": "Jammu",
    "Country": "India",
    "IATA code": "IXJ"
  },
  {
    "City/Airport": "Jamnagar",
    "Country": "India",
    "IATA code": "JGA"
  },
  {
    "City/Airport": "Jamshedpur",
    "Country": "India",
    "IATA code": "IXW"
  },
  {
    "City/Airport": "Janakpur",
    "Country": "Nepal",
    "IATA code": "JKR"
  },
  {
    "City/Airport": "Jandakot",
    "Country": "Australia",
    "IATA code": "JAD"
  },
  {
    "City/Airport": "Janesville (WI)",
    "Country": "USA",
    "IATA code": "JVL"
  },
  {
    "City/Airport": "Januaria",
    "Country": "Brazil",
    "IATA code": "JNA"
  },
  {
    "City/Airport": "Jaque",
    "Country": "Panama",
    "IATA code": "JQE"
  },
  {
    "City/Airport": "Jatai",
    "Country": "Brazil",
    "IATA code": "JTI"
  },
  {
    "City/Airport": "Jauja",
    "Country": "Peru",
    "IATA code": "JAU"
  },
  {
    "City/Airport": "Jayapura",
    "Country": "Indonesia",
    "IATA code": "DJJ"
  },
  {
    "City/Airport": "Jeddah",
    "Country": "Saudi Arabia",
    "IATA code": "JED"
  },
  {
    "City/Airport": "Jefferson City (MO)",
    "Country": "USA",
    "IATA code": "JEF"
  },
  {
    "City/Airport": "Jeremie",
    "Country": "Haiti",
    "IATA code": "JEE"
  },
  {
    "City/Airport": "Jerez de la Frontera/Cadiz",
    "Country": "Spain",
    "IATA code": "XRY"
  },
  {
    "City/Airport": "Jersey",
    "Country": "Channel Islands",
    "IATA code": "JER"
  },
  {
    "City/Airport": "Jerusalem",
    "Country": "Israel",
    "IATA code": "JRS"
  },
  {
    "City/Airport": "Jessore",
    "Country": "Bangladesh",
    "IATA code": "JSR"
  },
  {
    "City/Airport": "Jeypore",
    "Country": "India",
    "IATA code": "PYB"
  },
  {
    "City/Airport": "Ji'an",
    "Country": "Jiangxi, China",
    "IATA code": "JGS"
  },
  {
    "City/Airport": "Jiamusi",
    "Country": "PR China",
    "IATA code": "JMU"
  },
  {
    "City/Airport": "Jiayuguan",
    "Country": "PR China",
    "IATA code": "JGN"
  },
  {
    "City/Airport": "Jijel",
    "Country": "Algeria",
    "IATA code": "GJL"
  },
  {
    "City/Airport": "Jijiga",
    "Country": "Ethiopia",
    "IATA code": "JIJ"
  },
  {
    "City/Airport": "Jilin",
    "Country": "PR China",
    "IATA code": "JIL"
  },
  {
    "City/Airport": "Jimma",
    "Country": "Ethiopia",
    "IATA code": "JIM"
  },
  {
    "City/Airport": "Jinan",
    "Country": "Shandong, PR China",
    "IATA code": "TNA"
  },
  {
    "City/Airport": "Jingdezhen",
    "Country": "PR China",
    "IATA code": "JDZ"
  },
  {
    "City/Airport": "Jinghong",
    "Country": "PR China",
    "IATA code": "JHG"
  },
  {
    "City/Airport": "Jining",
    "Country": "PR China",
    "IATA code": "JNG"
  },
  {
    "City/Airport": "Jinja",
    "Country": "Uganda",
    "IATA code": "JIN"
  },
  {
    "City/Airport": "Jinjiang",
    "Country": "PR China",
    "IATA code": "JJN"
  },
  {
    "City/Airport": "Jinka",
    "Country": "Ethiopia",
    "IATA code": "BCO"
  },
  {
    "City/Airport": "Jinzhou",
    "Country": "PR China",
    "IATA code": "JNZ"
  },
  {
    "City/Airport": "Jipijapa",
    "Country": "Ecuador",
    "IATA code": "JIP"
  },
  {
    "City/Airport": "Jiri",
    "Country": "Nepal",
    "IATA code": "JIR"
  },
  {
    "City/Airport": "Jiujiang",
    "Country": "PR China",
    "IATA code": "JIU"
  },
  {
    "City/Airport": "Jiwani",
    "Country": "Pakistan",
    "IATA code": "JIW"
  },
  {
    "City/Airport": "Joacaba",
    "Country": "Brazil",
    "IATA code": "JCB"
  },
  {
    "City/Airport": "Joao Pessoa",
    "Country": "Brazil",
    "IATA code": "JPA"
  },
  {
    "City/Airport": "Jodhpur",
    "Country": "India",
    "IATA code": "JDH"
  },
  {
    "City/Airport": "Jönköping (Jonkoping)",
    "Country": "Sweden",
    "IATA code": "JKG"
  },
  {
    "City/Airport": "Joensuu",
    "Country": "Finland",
    "IATA code": "JOE"
  },
  {
    "City/Airport": "Johannesburg",
    "Country": "South Africa",
    "IATA code": "JNB"
  },
  {
    "City/Airport": "Johnson City (NY)",
    "Country": "USA",
    "IATA code": "BGM"
  },
  {
    "City/Airport": "Johnston Island",
    "Country": "USA",
    "IATA code": "JON"
  },
  {
    "City/Airport": "Johnstown (PA)",
    "Country": "USA",
    "IATA code": "JST"
  },
  {
    "City/Airport": "Johor Bahru",
    "Country": "Malaysia",
    "IATA code": "JHB"
  },
  {
    "City/Airport": "Joinville",
    "Country": "Brazil",
    "IATA code": "JOI"
  },
  {
    "City/Airport": "Jolo",
    "Country": "Philippines",
    "IATA code": "JOL"
  },
  {
    "City/Airport": "Jomsom",
    "Country": "Nepal",
    "IATA code": "JMO"
  },
  {
    "City/Airport": "Jonesboro (AR) Jonesboro Airport",
    "Country": "USA",
    "IATA code": "JBR"
  },
  {
    "City/Airport": "Joplin (MO)",
    "Country": "USA",
    "IATA code": "JLN"
  },
  {
    "City/Airport": "Jorhat",
    "Country": "India",
    "IATA code": "JRH"
  },
  {
    "City/Airport": "Jos",
    "Country": "Nigeria",
    "IATA code": "JOS"
  },
  {
    "City/Airport": "Jose De San Martin",
    "Country": "Argentina",
    "IATA code": "JSM"
  },
  {
    "City/Airport": "Jouf",
    "Country": "Saudi Arabia",
    "IATA code": "AJF"
  },
  {
    "City/Airport": "Juanjui",
    "Country": "Peru",
    "IATA code": "JJI"
  },
  {
    "City/Airport": "Juba",
    "Country": "South Sudan",
    "IATA code": "JUB"
  },
  {
    "City/Airport": "Juist (island)",
    "Country": "Germany",
    "IATA code": "JUI"
  },
  {
    "City/Airport": "Juiz De Fora",
    "Country": "Brazil",
    "IATA code": "JDF"
  },
  {
    "City/Airport": "Jujuy",
    "Country": "Argentina",
    "IATA code": "JUJ"
  },
  {
    "City/Airport": "Julia Creek",
    "Country": "Australia",
    "IATA code": "JCK"
  },
  {
    "City/Airport": "Juliaca",
    "Country": "Peru",
    "IATA code": "JUL"
  },
  {
    "City/Airport": "Jumla",
    "Country": "Nepal",
    "IATA code": "JUM"
  },
  {
    "City/Airport": "Jundah",
    "Country": "Australia",
    "IATA code": "JUN"
  },
  {
    "City/Airport": "Juneau (AK)",
    "Country": "USA",
    "IATA code": "JNU"
  },
  {
    "City/Airport": "Junin",
    "Country": "Argentina",
    "IATA code": "JNI"
  },
  {
    "City/Airport": "Juticalpa",
    "Country": "Honduras",
    "IATA code": "JUT"
  },
  {
    "City/Airport": "Jwaneng",
    "Country": "Botswana",
    "IATA code": "JWA"
  },
  {
    "City/Airport": "Jyväskylä (Jyvaskyla)",
    "Country": "Finland",
    "IATA code": "JYV"
  },
  {
    "City/Airport": "Kabul",
    "Country": "Afghanistan",
    "IATA code": "KBL"
  },
  {
    "City/Airport": "Kagoshima",
    "Country": "Japan",
    "IATA code": "KOJ"
  },
  {
    "City/Airport": "Kahramanmaras",
    "Country": "Turkey",
    "IATA code": "KCM"
  },
  {
    "City/Airport": "Kahului (HI)",
    "Country": "USA",
    "IATA code": "OGG"
  },
  {
    "City/Airport": "Kajaani",
    "Country": "Finland",
    "IATA code": "KAJ"
  },
  {
    "City/Airport": "Kalamata",
    "Country": "Greece",
    "IATA code": "KLX"
  },
  {
    "City/Airport": "Kalamazoo/Battle Creek (MI)",
    "Country": "USA",
    "IATA code": "AZO"
  },
  {
    "City/Airport": "Kalgoorlie",
    "Country": "Australia",
    "IATA code": "KGI"
  },
  {
    "City/Airport": "Kaliningrad",
    "Country": "Russia",
    "IATA code": "KGD"
  },
  {
    "City/Airport": "Kalispell (MT)",
    "Country": "USA",
    "IATA code": "FCA"
  },
  {
    "City/Airport": "Kalmar",
    "Country": "Sweden",
    "IATA code": "KLR"
  },
  {
    "City/Airport": "Kamloops, BC",
    "Country": "Canada",
    "IATA code": "YKA"
  },
  {
    "City/Airport": "Kamuela (HI)",
    "Country": "USA",
    "IATA code": "MUE"
  },
  {
    "City/Airport": "Kano",
    "Country": "Nigeria",
    "IATA code": "KAN"
  },
  {
    "City/Airport": "Kanpur",
    "Country": "India",
    "IATA code": "KNU"
  },
  {
    "City/Airport": "Kansas City (MO)",
    "Country": "USA",
    "IATA code": "MCI"
  },
  {
    "City/Airport": "Kaohsiung International",
    "Country": "Taiwan",
    "IATA code": "KHH"
  },
  {
    "City/Airport": "Kapalua West (HI)",
    "Country": "USA",
    "IATA code": "JHM"
  },
  {
    "City/Airport": "Karachi",
    "Country": "Pakistan",
    "IATA code": "KHI"
  },
  {
    "City/Airport": "Karlsruh",
    "Country": "Germany",
    "IATA code": "FKB"
  },
  {
    "City/Airport": "Karlstad",
    "Country": "Sweden",
    "IATA code": "KSD"
  },
  {
    "City/Airport": "Karpathos",
    "Country": "Greece",
    "IATA code": "AOK"
  },
  {
    "City/Airport": "Karratha",
    "Country": "Australia",
    "IATA code": "KTA"
  },
  {
    "City/Airport": "Kars",
    "Country": "Turkey",
    "IATA code": "KYS"
  },
  {
    "City/Airport": "Karumba",
    "Country": "Australia",
    "IATA code": "KRB"
  },
  {
    "City/Airport": "Karup",
    "Country": "Denmark",
    "IATA code": "KRP"
  },
  {
    "City/Airport": "Kaschechawan, PQ",
    "Country": "Canada",
    "IATA code": "ZKE"
  },
  {
    "City/Airport": "Kassala",
    "Country": "Sudan",
    "IATA code": "KSL"
  },
  {
    "City/Airport": "Katherine",
    "Country": "Australia",
    "IATA code": "KTR"
  },
  {
    "City/Airport": "Kathmandu",
    "Country": "Nepal",
    "IATA code": "KTM"
  },
  {
    "City/Airport": "Katima Mulilo/Mpacha",
    "Country": "Namibia",
    "IATA code": "MPA"
  },
  {
    "City/Airport": "Kauhajoki",
    "Country": "Finland",
    "IATA code": "KHJ"
  },
  {
    "City/Airport": "Kaunakakai (HI)",
    "Country": "USA",
    "IATA code": "MKK"
  },
  {
    "City/Airport": "Kavalla",
    "Country": "Greece",
    "IATA code": "KVA"
  },
  {
    "City/Airport": "Kayseri",
    "Country": "Turkey",
    "IATA code": "ASR"
  },
  {
    "City/Airport": "Kazan",
    "Country": "Russia",
    "IATA code": "KZN"
  },
  {
    "City/Airport": "Keetmanshoop",
    "Country": "Namibia",
    "IATA code": "KMP"
  },
  {
    "City/Airport": "Kelowna, BC",
    "Country": "Canada",
    "IATA code": "YLW"
  },
  {
    "City/Airport": "Kemi/Tornio",
    "Country": "Finland",
    "IATA code": "KEM"
  },
  {
    "City/Airport": "Kenai (AK)",
    "Country": "USA",
    "IATA code": "ENA"
  },
  {
    "City/Airport": "Kent (Manston) Kent International",
    "Country": "United Kingdom",
    "IATA code": "MSE"
  },
  {
    "City/Airport": "Kerry County",
    "Country": "Ireland",
    "IATA code": "KIR"
  },
  {
    "City/Airport": "Ketchikan (AK)",
    "Country": "USA",
    "IATA code": "KTN"
  },
  {
    "City/Airport": "Key West (FL)",
    "Country": "USA",
    "IATA code": "EYW"
  },
  {
    "City/Airport": "Khamis Mushayat",
    "Country": "Saudi Arabia",
    "IATA code": "AHB"
  },
  {
    "City/Airport": "Kharga",
    "Country": "Egypt",
    "IATA code": "UVL"
  },
  {
    "City/Airport": "Kharkov",
    "Country": "Ukraine",
    "IATA code": "HRK"
  },
  {
    "City/Airport": "Khartoum",
    "Country": "Sudan",
    "IATA code": "KRT"
  },
  {
    "City/Airport": "Khuzdar",
    "Country": "Pakistan",
    "IATA code": "KDD"
  },
  {
    "City/Airport": "Kiel",
    "Country": "Germany",
    "IATA code": "KEL"
  },
  {
    "City/Airport": "Kiev",
    "Country": "Ukraine",
    "IATA code": "KBP"
  },
  {
    "City/Airport": "Kiev",
    "Country": "Ukraine",
    "IATA code": "IEV"
  },
  {
    "City/Airport": "Kigali",
    "Country": "Rwanda",
    "IATA code": "KGL"
  },
  {
    "City/Airport": "Kilimadjaro",
    "Country": "Tanzania",
    "IATA code": "JRO"
  },
  {
    "City/Airport": "Killeem (TX)",
    "Country": "USA",
    "IATA code": "ILE"
  },
  {
    "City/Airport": "Kimberley",
    "Country": "South Africa",
    "IATA code": "KIM"
  },
  {
    "City/Airport": "King Island",
    "Country": "King Island (Australia)",
    "IATA code": "KNS"
  },
  {
    "City/Airport": "King Salomon (AK)",
    "Country": "USA",
    "IATA code": "AKN"
  },
  {
    "City/Airport": "Kingscote",
    "Country": "Australia",
    "IATA code": "KGC"
  },
  {
    "City/Airport": "Kingston",
    "Country": "Jamaica",
    "IATA code": "KIN"
  },
  {
    "City/Airport": "Kingston (NC)",
    "Country": "USA",
    "IATA code": "ISO"
  },
  {
    "City/Airport": "Kingstown",
    "Country": "Saint Vincent and the Grenadines",
    "IATA code": "SVD"
  },
  {
    "City/Airport": "Kinshasa",
    "Country": "Congo (DRC)",
    "IATA code": "FIH"
  },
  {
    "City/Airport": "Kiritimati (island)",
    "Country": "Kiribati",
    "IATA code": "CXI"
  },
  {
    "City/Airport": "Kirkenes",
    "Country": "Norway",
    "IATA code": "KKN"
  },
  {
    "City/Airport": "Kirkuk",
    "Country": "Iraq",
    "IATA code": "KIK"
  },
  {
    "City/Airport": "Kirkwall (Orkney)",
    "Country": "United Kingdom",
    "IATA code": "KOI"
  },
  {
    "City/Airport": "Kiruna",
    "Country": "Sweden",
    "IATA code": "KRN"
  },
  {
    "City/Airport": "Kisangani",
    "Country": "Congo (DRC)",
    "IATA code": "FKI"
  },
  {
    "City/Airport": "Kittilä",
    "Country": "Finland",
    "IATA code": "KTT"
  },
  {
    "City/Airport": "Kitwe",
    "Country": "Zambia",
    "IATA code": "KIW"
  },
  {
    "City/Airport": "Klagenfurt",
    "Country": "Austria",
    "IATA code": "KLU"
  },
  {
    "City/Airport": "Klamath Fall (OR)",
    "Country": "USA",
    "IATA code": "LMT"
  },
  {
    "City/Airport": "Klawock (AK)",
    "Country": "USA",
    "IATA code": "KLW"
  },
  {
    "City/Airport": "Kleinsee",
    "Country": "South Africa",
    "IATA code": "KLZ"
  },
  {
    "City/Airport": "Knock",
    "Country": "Ireland",
    "IATA code": "NOC"
  },
  {
    "City/Airport": "Knoxville (TN)",
    "Country": "USA",
    "IATA code": "TYS"
  },
  {
    "City/Airport": "Kobe",
    "Country": "Japan",
    "IATA code": "UKB"
  },
  {
    "City/Airport": "Kochi",
    "Country": "Japan",
    "IATA code": "KCZ"
  },
  {
    "City/Airport": "Köln, Köln/Bonn",
    "Country": "Germany",
    "IATA code": "CGN"
  },
  {
    "City/Airport": "Kodiak (AK)",
    "Country": "USA",
    "IATA code": "ADQ"
  },
  {
    "City/Airport": "Kohat",
    "Country": "Pakistan",
    "IATA code": "OHT"
  },
  {
    "City/Airport": "Kokkola/Pietarsaari",
    "Country": "Finland",
    "IATA code": "KOK"
  },
  {
    "City/Airport": "Kolkata (Calcutta)",
    "Country": "India",
    "IATA code": "CCU"
  },
  {
    "City/Airport": "Komatsu",
    "Country": "Japan",
    "IATA code": "KMQ"
  },
  {
    "City/Airport": "Kona (HI)",
    "Country": "USA",
    "IATA code": "KOA"
  },
  {
    "City/Airport": "Konya",
    "Country": "Turkey",
    "IATA code": "KYA"
  },
  {
    "City/Airport": "Korhogo",
    "Country": "Cote d'Ivoire",
    "IATA code": "HGO"
  },
  {
    "City/Airport": "Kos",
    "Country": "Greece",
    "IATA code": "KGS"
  },
  {
    "City/Airport": "Kota Kinabalu",
    "Country": "Malaysia",
    "IATA code": "BKI"
  },
  {
    "City/Airport": "Kotzbue (AK)",
    "Country": "USA",
    "IATA code": "OTZ"
  },
  {
    "City/Airport": "Kowanyama",
    "Country": "Australia",
    "IATA code": "KWM"
  },
  {
    "City/Airport": "Krakow (Cracow)",
    "Country": "Poland",
    "IATA code": "KRK"
  },
  {
    "City/Airport": "Kristiansand",
    "Country": "Norway",
    "IATA code": "KRS"
  },
  {
    "City/Airport": "Kristianstad",
    "Country": "Sweden",
    "IATA code": "KID"
  },
  {
    "City/Airport": "Kristiansund",
    "Country": "Norway",
    "IATA code": "KSU"
  },
  {
    "City/Airport": "Kuala Lumpur",
    "Country": "Malaysia",
    "IATA code": "KUL"
  },
  {
    "City/Airport": "Kuala Lumpur",
    "Country": "Malaysia",
    "IATA code": "SZB"
  },
  {
    "City/Airport": "Kuantan",
    "Country": "Malaysia",
    "IATA code": "KUA"
  },
  {
    "City/Airport": "Kuching",
    "Country": "Malaysia",
    "IATA code": "KCH"
  },
  {
    "City/Airport": "Kumamoto",
    "Country": "Japan",
    "IATA code": "KMJ"
  },
  {
    "City/Airport": "Kununurra",
    "Country": "Australia",
    "IATA code": "KNX"
  },
  {
    "City/Airport": "Kuopio",
    "Country": "Finland",
    "IATA code": "KUO"
  },
  {
    "City/Airport": "Kushiro",
    "Country": "Japan",
    "IATA code": "KUH"
  },
  {
    "City/Airport": "Kuujjuaq (FortChimo)",
    "Country": "Canada",
    "IATA code": "YVP"
  },
  {
    "City/Airport": "Kuujjuarapik",
    "Country": "Canada",
    "IATA code": "YGW"
  },
  {
    "City/Airport": "Kuusamo",
    "Country": "Finland",
    "IATA code": "KAO"
  },
  {
    "City/Airport": "Kuwait",
    "Country": "Kuwait",
    "IATA code": "KWI"
  },
  {
    "City/Airport": "Kyoto",
    "Country": "Japan",
    "IATA code": "UKY"
  },
  {
    "City/Airport": "Labe",
    "Country": "Guinea",
    "IATA code": "LEK"
  },
  {
    "City/Airport": "Labouchere Bay (AK)",
    "Country": "USA",
    "IATA code": "WLB"
  },
  {
    "City/Airport": "Labuan",
    "Country": "Malaysia",
    "IATA code": "LBU"
  },
  {
    "City/Airport": "Lac Brochet, MB",
    "Country": "Canada",
    "IATA code": "XLB"
  },
  {
    "City/Airport": "La Coruna",
    "Country": "Spain",
    "IATA code": "LCG"
  },
  {
    "City/Airport": "La Crosse (WI)",
    "Country": "USA",
    "IATA code": "LSE"
  },
  {
    "City/Airport": "Lae",
    "Country": "Papua New Guinea",
    "IATA code": "LAE"
  },
  {
    "City/Airport": "La Rochelle",
    "Country": "France",
    "IATA code": "LRH"
  },
  {
    "City/Airport": "Lafayette (IN)",
    "Country": "USA",
    "IATA code": "LAF"
  },
  {
    "City/Airport": "Lafayette, La",
    "Country": "USA",
    "IATA code": "LFT"
  },
  {
    "City/Airport": "Lagos",
    "Country": "Nigeria",
    "IATA code": "LOS"
  },
  {
    "City/Airport": "La Grande",
    "Country": "Canada",
    "IATA code": "YGL"
  },
  {
    "City/Airport": "Lahore",
    "Country": "Pakistan",
    "IATA code": "LHE"
  },
  {
    "City/Airport": "Lake Charles (LA)",
    "Country": "USA",
    "IATA code": "LCH"
  },
  {
    "City/Airport": "Lake Havasu City (AZ)",
    "Country": "USA",
    "IATA code": "HII"
  },
  {
    "City/Airport": "Lake Tahoe (CA)",
    "Country": "USA",
    "IATA code": "TVL"
  },
  {
    "City/Airport": "Lakselv",
    "Country": "Norway",
    "IATA code": "LKL"
  },
  {
    "City/Airport": "Lambarene",
    "Country": "Gabon",
    "IATA code": "LBQ"
  },
  {
    "City/Airport": "Lamezia Terme",
    "Country": "Italy",
    "IATA code": "SUF"
  },
  {
    "City/Airport": "Lampedusa",
    "Country": "Italy",
    "IATA code": "LMP"
  },
  {
    "City/Airport": "Lanai City (HI)",
    "Country": "USA",
    "IATA code": "LNY"
  },
  {
    "City/Airport": "Lancaster (PA)",
    "Country": "USA",
    "IATA code": "LNS"
  },
  {
    "City/Airport": "Land's End",
    "Country": "United Kingdom",
    "IATA code": "LEQ"
  },
  {
    "City/Airport": "Langkawi (islands)",
    "Country": "Malaysia",
    "IATA code": "LGK"
  },
  {
    "City/Airport": "Lannion",
    "Country": "France",
    "IATA code": "LAI"
  },
  {
    "City/Airport": "Lanseria",
    "Country": "South Africa",
    "IATA code": "HLA"
  },
  {
    "City/Airport": "Lansing (MI)",
    "Country": "USA",
    "IATA code": "LAN"
  },
  {
    "City/Airport": "La Paz",
    "Country": "Bolivia",
    "IATA code": "LPB"
  },
  {
    "City/Airport": "La Paz",
    "Country": "Mexico",
    "IATA code": "LAP"
  },
  {
    "City/Airport": "Lappeenranta",
    "Country": "Finland",
    "IATA code": "LPP"
  },
  {
    "City/Airport": "Laramie (WY)",
    "Country": "USA",
    "IATA code": "LAR"
  },
  {
    "City/Airport": "Laredo (TX)",
    "Country": "USA",
    "IATA code": "LRD"
  },
  {
    "City/Airport": "Larnaca",
    "Country": "Cyprus",
    "IATA code": "LCA"
  },
  {
    "City/Airport": "Las Palmas",
    "Country": "Spain",
    "IATA code": "LPA"
  },
  {
    "City/Airport": "Las Vegas (NV)",
    "Country": "USA",
    "IATA code": "LAS"
  },
  {
    "City/Airport": "Latrobe (PA)",
    "Country": "USA",
    "IATA code": "LBE"
  },
  {
    "City/Airport": "Launceston",
    "Country": "Australia",
    "IATA code": "LST"
  },
  {
    "City/Airport": "Laurel/Hattisburg (MS)",
    "Country": "USA",
    "IATA code": "PIB"
  },
  {
    "City/Airport": "Laverton",
    "Country": "Australia",
    "IATA code": "LVO"
  },
  {
    "City/Airport": "Lawton (OK)",
    "Country": "USA",
    "IATA code": "LAW"
  },
  {
    "City/Airport": "Lazaro Cardenas",
    "Country": "Mexico",
    "IATA code": "LZC"
  },
  {
    "City/Airport": "Leaf Rapids",
    "Country": "Canada",
    "IATA code": "YLR"
  },
  {
    "City/Airport": "Learmouth (Exmouth)",
    "Country": "Australia",
    "IATA code": "LEA"
  },
  {
    "City/Airport": "Lebanon (NH)",
    "Country": "USA",
    "IATA code": "LEB"
  },
  {
    "City/Airport": "Leeds/Bradford",
    "Country": "United Kingdom",
    "IATA code": "LBA"
  },
  {
    "City/Airport": "Leinster",
    "Country": "Australia",
    "IATA code": "LER"
  },
  {
    "City/Airport": "Leipzig",
    "Country": "Germany",
    "IATA code": "LEJ"
  },
  {
    "City/Airport": "Lelystad",
    "Country": "Netherlands",
    "IATA code": "LEY"
  },
  {
    "City/Airport": "Leon",
    "Country": "Mexico",
    "IATA code": "BJX"
  },
  {
    "City/Airport": "Leonora",
    "Country": "Australia",
    "IATA code": "LNO"
  },
  {
    "City/Airport": "Lerwick/Tingwall (Shetland Islands)",
    "Country": "United Kingdom",
    "IATA code": "LWK"
  },
  {
    "City/Airport": "Lewiston (ID)",
    "Country": "USA",
    "IATA code": "LWS"
  },
  {
    "City/Airport": "Lewistown (MT)",
    "Country": "USA",
    "IATA code": "LWT"
  },
  {
    "City/Airport": "Lexington (KY)",
    "Country": "USA",
    "IATA code": "LEX"
  },
  {
    "City/Airport": "Libreville",
    "Country": "Gabon",
    "IATA code": "LBV"
  },
  {
    "City/Airport": "Lidkoeping",
    "Country": "Sweden",
    "IATA code": "LDK"
  },
  {
    "City/Airport": "Liege",
    "Country": "Belgium",
    "IATA code": "LGG"
  },
  {
    "City/Airport": "Lifou",
    "Country": "Loyaute, Pazifik",
    "IATA code": "LIF"
  },
  {
    "City/Airport": "Lihue (HI)",
    "Country": "USA",
    "IATA code": "LIH"
  },
  {
    "City/Airport": "Lille",
    "Country": "France",
    "IATA code": "LIL"
  },
  {
    "City/Airport": "Lilongwe",
    "Country": "Malawi",
    "IATA code": "LLW"
  },
  {
    "City/Airport": "Lima",
    "Country": "Peru",
    "IATA code": "LIM"
  },
  {
    "City/Airport": "Limassol",
    "Country": "Cyprus",
    "IATA code": "QLI"
  },
  {
    "City/Airport": "Limoges",
    "Country": "France",
    "IATA code": "LIG"
  },
  {
    "City/Airport": "Lincoln (NE)",
    "Country": "USA",
    "IATA code": "LNK"
  },
  {
    "City/Airport": "Lindeman Island",
    "Country": "Australia",
    "IATA code": "LDC"
  },
  {
    "City/Airport": "Linz",
    "Country": "Austria",
    "IATA code": "LNZ"
  },
  {
    "City/Airport": "Lisala",
    "Country": "Congo (DRC)",
    "IATA code": "LIQ"
  },
  {
    "City/Airport": "Lisbon",
    "Country": "Portugal",
    "IATA code": "LIS"
  },
  {
    "City/Airport": "Lismore",
    "Country": "Australia",
    "IATA code": "LSY"
  },
  {
    "City/Airport": "Little Rock (AR)",
    "Country": "USA",
    "IATA code": "LIT"
  },
  {
    "City/Airport": "Liverpool",
    "Country": "United Kingdom",
    "IATA code": "LPL"
  },
  {
    "City/Airport": "Lizard Island",
    "Country": "Australia",
    "IATA code": "LZR"
  },
  {
    "City/Airport": "Ljubljana",
    "Country": "Slovenia",
    "IATA code": "LJU"
  },
  {
    "City/Airport": "Lockhart River",
    "Country": "Australia",
    "IATA code": "IRG"
  },
  {
    "City/Airport": "Lome",
    "Country": "Togo",
    "IATA code": "LFW"
  },
  {
    "City/Airport": "London",
    "Country": "Canada",
    "IATA code": "YXU"
  },
  {
    "City/Airport": "London Metropolitan Area",
    "Country": "United Kingdom",
    "IATA code": "LON"
  },
  {
    "City/Airport": "London",
    "Country": "United Kingdom",
    "IATA code": "LCY"
  },
  {
    "City/Airport": "London",
    "Country": "United Kingdom",
    "IATA code": "LGW"
  },
  {
    "City/Airport": "London",
    "Country": "United Kingdom",
    "IATA code": "LHR"
  },
  {
    "City/Airport": "London",
    "Country": "United Kingdom",
    "IATA code": "LTN"
  },
  {
    "City/Airport": "London",
    "Country": "United Kingdom",
    "IATA code": "STN"
  },
  {
    "City/Airport": "Londonderry",
    "Country": "United Kingdom",
    "IATA code": "LDY"
  },
  {
    "City/Airport": "Long Beach (CA)",
    "Country": "USA",
    "IATA code": "LGB"
  },
  {
    "City/Airport": "Long Island (AK)",
    "Country": "USA",
    "IATA code": "LIJ"
  },
  {
    "City/Airport": "Long Island, Islip (NY)",
    "Country": "USA",
    "IATA code": "ISP"
  },
  {
    "City/Airport": "Longreach",
    "Country": "Australia",
    "IATA code": "LRE"
  },
  {
    "City/Airport": "Longview/Kilgore (TX)",
    "Country": "USA",
    "IATA code": "GGG"
  },
  {
    "City/Airport": "Longyearbyen",
    "Country": "Svalbard/Norway",
    "IATA code": "LYR"
  },
  {
    "City/Airport": "Loreto",
    "Country": "Mexico",
    "IATA code": "LTO"
  },
  {
    "City/Airport": "Lorient",
    "Country": "France",
    "IATA code": "LRT"
  },
  {
    "City/Airport": "Los Angeles (CA)",
    "Country": "USA",
    "IATA code": "LAX"
  },
  {
    "City/Airport": "Los Cabos",
    "Country": "Mexico",
    "IATA code": "SJD"
  },
  {
    "City/Airport": "Los Mochis",
    "Country": "Mexico",
    "IATA code": "LMM"
  },
  {
    "City/Airport": "Los Rodeos",
    "Country": "Teneriffa/Spain",
    "IATA code": "TFN"
  },
  {
    "City/Airport": "Losinj",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "LSZ"
  },
  {
    "City/Airport": "Lourdes/Tarbes",
    "Country": "France",
    "IATA code": "LDE"
  },
  {
    "City/Airport": "Louisville (KY)",
    "Country": "USA",
    "IATA code": "SDF"
  },
  {
    "City/Airport": "Luanda",
    "Country": "Angola",
    "IATA code": "LAD"
  },
  {
    "City/Airport": "Lubbock (TX)",
    "Country": "USA",
    "IATA code": "LBB"
  },
  {
    "City/Airport": "Lucknow",
    "Country": "India",
    "IATA code": "LKO"
  },
  {
    "City/Airport": "Luederitz",
    "Country": "Namibia",
    "IATA code": "LUD"
  },
  {
    "City/Airport": "Luga",
    "Country": "Malta",
    "IATA code": "MLA"
  },
  {
    "City/Airport": "Lugano",
    "Country": "Switzerland",
    "IATA code": "LUG"
  },
  {
    "City/Airport": "Lulea",
    "Country": "Sweden",
    "IATA code": "LLA"
  },
  {
    "City/Airport": "Lumbumbashi",
    "Country": "Congo (DRC)",
    "IATA code": "FBM"
  },
  {
    "City/Airport": "Lusaka",
    "Country": "Zambia",
    "IATA code": "LUN"
  },
  {
    "City/Airport": "Lusisiki",
    "Country": "South Africa",
    "IATA code": "LUJ"
  },
  {
    "City/Airport": "Luxembourg",
    "Country": "Luxembourg",
    "IATA code": "LUX"
  },
  {
    "City/Airport": "Luxi",
    "Country": "Yunnan, PR China",
    "IATA code": "LUM"
  },
  {
    "City/Airport": "Luxor",
    "Country": "Egypt",
    "IATA code": "LXR"
  },
  {
    "City/Airport": "Lvov (Lwow, Lemberg)",
    "Country": "Ukraine",
    "IATA code": "LWO"
  },
  {
    "City/Airport": "Lydd",
    "Country": "United Kingdom",
    "IATA code": "LYX"
  },
  {
    "City/Airport": "Lynchburg (VA)",
    "Country": "USA",
    "IATA code": "LYH"
  },
  {
    "City/Airport": "Lyon",
    "Country": "France",
    "IATA code": "LYS"
  },
  {
    "City/Airport": "Lyons (KS)",
    "Country": "USA",
    "IATA code": "LYO"
  },
  {
    "City/Airport": "Maastricht/Aachen",
    "Country": "Netherlands",
    "IATA code": "MST"
  },
  {
    "City/Airport": "Macapa",
    "Country": "Brazil",
    "IATA code": "MCP"
  },
  {
    "City/Airport": "Macau",
    "Country": "Macau, China SAR",
    "IATA code": "MFM"
  },
  {
    "City/Airport": "Maceio",
    "Country": "Brazil",
    "IATA code": "MCZ"
  },
  {
    "City/Airport": "Mackay",
    "Country": "Australia",
    "IATA code": "MKY"
  },
  {
    "City/Airport": "Macon (GA)",
    "Country": "USA",
    "IATA code": "MCN"
  },
  {
    "City/Airport": "Mactan Island",
    "Country": "Philippines",
    "IATA code": "NOP"
  },
  {
    "City/Airport": "Madinah (Medina)",
    "Country": "Saudi Arabia",
    "IATA code": "MED"
  },
  {
    "City/Airport": "Madison (WI)",
    "Country": "USA",
    "IATA code": "MSN"
  },
  {
    "City/Airport": "Madras (Chennai)",
    "Country": "India",
    "IATA code": "MAA"
  },
  {
    "City/Airport": "Madrid",
    "Country": "Spain",
    "IATA code": "MAD"
  },
  {
    "City/Airport": "Mahe",
    "Country": "Seychelles",
    "IATA code": "SEZ"
  },
  {
    "City/Airport": "Mahon",
    "Country": "Spain",
    "IATA code": "MAH"
  },
  {
    "City/Airport": "Maitland",
    "Country": "Australia",
    "IATA code": "MTL"
  },
  {
    "City/Airport": "Majunga",
    "Country": "Madagascar",
    "IATA code": "MJN"
  },
  {
    "City/Airport": "Makung",
    "Country": "Taiwan",
    "IATA code": "MZG"
  },
  {
    "City/Airport": "Malabo",
    "Country": "Equatorial Guinea",
    "IATA code": "SSG"
  },
  {
    "City/Airport": "Malaga",
    "Country": "Spain",
    "IATA code": "AGP"
  },
  {
    "City/Airport": "Malatya",
    "Country": "Turkey",
    "IATA code": "MLX"
  },
  {
    "City/Airport": "Male",
    "Country": "Maledives",
    "IATA code": "MLE"
  },
  {
    "City/Airport": "Malindi",
    "Country": "Kenya",
    "IATA code": "MYD"
  },
  {
    "City/Airport": "Malmo (Malmoe)",
    "Country": "Sweden",
    "IATA code": "MMA"
  },
  {
    "City/Airport": "Malmo (Malmö)",
    "Country": "Sweden",
    "IATA code": "MMX"
  },
  {
    "City/Airport": "Man",
    "Country": "Cote d'Ivoire",
    "IATA code": "MJC"
  },
  {
    "City/Airport": "Managua",
    "Country": "Nicaragua",
    "IATA code": "MGA"
  },
  {
    "City/Airport": "Manaus",
    "Country": "Brazil",
    "IATA code": "MAO"
  },
  {
    "City/Airport": "Manchester",
    "Country": "United Kingdom",
    "IATA code": "MAN"
  },
  {
    "City/Airport": "Manchester (NH)",
    "Country": "USA",
    "IATA code": "MHT"
  },
  {
    "City/Airport": "Mandalay",
    "Country": "Myanmar",
    "IATA code": "MDL"
  },
  {
    "City/Airport": "Manguna",
    "Country": "Papua New Guinea",
    "IATA code": "MFO"
  },
  {
    "City/Airport": "Manihi",
    "Country": "French Polynesia",
    "IATA code": "XMH"
  },
  {
    "City/Airport": "Manila",
    "Country": "Philippines",
    "IATA code": "MNL"
  },
  {
    "City/Airport": "Manzanillo",
    "Country": "Mexico",
    "IATA code": "ZLO"
  },
  {
    "City/Airport": "Manzini",
    "Country": "Swaziland",
    "IATA code": "MTS"
  },
  {
    "City/Airport": "Maputo",
    "Country": "Mozambique",
    "IATA code": "MPM"
  },
  {
    "City/Airport": "Mar del Plata",
    "Country": "Argentina",
    "IATA code": "MDQ"
  },
  {
    "City/Airport": "Maracaibo",
    "Country": "Venezuela",
    "IATA code": "MAR"
  },
  {
    "City/Airport": "Maradi",
    "Country": "Niger",
    "IATA code": "MFQ"
  },
  {
    "City/Airport": "Maras",
    "Country": "Turkey",
    "IATA code": "KCM"
  },
  {
    "City/Airport": "Marathon (FL)",
    "Country": "USA",
    "IATA code": "MTH"
  },
  {
    "City/Airport": "Mardin",
    "Country": "Turkey",
    "IATA code": "MQM"
  },
  {
    "City/Airport": "Mare",
    "Country": "New Caledonia",
    "IATA code": "MEE"
  },
  {
    "City/Airport": "Margate",
    "Country": "South Africa",
    "IATA code": "MGH"
  },
  {
    "City/Airport": "Margerita",
    "Country": "Venezuela",
    "IATA code": "PMV"
  },
  {
    "City/Airport": "Maribor",
    "Country": "Slovenia",
    "IATA code": "MBX"
  },
  {
    "City/Airport": "Mariehamn (Maarianhamina)",
    "Country": "Finland",
    "IATA code": "MHQ"
  },
  {
    "City/Airport": "Maroua",
    "Country": "Cameroon",
    "IATA code": "MVR"
  },
  {
    "City/Airport": "Marquette (MI)",
    "Country": "USA",
    "IATA code": "MQT"
  },
  {
    "City/Airport": "Marrakesh",
    "Country": "Morocco",
    "IATA code": "RAK"
  },
  {
    "City/Airport": "Marsa Alam",
    "Country": "Egypt",
    "IATA code": "RMF"
  },
  {
    "City/Airport": "Marsa Matrah (Marsa Matruh)",
    "Country": "Egypt",
    "IATA code": "MUH"
  },
  {
    "City/Airport": "Marseille",
    "Country": "France",
    "IATA code": "MRS"
  },
  {
    "City/Airport": "Marsh Harbour",
    "Country": "Bahamas",
    "IATA code": "MHH"
  },
  {
    "City/Airport": "Martha's Vineyard (MA)",
    "Country": "USA",
    "IATA code": "MVY"
  },
  {
    "City/Airport": "Martinsburg (WV)",
    "Country": "USA",
    "IATA code": "MRB"
  },
  {
    "City/Airport": "Maryborough",
    "Country": "Australia",
    "IATA code": "MBH"
  },
  {
    "City/Airport": "Maseru",
    "Country": "Lesotho",
    "IATA code": "MSU"
  },
  {
    "City/Airport": "Mason City IA",
    "Country": "USA",
    "IATA code": "MCW"
  },
  {
    "City/Airport": "Masvingo",
    "Country": "Zimbabwe",
    "IATA code": "MVZ"
  },
  {
    "City/Airport": "Matsumoto, Nagano",
    "Country": "Japan",
    "IATA code": "MMJ"
  },
  {
    "City/Airport": "Matsuyama",
    "Country": "Japan",
    "IATA code": "MYJ"
  },
  {
    "City/Airport": "Mattoon (IL)",
    "Country": "USA",
    "IATA code": "MTO"
  },
  {
    "City/Airport": "Maun",
    "Country": "Botswana",
    "IATA code": "MUB"
  },
  {
    "City/Airport": "Maupiti",
    "Country": "French Polynesia",
    "IATA code": "MAU"
  },
  {
    "City/Airport": "Mauritius",
    "Country": "Mauritius",
    "IATA code": "MRU"
  },
  {
    "City/Airport": "Mayaguez",
    "Country": "Puerto Rico",
    "IATA code": "MAZ"
  },
  {
    "City/Airport": "Mazatlan",
    "Country": "Mexico",
    "IATA code": "MZT"
  },
  {
    "City/Airport": "McAllen (TX)",
    "Country": "USA",
    "IATA code": "MFE"
  },
  {
    "City/Airport": "Medan",
    "Country": "Indonesia",
    "IATA code": "MES"
  },
  {
    "City/Airport": "Medan",
    "Country": "Indonesia",
    "IATA code": "KNO"
  },
  {
    "City/Airport": "Medellin",
    "Country": "Colombia",
    "IATA code": "MDE"
  },
  {
    "City/Airport": "Medford (OR)",
    "Country": "USA",
    "IATA code": "MFR"
  },
  {
    "City/Airport": "Medina",
    "Country": "Saudi Arabia",
    "IATA code": "MED"
  },
  {
    "City/Airport": "Meekatharra",
    "Country": "Australia",
    "IATA code": "MKR"
  },
  {
    "City/Airport": "Melbourne",
    "Country": "Australia",
    "IATA code": "MEL"
  },
  {
    "City/Airport": "Melbourne (FL)",
    "Country": "USA",
    "IATA code": "MLB"
  },
  {
    "City/Airport": "Melville Hall",
    "Country": "Dominica",
    "IATA code": "DOM"
  },
  {
    "City/Airport": "Memphis (TN)",
    "Country": "USA",
    "IATA code": "MEM"
  },
  {
    "City/Airport": "Mendoza",
    "Country": "Argentina",
    "IATA code": "MDZ"
  },
  {
    "City/Airport": "Manado",
    "Country": "Indonesia",
    "IATA code": "MDC"
  },
  {
    "City/Airport": "Merced (CA)",
    "Country": "USA",
    "IATA code": "MCE"
  },
  {
    "City/Airport": "Merida",
    "Country": "Mexico",
    "IATA code": "MID"
  },
  {
    "City/Airport": "Meridian (MS)",
    "Country": "USA",
    "IATA code": "MEI"
  },
  {
    "City/Airport": "Merimbula",
    "Country": "Australia",
    "IATA code": "MIM"
  },
  {
    "City/Airport": "Messina",
    "Country": "South Africa",
    "IATA code": "MEZ"
  },
  {
    "City/Airport": "Metlakatla (AK)",
    "Country": "USA",
    "IATA code": "MTM"
  },
  {
    "City/Airport": "Metz",
    "Country": "France",
    "IATA code": "MZM"
  },
  {
    "City/Airport": "Metz/Nancy Met",
    "Country": "France",
    "IATA code": "ETZ"
  },
  {
    "City/Airport": "Mexicali",
    "Country": "Mexico",
    "IATA code": "MXL"
  },
  {
    "City/Airport": "Mexico City",
    "Country": "Mexico",
    "IATA code": "MEX"
  },
  {
    "City/Airport": "Mexico City",
    "Country": "Mexico",
    "IATA code": "AZP"
  },
  {
    "City/Airport": "Mexico City",
    "Country": "Mexico",
    "IATA code": "MEX"
  },
  {
    "City/Airport": "Mexico City",
    "Country": "Mexico",
    "IATA code": "NLU"
  },
  {
    "City/Airport": "Mfuwe",
    "Country": "Zambia",
    "IATA code": "MFU"
  },
  {
    "City/Airport": "Miami (FL)",
    "Country": "USA",
    "IATA code": "MIA"
  },
  {
    "City/Airport": "Mianwali",
    "Country": "Pakistan",
    "IATA code": "MWD"
  },
  {
    "City/Airport": "Middlemount",
    "Country": "Australia",
    "IATA code": "MMM"
  },
  {
    "City/Airport": "Midland/Odessa (TX)",
    "Country": "USA",
    "IATA code": "MAF"
  },
  {
    "City/Airport": "Midway Island",
    "Country": "US Minor Outlying Islands",
    "IATA code": "MDY"
  },
  {
    "City/Airport": "Mikkeli",
    "Country": "Finland",
    "IATA code": "MIK"
  },
  {
    "City/Airport": "Milan",
    "Country": "Italy",
    "IATA code": "MIL"
  },
  {
    "City/Airport": "Milan",
    "Country": "Italy",
    "IATA code": "LIN"
  },
  {
    "City/Airport": "Milan",
    "Country": "Italy",
    "IATA code": "MXP"
  },
  {
    "City/Airport": "Milan",
    "Country": "Italy",
    "IATA code": "BGY"
  },
  {
    "City/Airport": "Mildura",
    "Country": "Australia",
    "IATA code": "MQL"
  },
  {
    "City/Airport": "Miles City (MT)",
    "Country": "USA",
    "IATA code": "MLS"
  },
  {
    "City/Airport": "Milford Sound",
    "Country": "New Zealand",
    "IATA code": "MFN"
  },
  {
    "City/Airport": "Milwaukee (WI)",
    "Country": "USA",
    "IATA code": "MKE"
  },
  {
    "City/Airport": "Minatitlan",
    "Country": "Mexico",
    "IATA code": "MTT"
  },
  {
    "City/Airport": "Mineralnye Vody",
    "Country": "Russia",
    "IATA code": "MRV"
  },
  {
    "City/Airport": "Minneapolis",
    "Country": "USA",
    "IATA code": "MSP"
  },
  {
    "City/Airport": "Minot (ND)",
    "Country": "USA",
    "IATA code": "MOT"
  },
  {
    "City/Airport": "Minsk, International",
    "Country": "Belarus",
    "IATA code": "MSQ"
  },
  {
    "City/Airport": "Miri",
    "Country": "Malaysia",
    "IATA code": "MYY"
  },
  {
    "City/Airport": "Mirpur",
    "Country": "Pakistan",
    "IATA code": "QML"
  },
  {
    "City/Airport": "Missula (MT)",
    "Country": "USA",
    "IATA code": "MSO"
  },
  {
    "City/Airport": "Mitchell (SD)",
    "Country": "USA",
    "IATA code": "MHE"
  },
  {
    "City/Airport": "Miyako Jima (Ryuku Islands)",
    "Country": "Japan",
    "IATA code": "MMY"
  },
  {
    "City/Airport": "Miyazaki",
    "Country": "Japan",
    "IATA code": "KMI"
  },
  {
    "City/Airport": "Mkambati",
    "Country": "South Africa",
    "IATA code": "MBM"
  },
  {
    "City/Airport": "Moanda",
    "Country": "Gabon",
    "IATA code": "MFF"
  },
  {
    "City/Airport": "Mobile (AL)",
    "Country": "USA",
    "IATA code": "MOB"
  },
  {
    "City/Airport": "Modesto (CA)",
    "Country": "USA",
    "IATA code": "MOD"
  },
  {
    "City/Airport": "Moenjodaro",
    "Country": "Pakistan",
    "IATA code": "MJD"
  },
  {
    "City/Airport": "Mogadishu",
    "Country": "Somalia",
    "IATA code": "MGQ"
  },
  {
    "City/Airport": "Mokuti",
    "Country": "Namibia",
    "IATA code": "OKU"
  },
  {
    "City/Airport": "Moline/Quad Cities (IL)",
    "Country": "USA",
    "IATA code": "MLI"
  },
  {
    "City/Airport": "Mombasa",
    "Country": "Kenya",
    "IATA code": "MBA"
  },
  {
    "City/Airport": "Monastir",
    "Country": "Tunisia",
    "IATA code": "MIR"
  },
  {
    "City/Airport": "Moncton",
    "Country": "Canada",
    "IATA code": "YQM"
  },
  {
    "City/Airport": "Monroe (LA)",
    "Country": "USA",
    "IATA code": "MLU"
  },
  {
    "City/Airport": "Monrovia",
    "Country": "Liberia",
    "IATA code": "MLW"
  },
  {
    "City/Airport": "Monrovia",
    "Country": "Liberia",
    "IATA code": "ROB"
  },
  {
    "City/Airport": "Montego Bay",
    "Country": "Jamaica",
    "IATA code": "MBJ"
  },
  {
    "City/Airport": "Montenegro",
    "Country": "Brazil",
    "IATA code": "QGF"
  },
  {
    "City/Airport": "Monterey (CA)",
    "Country": "USA",
    "IATA code": "MRY"
  },
  {
    "City/Airport": "Monterrey",
    "Country": "Mexico",
    "IATA code": "MTY"
  },
  {
    "City/Airport": "Monterrey",
    "Country": "Mexico",
    "IATA code": "NTR"
  },
  {
    "City/Airport": "Montevideo",
    "Country": "Uruguay",
    "IATA code": "MVD"
  },
  {
    "City/Airport": "Montgomery (AL)",
    "Country": "USA",
    "IATA code": "MGM"
  },
  {
    "City/Airport": "Montpellier",
    "Country": "France",
    "IATA code": "MPL"
  },
  {
    "City/Airport": "Montreal",
    "Country": "Canada",
    "IATA code": "YMQ"
  },
  {
    "City/Airport": "Montreal",
    "Country": "Canada",
    "IATA code": "YUL"
  },
  {
    "City/Airport": "Montreal",
    "Country": "Canada",
    "IATA code": "YMX"
  },
  {
    "City/Airport": "Montrose/Tellruide (CO)",
    "Country": "USA",
    "IATA code": "MTJ"
  },
  {
    "City/Airport": "Moorea",
    "Country": "French Polynesia",
    "IATA code": "MOZ"
  },
  {
    "City/Airport": "Moranbah",
    "Country": "Australia",
    "IATA code": "MOV"
  },
  {
    "City/Airport": "Moree",
    "Country": "Australia",
    "IATA code": "MRZ"
  },
  {
    "City/Airport": "Morelia",
    "Country": "Mexico",
    "IATA code": "MLM"
  },
  {
    "City/Airport": "Morgantown (WV)",
    "Country": "USA",
    "IATA code": "MGW"
  },
  {
    "City/Airport": "Morioka, Hanamaki",
    "Country": "Japan",
    "IATA code": "HNA"
  },
  {
    "City/Airport": "Moroni",
    "Country": "Comoros (Comores)",
    "IATA code": "HAH"
  },
  {
    "City/Airport": "Moruya",
    "Country": "Australia",
    "IATA code": "MYA"
  },
  {
    "City/Airport": "Moscow",
    "Country": "Russia",
    "IATA code": "MOW"
  },
  {
    "City/Airport": "Moscow",
    "Country": "Russia",
    "IATA code": "DME"
  },
  {
    "City/Airport": "Moscow",
    "Country": "Russia",
    "IATA code": "SVO"
  },
  {
    "City/Airport": "Moscow",
    "Country": "Russia",
    "IATA code": "VKO"
  },
  {
    "City/Airport": "Moses Lake (WA)",
    "Country": "USA",
    "IATA code": "MWH"
  },
  {
    "City/Airport": "Mossel Bay",
    "Country": "South Africa",
    "IATA code": "MZY"
  },
  {
    "City/Airport": "Mostar",
    "Country": "Bosnia and Herzegovina",
    "IATA code": "OMO"
  },
  {
    "City/Airport": "Mosul",
    "Country": "Iraq",
    "IATA code": "OSM"
  },
  {
    "City/Airport": "Mouila",
    "Country": "Gabon",
    "IATA code": "MJL"
  },
  {
    "City/Airport": "Moundou",
    "Country": "Chad",
    "IATA code": "MQQ"
  },
  {
    "City/Airport": "Mount Cook",
    "Country": "New Zealand",
    "IATA code": "GTN"
  },
  {
    "City/Airport": "Mount Gambier",
    "Country": "Australia",
    "IATA code": "MGB"
  },
  {
    "City/Airport": "Mount Magnet",
    "Country": "Australia",
    "IATA code": "MMG"
  },
  {
    "City/Airport": "Mt. Isa",
    "Country": "Australia",
    "IATA code": "ISA"
  },
  {
    "City/Airport": "Mt. McKinley (AK)",
    "Country": "USA",
    "IATA code": "MCL"
  },
  {
    "City/Airport": "Muenchen (Munich)",
    "Country": "Germany",
    "IATA code": "MUC"
  },
  {
    "City/Airport": "Muenster/Osnabrueck",
    "Country": "Germany",
    "IATA code": "FMO"
  },
  {
    "City/Airport": "Mulhouse",
    "Country": "France",
    "IATA code": "MLH"
  },
  {
    "City/Airport": "Multan",
    "Country": "Pakistan",
    "IATA code": "MUX"
  },
  {
    "City/Airport": "Murcia",
    "Country": "Spain",
    "IATA code": "MJV"
  },
  {
    "City/Airport": "Murmansk",
    "Country": "Russia",
    "IATA code": "MMK"
  },
  {
    "City/Airport": "Mus",
    "Country": "Turkey",
    "IATA code": "MSR"
  },
  {
    "City/Airport": "Muscat",
    "Country": "Oman",
    "IATA code": "MCT"
  },
  {
    "City/Airport": "Muscle Shoals (AL)",
    "Country": "USA",
    "IATA code": "MSL"
  },
  {
    "City/Airport": "Muskegon (MI)",
    "Country": "USA",
    "IATA code": "MKG"
  },
  {
    "City/Airport": "Muzaffarabad",
    "Country": "Pakistan",
    "IATA code": "MFG"
  },
  {
    "City/Airport": "Mvengue",
    "Country": "Gabon",
    "IATA code": "MVB"
  },
  {
    "City/Airport": "Mykonos",
    "Country": "Greece",
    "IATA code": "JMK"
  },
  {
    "City/Airport": "Myrtle Beach (SC)",
    "Country": "USA",
    "IATA code": "MYR"
  },
  {
    "City/Airport": "Myrtle Beach (SC)",
    "Country": "USA",
    "IATA code": "CRE"
  },
  {
    "City/Airport": "Mysore",
    "Country": "India",
    "IATA code": "MYQ"
  },
  {
    "City/Airport": "Mytilene (Lesbos)",
    "Country": "Greece",
    "IATA code": "MJT"
  },
  {
    "City/Airport": "Mzamba",
    "Country": "South Africa",
    "IATA code": "MZF"
  },
  {
    "City/Airport": "Nadi",
    "Country": "Fiji",
    "IATA code": "NAN"
  },
  {
    "City/Airport": "Nagasaki",
    "Country": "Japan",
    "IATA code": "NGS"
  },
  {
    "City/Airport": "Nagoya",
    "Country": "Japan",
    "IATA code": "NGO"
  },
  {
    "City/Airport": "Nagpur",
    "Country": "India",
    "IATA code": "NAG"
  },
  {
    "City/Airport": "Nairobi",
    "Country": "Kenya",
    "IATA code": "NBO"
  },
  {
    "City/Airport": "Nakhichevan",
    "Country": "Azerbaijan",
    "IATA code": "NAJ"
  },
  {
    "City/Airport": "Nakhon Si Thammarat",
    "Country": "Thailand",
    "IATA code": "NST"
  },
  {
    "City/Airport": "Nancy",
    "Country": "France",
    "IATA code": "ENC"
  },
  {
    "City/Airport": "Nanisivik",
    "Country": "Canada",
    "IATA code": "YSR"
  },
  {
    "City/Airport": "Nanning",
    "Country": "Guangxi, PR China",
    "IATA code": "NNG"
  },
  {
    "City/Airport": "Nantes",
    "Country": "France",
    "IATA code": "NTE"
  },
  {
    "City/Airport": "Nantucket (MA)",
    "Country": "USA",
    "IATA code": "ACK"
  },
  {
    "City/Airport": "Naples",
    "Country": "Italy",
    "IATA code": "NAP"
  },
  {
    "City/Airport": "Naples (FL)",
    "Country": "USA",
    "IATA code": "APF"
  },
  {
    "City/Airport": "Narrabri",
    "Country": "Australia",
    "IATA code": "NAA"
  },
  {
    "City/Airport": "Narrandera",
    "Country": "Australia",
    "IATA code": "NRA"
  },
  {
    "City/Airport": "Narsarsuaq",
    "Country": "Greenland",
    "IATA code": "UAK"
  },
  {
    "City/Airport": "Nashville (TN)",
    "Country": "USA",
    "IATA code": "BNA"
  },
  {
    "City/Airport": "Nassau",
    "Country": "Bahamas",
    "IATA code": "NAS"
  },
  {
    "City/Airport": "Natal",
    "Country": "Brazil",
    "IATA code": "NAT"
  },
  {
    "City/Airport": "Nausori",
    "Country": "Fiji/Suva",
    "IATA code": "SUV"
  },
  {
    "City/Airport": "Nawab Shah",
    "Country": "Pakistan",
    "IATA code": "WNS"
  },
  {
    "City/Airport": "Naxos",
    "Country": "Greece",
    "IATA code": "JNX"
  },
  {
    "City/Airport": "N'Djamena",
    "Country": "Chad",
    "IATA code": "NDJ"
  },
  {
    "City/Airport": "N'Dola",
    "Country": "Zambia",
    "IATA code": "NLA"
  },
  {
    "City/Airport": "Nelson",
    "Country": "New Zealand",
    "IATA code": "NSN"
  },
  {
    "City/Airport": "Nelspruit",
    "Country": "South Africa",
    "IATA code": "NLP"
  },
  {
    "City/Airport": "Nelspruit",
    "Country": "South Africa",
    "IATA code": "MQP"
  },
  {
    "City/Airport": "Nevis",
    "Country": "St. Kitts and Nevis",
    "IATA code": "NEV"
  },
  {
    "City/Airport": "New Bern (NC)",
    "Country": "USA",
    "IATA code": "EWN"
  },
  {
    "City/Airport": "New Haven (CT)",
    "Country": "USA",
    "IATA code": "HVN"
  },
  {
    "City/Airport": "New Orleans, La",
    "Country": "USA",
    "IATA code": "MSY"
  },
  {
    "City/Airport": "Newquay",
    "Country": "United Kingdom",
    "IATA code": "NQY"
  },
  {
    "City/Airport": "New Valley",
    "Country": "Egypt",
    "IATA code": "UVL"
  },
  {
    "City/Airport": "New York",
    "Country": "USA",
    "IATA code": "JFK"
  },
  {
    "City/Airport": "New York",
    "Country": "USA",
    "IATA code": "LGA"
  },
  {
    "City/Airport": "New York",
    "Country": "USA",
    "IATA code": "EWR"
  },
  {
    "City/Airport": "New York (NY)",
    "Country": "USA",
    "IATA code": "NYC"
  },
  {
    "City/Airport": "Newburgh (NY)",
    "Country": "USA",
    "IATA code": "SWF"
  },
  {
    "City/Airport": "Newcastle",
    "Country": "Australia",
    "IATA code": "BEO"
  },
  {
    "City/Airport": "Newcastle",
    "Country": "Australia",
    "IATA code": "NTL"
  },
  {
    "City/Airport": "Newcastle",
    "Country": "United Kingdom",
    "IATA code": "NCL"
  },
  {
    "City/Airport": "Newcastle",
    "Country": "South Africa",
    "IATA code": "NCS"
  },
  {
    "City/Airport": "Newman",
    "Country": "Australia",
    "IATA code": "ZNE"
  },
  {
    "City/Airport": "Newport News/Williamsburg (VA)",
    "Country": "USA",
    "IATA code": "PHF"
  },
  {
    "City/Airport": "N'Gaoundere",
    "Country": "Cameroon",
    "IATA code": "NGE"
  },
  {
    "City/Airport": "Niagara Falls International",
    "Country": "USA",
    "IATA code": "IAG"
  },
  {
    "City/Airport": "Niamey",
    "Country": "Niger",
    "IATA code": "NIM"
  },
  {
    "City/Airport": "Nice",
    "Country": "France",
    "IATA code": "NCE"
  },
  {
    "City/Airport": "Nicosia",
    "Country": "Cyprus",
    "IATA code": "NIC"
  },
  {
    "City/Airport": "Nikolaev",
    "Country": "Ukraine",
    "IATA code": "NLV"
  },
  {
    "City/Airport": "Niigata",
    "Country": "Japan",
    "IATA code": "KIJ"
  },
  {
    "City/Airport": "Nimes",
    "Country": "France",
    "IATA code": "FNI"
  },
  {
    "City/Airport": "Nis",
    "Country": "Serbia",
    "IATA code": "INI"
  },
  {
    "City/Airport": "Nizhny Novgorod",
    "Country": "Russia",
    "IATA code": "GOJ"
  },
  {
    "City/Airport": "Nome (AK)",
    "Country": "USA",
    "IATA code": "OME"
  },
  {
    "City/Airport": "Noosa",
    "Country": "Australia",
    "IATA code": "NSA"
  },
  {
    "City/Airport": "Norfolk Island",
    "Country": "Australia",
    "IATA code": "NLK"
  },
  {
    "City/Airport": "Norfolk/Virginia Beach (VA)",
    "Country": "USA",
    "IATA code": "ORF"
  },
  {
    "City/Airport": "Norman Wells",
    "Country": "Canada",
    "IATA code": "YVQ"
  },
  {
    "City/Airport": "Norrkoeping",
    "Country": "Sweden",
    "IATA code": "NRK"
  },
  {
    "City/Airport": "North Bend (OR)",
    "Country": "USA",
    "IATA code": "OTH"
  },
  {
    "City/Airport": "North Eleuthera",
    "Country": "Bahamas",
    "IATA code": "ELH"
  },
  {
    "City/Airport": "Norwich",
    "Country": "United Kingdom",
    "IATA code": "NWI"
  },
  {
    "City/Airport": "Nottingham",
    "Country": "United Kingdom",
    "IATA code": "EMA"
  },
  {
    "City/Airport": "Nouadhibou",
    "Country": "Mauritania",
    "IATA code": "NDB"
  },
  {
    "City/Airport": "Nouakchott",
    "Country": "Mauritania",
    "IATA code": "NKC"
  },
  {
    "City/Airport": "Noumea",
    "Country": "New Caledonia",
    "IATA code": "NOU"
  },
  {
    "City/Airport": "Novi Sad",
    "Country": "Serbia",
    "IATA code": "QND"
  },
  {
    "City/Airport": "Novosibirsk",
    "Country": "Russia",
    "IATA code": "OVB"
  },
  {
    "City/Airport": "Nürnberg (Nuremberg)",
    "Country": "Germany",
    "IATA code": "NUE"
  },
  {
    "City/Airport": "Nuevo Laredo",
    "Country": "Mexico",
    "IATA code": "NLD"
  },
  {
    "City/Airport": "Nuku'alofa",
    "Country": "Tonga",
    "IATA code": "TBU"
  },
  {
    "City/Airport": "Oakland (CA)",
    "Country": "USA",
    "IATA code": "OAK"
  },
  {
    "City/Airport": "Oaxaca",
    "Country": "Mexico",
    "IATA code": "OAX"
  },
  {
    "City/Airport": "Odense",
    "Country": "Denmark",
    "IATA code": "ODE"
  },
  {
    "City/Airport": "Odessa",
    "Country": "Ukraine",
    "IATA code": "ODS"
  },
  {
    "City/Airport": "Oerebro",
    "Country": "Sweden",
    "IATA code": "ORB"
  },
  {
    "City/Airport": "Ohrid",
    "Country": "Macedonia",
    "IATA code": "OHD"
  },
  {
    "City/Airport": "Oita",
    "Country": "Japan",
    "IATA code": "OIT"
  },
  {
    "City/Airport": "Okayama",
    "Country": "Japan",
    "IATA code": "OKJ"
  },
  {
    "City/Airport": "Okinawa, Ryukyo Island",
    "Country": "Japan",
    "IATA code": "OKA"
  },
  {
    "City/Airport": "Oklahoma City (OK)",
    "Country": "USA",
    "IATA code": "OKC"
  },
  {
    "City/Airport": "Olbia",
    "Country": "Italy",
    "IATA code": "OLB"
  },
  {
    "City/Airport": "Olympic Dam",
    "Country": "Australia",
    "IATA code": "OLP"
  },
  {
    "City/Airport": "Omaha (NE)",
    "Country": "USA",
    "IATA code": "OMA"
  },
  {
    "City/Airport": "Ondangwa",
    "Country": "Namibia",
    "IATA code": "OND"
  },
  {
    "City/Airport": "Ontario (CA)",
    "Country": "USA",
    "IATA code": "ONT"
  },
  {
    "City/Airport": "Oran (Ouahran)",
    "Country": "Algeria",
    "IATA code": "ORN"
  },
  {
    "City/Airport": "Orange",
    "Country": "Australia",
    "IATA code": "OAG"
  },
  {
    "City/Airport": "Orange County (Santa Ana) (CA)",
    "Country": "USA",
    "IATA code": "SNA"
  },
  {
    "City/Airport": "Oranjemund",
    "Country": "Namibia",
    "IATA code": "OMD"
  },
  {
    "City/Airport": "Oranjestad",
    "Country": "Aruba",
    "IATA code": "AUA"
  },
  {
    "City/Airport": "Orkney",
    "Country": "United Kingdom",
    "IATA code": "KOI"
  },
  {
    "City/Airport": "Orlando Metropolitan Area (FL)",
    "Country": "USA",
    "IATA code": "ORL"
  },
  {
    "City/Airport": "Orlando",
    "Country": "USA",
    "IATA code": "MCO"
  },
  {
    "City/Airport": "Orpheus Island",
    "Country": "Australia",
    "IATA code": "ORS"
  },
  {
    "City/Airport": "Osaka, Metropolitan Area",
    "Country": "Japan",
    "IATA code": "OSA"
  },
  {
    "City/Airport": "Osaka",
    "Country": "Japan",
    "IATA code": "ITM"
  },
  {
    "City/Airport": "Osaka",
    "Country": "Japan",
    "IATA code": "KIX"
  },
  {
    "City/Airport": "Oshkosh (WI)",
    "Country": "USA",
    "IATA code": "OSH"
  },
  {
    "City/Airport": "Osijek",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "OSI"
  },
  {
    "City/Airport": "Oslo",
    "Country": "Norway",
    "IATA code": "OSL"
  },
  {
    "City/Airport": "Oslo",
    "Country": "Norway",
    "IATA code": "FBU"
  },
  {
    "City/Airport": "Oslo",
    "Country": "Norway",
    "IATA code": "TRF"
  },
  {
    "City/Airport": "Ottawa",
    "Country": "Canada",
    "IATA code": "YOW"
  },
  {
    "City/Airport": "Ouadda",
    "Country": "Central African Republic",
    "IATA code": "ODA"
  },
  {
    "City/Airport": "Ouarzazate",
    "Country": "Morocco",
    "IATA code": "OZZ"
  },
  {
    "City/Airport": "Oudtshoorn",
    "Country": "South Africa",
    "IATA code": "OUH"
  },
  {
    "City/Airport": "Ouagadougou",
    "Country": "Burkina Faso",
    "IATA code": "OUA"
  },
  {
    "City/Airport": "Oujda",
    "Country": "Morocco",
    "IATA code": "OUD"
  },
  {
    "City/Airport": "Oulu",
    "Country": "Finland",
    "IATA code": "OUL"
  },
  {
    "City/Airport": "Out Skerries (Shetland)",
    "Country": "United Kingdom",
    "IATA code": "OUK"
  },
  {
    "City/Airport": "Oviedo",
    "Country": "Spain",
    "IATA code": "OVD"
  },
  {
    "City/Airport": "Owensboro (KY)",
    "Country": "USA",
    "IATA code": "OWB"
  },
  {
    "City/Airport": "Oxnard (CA)",
    "Country": "USA",
    "IATA code": "OXR"
  },
  {
    "City/Airport": "Oyem",
    "Country": "Gabon/Loyautte",
    "IATA code": "UVE"
  },
  {
    "City/Airport": "Paderborn/Lippstadt",
    "Country": "Germany",
    "IATA code": "PAD"
  },
  {
    "City/Airport": "Paducah (KY)",
    "Country": "USA",
    "IATA code": "PAH"
  },
  {
    "City/Airport": "Page/Lake Powell (AZ)",
    "Country": "USA",
    "IATA code": "PGA"
  },
  {
    "City/Airport": "Pago Pago",
    "Country": "American Samoa",
    "IATA code": "PPG"
  },
  {
    "City/Airport": "Pakersburg (WV) /Marietta (OH)",
    "Country": "USA",
    "IATA code": "PKB"
  },
  {
    "City/Airport": "Palermo",
    "Country": "Italy",
    "IATA code": "PMO"
  },
  {
    "City/Airport": "Palma de Mallorca",
    "Country": "Spain",
    "IATA code": "PMI"
  },
  {
    "City/Airport": "Palmas",
    "Country": "Brazil",
    "IATA code": "PMW"
  },
  {
    "City/Airport": "Palmdale/Lancaster (CA)",
    "Country": "USA",
    "IATA code": "PMD"
  },
  {
    "City/Airport": "Palmerston North",
    "Country": "New Zealand",
    "IATA code": "PMR"
  },
  {
    "City/Airport": "Palm Springs (CA)",
    "Country": "USA",
    "IATA code": "PSP"
  },
  {
    "City/Airport": "Panama City",
    "Country": "Panama",
    "IATA code": "PTY"
  },
  {
    "City/Airport": "Panama City (FL)",
    "Country": "USA",
    "IATA code": "PFN"
  },
  {
    "City/Airport": "Panjgur",
    "Country": "Pakistan",
    "IATA code": "PJG"
  },
  {
    "City/Airport": "Pantelleria",
    "Country": "Italy",
    "IATA code": "PNL"
  },
  {
    "City/Airport": "Papeete",
    "Country": "French Polynesia (Tahiti)",
    "IATA code": "PPT"
  },
  {
    "City/Airport": "Paphos",
    "Country": "Cyprus",
    "IATA code": "PFO"
  },
  {
    "City/Airport": "Paraburdoo",
    "Country": "Australia",
    "IATA code": "PBO"
  },
  {
    "City/Airport": "Paramaribo",
    "Country": "Suriname",
    "IATA code": "PBM"
  },
  {
    "City/Airport": "Paris",
    "Country": "France",
    "IATA code": "PAR"
  },
  {
    "City/Airport": "Paris",
    "Country": "France",
    "IATA code": "CDG"
  },
  {
    "City/Airport": "Paris",
    "Country": "France",
    "IATA code": "LBG"
  },
  {
    "City/Airport": "Paris",
    "Country": "France",
    "IATA code": "ORY"
  },
  {
    "City/Airport": "Paro",
    "Country": "Bhutan",
    "IATA code": "PBH"
  },
  {
    "City/Airport": "Pasco (WA)",
    "Country": "USA",
    "IATA code": "PSC"
  },
  {
    "City/Airport": "Pasni",
    "Country": "Pakistan",
    "IATA code": "PSI"
  },
  {
    "City/Airport": "Patna",
    "Country": "India",
    "IATA code": "PAT"
  },
  {
    "City/Airport": "Pattaya",
    "Country": "Thailand",
    "IATA code": "PYX"
  },
  {
    "City/Airport": "Pau",
    "Country": "France",
    "IATA code": "PUF"
  },
  {
    "City/Airport": "Pellston (MI)",
    "Country": "USA",
    "IATA code": "PLN"
  },
  {
    "City/Airport": "Penang International",
    "Country": "Malaysia",
    "IATA code": "PEN"
  },
  {
    "City/Airport": "Pendelton (OR)",
    "Country": "USA",
    "IATA code": "PDT"
  },
  {
    "City/Airport": "Pensacola (FL)",
    "Country": "USA",
    "IATA code": "PNS"
  },
  {
    "City/Airport": "Peoria/Bloomington (IL)",
    "Country": "USA",
    "IATA code": "PIA"
  },
  {
    "City/Airport": "Pereira",
    "Country": "Colombia",
    "IATA code": "PEI"
  },
  {
    "City/Airport": "Perpignan",
    "Country": "France",
    "IATA code": "PGF"
  },
  {
    "City/Airport": "Perth International",
    "Country": "Australia",
    "IATA code": "PER"
  },
  {
    "City/Airport": "Perugia",
    "Country": "Italy",
    "IATA code": "PEG"
  },
  {
    "City/Airport": "Pescara",
    "Country": "Italy",
    "IATA code": "PSR"
  },
  {
    "City/Airport": "Peshawar",
    "Country": "Pakistan",
    "IATA code": "PEW"
  },
  {
    "City/Airport": "Petersburg (AK)",
    "Country": "USA",
    "IATA code": "PSG"
  },
  {
    "City/Airport": "Phalaborwa",
    "Country": "South Africa",
    "IATA code": "PHW"
  },
  {
    "City/Airport": "Philadelphia (PA)",
    "Country": "USA",
    "IATA code": "PHL"
  },
  {
    "City/Airport": "Phnom Penh",
    "Country": "Cambodia",
    "IATA code": "PNH"
  },
  {
    "City/Airport": "Phoenix (AZ)",
    "Country": "USA",
    "IATA code": "PHX"
  },
  {
    "City/Airport": "Phuket",
    "Country": "Thailand",
    "IATA code": "HKT"
  },
  {
    "City/Airport": "Pierre (SD)",
    "Country": "USA",
    "IATA code": "PIR"
  },
  {
    "City/Airport": "Pietermaritzburg",
    "Country": "South Africa",
    "IATA code": "PZB"
  },
  {
    "City/Airport": "Pietersburg",
    "Country": "South Africa",
    "IATA code": "PTG"
  },
  {
    "City/Airport": "Pilanesberg/Sun City",
    "Country": "South Africa",
    "IATA code": "NTY"
  },
  {
    "City/Airport": "Pisa",
    "Country": "Italy",
    "IATA code": "PSA"
  },
  {
    "City/Airport": "Pittsburgh International Airport (PA)",
    "Country": "USA",
    "IATA code": "PIT"
  },
  {
    "City/Airport": "Plattsburgh (NY)",
    "Country": "USA",
    "IATA code": "PLB"
  },
  {
    "City/Airport": "Plettenberg Bay",
    "Country": "South Africa",
    "IATA code": "PBZ"
  },
  {
    "City/Airport": "Pocatello (ID)",
    "Country": "USA",
    "IATA code": "PIH"
  },
  {
    "City/Airport": "Podgorica",
    "Country": "Montenegro",
    "IATA code": "TGD"
  },
  {
    "City/Airport": "Pohnpei",
    "Country": "Micronesia",
    "IATA code": "PNI"
  },
  {
    "City/Airport": "Pointe a Pitre",
    "Country": "Guadeloupe",
    "IATA code": "PTP"
  },
  {
    "City/Airport": "Pointe Noire",
    "Country": "Congo (ROC)",
    "IATA code": "PNR"
  },
  {
    "City/Airport": "Poitiers",
    "Country": "France",
    "IATA code": "PIS"
  },
  {
    "City/Airport": "Ponce",
    "Country": "Puerto Rico",
    "IATA code": "PSE"
  },
  {
    "City/Airport": "Ponta Delgada",
    "Country": "Portugal",
    "IATA code": "PDL"
  },
  {
    "City/Airport": "Pori",
    "Country": "Finland",
    "IATA code": "POR"
  },
  {
    "City/Airport": "Port Angeles (WA)",
    "Country": "USA",
    "IATA code": "CLM"
  },
  {
    "City/Airport": "Port au Prince",
    "Country": "Haiti",
    "IATA code": "PAP"
  },
  {
    "City/Airport": "Port Augusta",
    "Country": "Australia",
    "IATA code": "PUG"
  },
  {
    "City/Airport": "Port Elizabeth",
    "Country": "South Africa",
    "IATA code": "PLZ"
  },
  {
    "City/Airport": "Port Gentil",
    "Country": "Gabon",
    "IATA code": "POG"
  },
  {
    "City/Airport": "Port Harcourt",
    "Country": "Nigeria",
    "IATA code": "PHC"
  },
  {
    "City/Airport": "Port Hedland",
    "Country": "Australia",
    "IATA code": "PHE"
  },
  {
    "City/Airport": "Portland",
    "Country": "Australia",
    "IATA code": "PTJ"
  },
  {
    "City/Airport": "Portland (ME)",
    "Country": "USA",
    "IATA code": "PWM"
  },
  {
    "City/Airport": "Portland International (OR)",
    "Country": "USA",
    "IATA code": "PDX"
  },
  {
    "City/Airport": "Port Lincoln",
    "Country": "Australia",
    "IATA code": "PLO"
  },
  {
    "City/Airport": "Port Macquarie",
    "Country": "Australia",
    "IATA code": "PQQ"
  },
  {
    "City/Airport": "Port Menier, PQ",
    "Country": "Canada",
    "IATA code": "YPN"
  },
  {
    "City/Airport": "Port Moresby",
    "Country": "Papua New Guinea",
    "IATA code": "POM"
  },
  {
    "City/Airport": "Porto",
    "Country": "Portugal",
    "IATA code": "OPO"
  },
  {
    "City/Airport": "Porto Alegre",
    "Country": "Brazil",
    "IATA code": "POA"
  },
  {
    "City/Airport": "Port of Spain",
    "Country": "Trinidad and Tobago",
    "IATA code": "POS"
  },
  {
    "City/Airport": "Port Said",
    "Country": "Egypt",
    "IATA code": "PSD"
  },
  {
    "City/Airport": "Porto Santo",
    "Country": "Portugal",
    "IATA code": "PXO"
  },
  {
    "City/Airport": "Porto Velho",
    "Country": "Brazil",
    "IATA code": "PVH"
  },
  {
    "City/Airport": "Port Vila",
    "Country": "Vanuatu",
    "IATA code": "VLI"
  },
  {
    "City/Airport": "Poughkeepsie (NY)",
    "Country": "USA",
    "IATA code": "POU"
  },
  {
    "City/Airport": "Poznan, Lawica",
    "Country": "Poland",
    "IATA code": "POZ"
  },
  {
    "City/Airport": "Prague",
    "Country": "Czech Republic",
    "IATA code": "PRG"
  },
  {
    "City/Airport": "Praia",
    "Country": "Cape Verde",
    "IATA code": "RAI"
  },
  {
    "City/Airport": "Presque Island (ME)",
    "Country": "USA",
    "IATA code": "PQI"
  },
  {
    "City/Airport": "Pretoria",
    "Country": "South Africa",
    "IATA code": "PRY"
  },
  {
    "City/Airport": "Preveza/Lefkas",
    "Country": "Greece",
    "IATA code": "PVK"
  },
  {
    "City/Airport": "Prince George",
    "Country": "Canada",
    "IATA code": "YXS"
  },
  {
    "City/Airport": "Prince Rupert/Digby Island",
    "Country": "Canada",
    "IATA code": "YPR"
  },
  {
    "City/Airport": "Pristina",
    "Country": "Serbia",
    "IATA code": "PRN"
  },
  {
    "City/Airport": "Prosperpine",
    "Country": "Australia",
    "IATA code": "PPP"
  },
  {
    "City/Airport": "Providence (RI)",
    "Country": "USA",
    "IATA code": "PVD"
  },
  {
    "City/Airport": "Prudhoe Bay (AK)",
    "Country": "USA",
    "IATA code": "SCC"
  },
  {
    "City/Airport": "Puebla",
    "Country": "Mexico",
    "IATA code": "PBC"
  },
  {
    "City/Airport": "Pueblo (CO)",
    "Country": "USA",
    "IATA code": "PUB"
  },
  {
    "City/Airport": "Puerto Escondido",
    "Country": "Mexico",
    "IATA code": "PXM"
  },
  {
    "City/Airport": "Puerto Ordaz",
    "Country": "Venezuela",
    "IATA code": "PZO"
  },
  {
    "City/Airport": "Puerto Plata",
    "Country": "Dominican Republic",
    "IATA code": "POP"
  },
  {
    "City/Airport": "Puerto Vallarta",
    "Country": "Mexico",
    "IATA code": "PVR"
  },
  {
    "City/Airport": "Pukatawagan",
    "Country": "Canada",
    "IATA code": "XPK"
  },
  {
    "City/Airport": "Pula",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "PUY"
  },
  {
    "City/Airport": "Pullman (WA)",
    "Country": "USA",
    "IATA code": "PUW"
  },
  {
    "City/Airport": "Pune",
    "Country": "India, Maharashtra",
    "IATA code": "PNQ"
  },
  {
    "City/Airport": "Punta Arenas",
    "Country": "Chile",
    "IATA code": "PUQ"
  },
  {
    "City/Airport": "Punta Cana",
    "Country": "Dominican Republic",
    "IATA code": "PUJ"
  },
  {
    "City/Airport": "Pu San (Busan)",
    "Country": "South Korea",
    "IATA code": "PUS"
  },
  {
    "City/Airport": "Pyongyang",
    "Country": "North Korea",
    "IATA code": "FNJ"
  },
  {
    "City/Airport": "Quebec",
    "Country": "Canada",
    "IATA code": "YQB"
  },
  {
    "City/Airport": "Queenstown",
    "Country": "Australia",
    "IATA code": "UEE"
  },
  {
    "City/Airport": "Queenstown",
    "Country": "New Zealand",
    "IATA code": "ZQN"
  },
  {
    "City/Airport": "Quetta",
    "Country": "Pakistan",
    "IATA code": "UET"
  },
  {
    "City/Airport": "Qingdao",
    "Country": "Shandong, PR China",
    "IATA code": "TAO"
  },
  {
    "City/Airport": "Quimper",
    "Country": "France",
    "IATA code": "UIP"
  },
  {
    "City/Airport": "Quincy (IL)",
    "Country": "USA",
    "IATA code": "UIN"
  },
  {
    "City/Airport": "Quito",
    "Country": "Ecuador",
    "IATA code": "UIO"
  },
  {
    "City/Airport": "Rabat",
    "Country": "Morocco",
    "IATA code": "RBA"
  },
  {
    "City/Airport": "Rahim Yar Khan",
    "Country": "Pakistan",
    "IATA code": "RYK"
  },
  {
    "City/Airport": "Raiatea",
    "Country": "French Polynesia",
    "IATA code": "RFP"
  },
  {
    "City/Airport": "Rainbow Lake, AB",
    "Country": "Canada",
    "IATA code": "YOP"
  },
  {
    "City/Airport": "Rajkot",
    "Country": "India",
    "IATA code": "RAJ"
  },
  {
    "City/Airport": "Raleigh/Durham (NC)",
    "Country": "USA",
    "IATA code": "RDU"
  },
  {
    "City/Airport": "Ranchi",
    "Country": "India",
    "IATA code": "IXR"
  },
  {
    "City/Airport": "Rangiroa",
    "Country": "French Polynesia",
    "IATA code": "RGI"
  },
  {
    "City/Airport": "Rangoon (Yangon)",
    "Country": "Myanmar",
    "IATA code": "RGN"
  },
  {
    "City/Airport": "Rapid City (SD)",
    "Country": "USA",
    "IATA code": "RAP"
  },
  {
    "City/Airport": "Rarotonga",
    "Country": "Cook Island",
    "IATA code": "RAR"
  },
  {
    "City/Airport": "Ras al Khaymah (Ras al Khaimah)",
    "Country": "United Arab Emirates",
    "IATA code": "RKT"
  },
  {
    "City/Airport": "Rawala Kot",
    "Country": "Pakistan",
    "IATA code": "RAZ"
  },
  {
    "City/Airport": "Rawalpindi",
    "Country": "Pakistan",
    "IATA code": "RWP"
  },
  {
    "City/Airport": "Reading (PA)",
    "Country": "USA",
    "IATA code": "RDG"
  },
  {
    "City/Airport": "Recife",
    "Country": "Brazil",
    "IATA code": "REC"
  },
  {
    "City/Airport": "Redding (CA)",
    "Country": "USA",
    "IATA code": "RDD"
  },
  {
    "City/Airport": "Redmond (OR)",
    "Country": "USA",
    "IATA code": "RDM"
  },
  {
    "City/Airport": "Reggio Calabria",
    "Country": "Italy",
    "IATA code": "REG"
  },
  {
    "City/Airport": "Regina",
    "Country": "Canada",
    "IATA code": "YQR"
  },
  {
    "City/Airport": "Reina Sofia",
    "Country": "Teneriffa/Spain",
    "IATA code": "TFS"
  },
  {
    "City/Airport": "Rennes",
    "Country": "France",
    "IATA code": "RNS"
  },
  {
    "City/Airport": "Reno (NV)",
    "Country": "USA",
    "IATA code": "RNO"
  },
  {
    "City/Airport": "Resolute Bay",
    "Country": "Canada",
    "IATA code": "YRB"
  },
  {
    "City/Airport": "Reus",
    "Country": "Spain",
    "IATA code": "REU"
  },
  {
    "City/Airport": "Reykjavik",
    "Country": "Iceland",
    "IATA code": "REK"
  },
  {
    "City/Airport": "Reykjavik",
    "Country": "Iceland",
    "IATA code": "KEF"
  },
  {
    "City/Airport": "Rhinelander (WI)",
    "Country": "USA",
    "IATA code": "RHI"
  },
  {
    "City/Airport": "Rhodos",
    "Country": "Greece",
    "IATA code": "RHO"
  },
  {
    "City/Airport": "Richards Bay",
    "Country": "South Africa",
    "IATA code": "RCB"
  },
  {
    "City/Airport": "Richmond (VA)",
    "Country": "USA",
    "IATA code": "RIC"
  },
  {
    "City/Airport": "Riga",
    "Country": "Latvia",
    "IATA code": "RIX"
  },
  {
    "City/Airport": "Rijeka",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "RJK"
  },
  {
    "City/Airport": "Rimini",
    "Country": "Italy",
    "IATA code": "RMI"
  },
  {
    "City/Airport": "Rio Branco",
    "Country": "Brazil",
    "IATA code": "RBR"
  },
  {
    "City/Airport": "Rio de Janeiro",
    "Country": "Brazil",
    "IATA code": "GIG"
  },
  {
    "City/Airport": "Rio de Janeiro",
    "Country": "Brazil",
    "IATA code": "SDU"
  },
  {
    "City/Airport": "Rio de Janeiro",
    "Country": "Brazil",
    "IATA code": "RIO"
  },
  {
    "City/Airport": "Riyadh",
    "Country": "Saudi Arabia",
    "IATA code": "RUH"
  },
  {
    "City/Airport": "Roanne",
    "Country": "France",
    "IATA code": "RNE"
  },
  {
    "City/Airport": "Roanoke (VA)",
    "Country": "USA",
    "IATA code": "ROA"
  },
  {
    "City/Airport": "Roatan",
    "Country": "Honduras",
    "IATA code": "RTB"
  },
  {
    "City/Airport": "Rochester (MN)",
    "Country": "USA",
    "IATA code": "RST"
  },
  {
    "City/Airport": "Rochester (NY)",
    "Country": "USA",
    "IATA code": "ROC"
  },
  {
    "City/Airport": "Rock Sound",
    "Country": "Bahamas",
    "IATA code": "RSD"
  },
  {
    "City/Airport": "Rock Springs (WY)",
    "Country": "USA",
    "IATA code": "RKS"
  },
  {
    "City/Airport": "Rockford (IL)",
    "Country": "USA",
    "IATA code": "RFD"
  },
  {
    "City/Airport": "Rockhampton",
    "Country": "Australia",
    "IATA code": "ROK"
  },
  {
    "City/Airport": "Rockland (ME)",
    "Country": "USA",
    "IATA code": "RKD"
  },
  {
    "City/Airport": "Rocky Mount",
    "Country": "USA",
    "IATA code": "RWI"
  },
  {
    "City/Airport": "Rodez",
    "Country": "France",
    "IATA code": "RDZ"
  },
  {
    "City/Airport": "Rodrigues Island",
    "Country": "Mauritius",
    "IATA code": "RRG"
  },
  {
    "City/Airport": "Roenne",
    "Country": "Denmark",
    "IATA code": "RNN"
  },
  {
    "City/Airport": "Rome",
    "Country": "Italy",
    "IATA code": "ROM"
  },
  {
    "City/Airport": "Rome",
    "Country": "Italy",
    "IATA code": "CIA"
  },
  {
    "City/Airport": "Rome",
    "Country": "Italy",
    "IATA code": "FCO"
  },
  {
    "City/Airport": "Ronneby",
    "Country": "Sweden",
    "IATA code": "RNB"
  },
  {
    "City/Airport": "Rosario",
    "Country": "Argentina",
    "IATA code": "ROS"
  },
  {
    "City/Airport": "Rosto",
    "Country": "Russia",
    "IATA code": "RVI"
  },
  {
    "City/Airport": "Rosto",
    "Country": "Russia",
    "IATA code": "ROV"
  },
  {
    "City/Airport": "Rotorua",
    "Country": "New Zealand",
    "IATA code": "ROT"
  },
  {
    "City/Airport": "Rotterdam",
    "Country": "Netherlands",
    "IATA code": "RTM"
  },
  {
    "City/Airport": "Rovaniemi",
    "Country": "Finland",
    "IATA code": "RVN"
  },
  {
    "City/Airport": "Rundu",
    "Country": "Namibia",
    "IATA code": "NDU"
  },
  {
    "City/Airport": "Ruse",
    "Country": "Bulgaria",
    "IATA code": "ROU"
  },
  {
    "City/Airport": "Saarbruecken",
    "Country": "Germany",
    "IATA code": "SCN"
  },
  {
    "City/Airport": "Sacramento (CA)",
    "Country": "USA",
    "IATA code": "SMF"
  },
  {
    "City/Airport": "Sado Shima",
    "Country": "Japan",
    "IATA code": "SDS"
  },
  {
    "City/Airport": "Saginaw/Bay City/Midland (MI)",
    "Country": "USA",
    "IATA code": "MBS"
  },
  {
    "City/Airport": "Saidu Sharif",
    "Country": "Pakistan",
    "IATA code": "SDT"
  },
  {
    "City/Airport": "Saigon (Ho Chi Minh City)",
    "Country": "Viet Nam",
    "IATA code": "SGN"
  },
  {
    "City/Airport": "Saint Brieuc",
    "Country": "France",
    "IATA code": "SBK"
  },
  {
    "City/Airport": "Saint Denis",
    "Country": "Reunion",
    "IATA code": "RUN"
  },
  {
    "City/Airport": "Saint John",
    "Country": "Canada",
    "IATA code": "YSJ"
  },
  {
    "City/Airport": "Saipan",
    "Country": "Northern Mariana Islands",
    "IATA code": "SPN"
  },
  {
    "City/Airport": "Sal",
    "Country": "Cape Verde",
    "IATA code": "SID"
  },
  {
    "City/Airport": "Salalah",
    "Country": "Oman",
    "IATA code": "SLL"
  },
  {
    "City/Airport": "Salem (OR)",
    "Country": "USA",
    "IATA code": "SLE"
  },
  {
    "City/Airport": "Salinas (CA)",
    "Country": "USA",
    "IATA code": "SNS"
  },
  {
    "City/Airport": "Salinas",
    "Country": "Ecuador",
    "IATA code": "SNC"
  },
  {
    "City/Airport": "Salisbury",
    "Country": "Zimbabwe",
    "IATA code": "SAY"
  },
  {
    "City/Airport": "Salisbury (MD)",
    "Country": "USA",
    "IATA code": "SBY"
  },
  {
    "City/Airport": "Saloniki",
    "Country": "Greece",
    "IATA code": "SKG"
  },
  {
    "City/Airport": "Salta, Gen Belgrano",
    "Country": "Argentina",
    "IATA code": "SLA"
  },
  {
    "City/Airport": "Salt Lake City (UT)",
    "Country": "USA",
    "IATA code": "SLC"
  },
  {
    "City/Airport": "Salvador",
    "Country": "Brazil",
    "IATA code": "SSA"
  },
  {
    "City/Airport": "Salzburg",
    "Country": "Austria",
    "IATA code": "SZG"
  },
  {
    "City/Airport": "Samara",
    "Country": "Russia",
    "IATA code": "KUF"
  },
  {
    "City/Airport": "Samarkand",
    "Country": "Uzbekistan",
    "IATA code": "SKD"
  },
  {
    "City/Airport": "Samos",
    "Country": "Greece",
    "IATA code": "SMI"
  },
  {
    "City/Airport": "Samsun",
    "Country": "Turkey",
    "IATA code": "SZF"
  },
  {
    "City/Airport": "San Andres",
    "Country": "Colombia",
    "IATA code": "ADZ"
  },
  {
    "City/Airport": "San Angelo (TX)",
    "Country": "USA",
    "IATA code": "SJT"
  },
  {
    "City/Airport": "San Antonio (TX)",
    "Country": "USA",
    "IATA code": "SAT"
  },
  {
    "City/Airport": "San Carlos de Bariloche",
    "Country": "Argentina",
    "IATA code": "BRC"
  },
  {
    "City/Airport": "San Diego",
    "Country": "USA",
    "IATA code": "SAN"
  },
  {
    "City/Airport": "San Francisco",
    "Country": "USA",
    "IATA code": "SFO"
  },
  {
    "City/Airport": "San Jose Cabo",
    "Country": "Mexico",
    "IATA code": "SJD"
  },
  {
    "City/Airport": "San Jose",
    "Country": "Costa Rica",
    "IATA code": "SJO"
  },
  {
    "City/Airport": "San Jose (CA)",
    "Country": "USA",
    "IATA code": "SJC"
  },
  {
    "City/Airport": "San Juan",
    "Country": "Puerto Rico",
    "IATA code": "SJU"
  },
  {
    "City/Airport": "San Luis Obisco (CA)",
    "Country": "USA",
    "IATA code": "SBP"
  },
  {
    "City/Airport": "San Luis Potosi",
    "Country": "Mexico",
    "IATA code": "SLP"
  },
  {
    "City/Airport": "San Pedro",
    "Country": "Cote d'Ivoire",
    "IATA code": "SPY"
  },
  {
    "City/Airport": "San Pedro Sula",
    "Country": "Honduras",
    "IATA code": "SAP"
  },
  {
    "City/Airport": "San Salvador",
    "Country": "Bahamas",
    "IATA code": "ZSA"
  },
  {
    "City/Airport": "San Salvador",
    "Country": "El Salvador",
    "IATA code": "SAL"
  },
  {
    "City/Airport": "San Sebastian",
    "Country": "Spain",
    "IATA code": "EAS"
  },
  {
    "City/Airport": "Sanaa (Sana'a)",
    "Country": "Yemen",
    "IATA code": "SAH"
  },
  {
    "City/Airport": "Sandspit",
    "Country": "Canada",
    "IATA code": "YZP"
  },
  {
    "City/Airport": "Santa Ana",
    "Country": "USA",
    "IATA code": "SNA"
  },
  {
    "City/Airport": "Santa Barbara (CA)",
    "Country": "USA",
    "IATA code": "SBA"
  },
  {
    "City/Airport": "Santa Cruz de la Palma",
    "Country": "Spain",
    "IATA code": "SPC"
  },
  {
    "City/Airport": "Santa Cruz de la Sierra",
    "Country": "Bolivia",
    "IATA code": "SRZ"
  },
  {
    "City/Airport": "Santa Katarina",
    "Country": "Egypt",
    "IATA code": "SKV"
  },
  {
    "City/Airport": "Santa Maria",
    "Country": "Portugal",
    "IATA code": "SMA"
  },
  {
    "City/Airport": "Santa Maria (CA)",
    "Country": "USA",
    "IATA code": "SMX"
  },
  {
    "City/Airport": "Santander",
    "Country": "Spain",
    "IATA code": "SDR"
  },
  {
    "City/Airport": "Santa Rosa (CA)",
    "Country": "USA",
    "IATA code": "STS"
  },
  {
    "City/Airport": "Santa Rosa",
    "Country": "Bolivia",
    "IATA code": "SRB"
  },
  {
    "City/Airport": "Santa Rosa",
    "Country": "Brazil",
    "IATA code": "SRA"
  },
  {
    "City/Airport": "Santa Rosa",
    "Country": "Argentina",
    "IATA code": "RSA"
  },
  {
    "City/Airport": "Santa Rosa, Copan",
    "Country": "Honduras",
    "IATA code": "SDH"
  },
  {
    "City/Airport": "Santa Rosalia",
    "Country": "Colombia",
    "IATA code": "SSL"
  },
  {
    "City/Airport": "Santa Rosalia",
    "Country": "Mexico",
    "IATA code": "SRL"
  },
  {
    "City/Airport": "Santiago",
    "Country": "Cuba",
    "IATA code": "SCU"
  },
  {
    "City/Airport": "Santiago de Chile",
    "Country": "Chile",
    "IATA code": "SCL"
  },
  {
    "City/Airport": "Santiago de Compostela",
    "Country": "Spain",
    "IATA code": "SCQ"
  },
  {
    "City/Airport": "Santo",
    "Country": "Vanuatu",
    "IATA code": "SON"
  },
  {
    "City/Airport": "Santo Domingo",
    "Country": "Dominican Republic",
    "IATA code": "SDQ"
  },
  {
    "City/Airport": "Sao Luis",
    "Country": "Brazil",
    "IATA code": "SLZ"
  },
  {
    "City/Airport": "Sao Paulo",
    "Country": "Brazil",
    "IATA code": "SAO"
  },
  {
    "City/Airport": "Sao Paulo",
    "Country": "Brazil",
    "IATA code": "CGH"
  },
  {
    "City/Airport": "Sao Paulo",
    "Country": "Brazil",
    "IATA code": "GRU"
  },
  {
    "City/Airport": "Sao Paulo",
    "Country": "Brazil",
    "IATA code": "VCP"
  },
  {
    "City/Airport": "Sao Tome",
    "Country": "Sao Tome & Principe",
    "IATA code": "TMS"
  },
  {
    "City/Airport": "Sapporo",
    "Country": "Hokkaido, Japan",
    "IATA code": "SPK"
  },
  {
    "City/Airport": "Sapporo",
    "Country": "Hokkaido, Japan",
    "IATA code": "OKD"
  },
  {
    "City/Airport": "Sapporo",
    "Country": "Japan",
    "IATA code": "CTS"
  },
  {
    "City/Airport": "Sarajevo",
    "Country": "Bosnia and Herzegovina",
    "IATA code": "SJJ"
  },
  {
    "City/Airport": "Saransk",
    "Country": "Russia",
    "IATA code": "SKX"
  },
  {
    "City/Airport": "Sarasota/Bradenton (FL)",
    "Country": "USA",
    "IATA code": "SRQ"
  },
  {
    "City/Airport": "Saskatoon",
    "Country": "Canada",
    "IATA code": "YXE"
  },
  {
    "City/Airport": "Sassandra",
    "Country": "Cote d'Ivoire",
    "IATA code": "ZSS"
  },
  {
    "City/Airport": "Savannah (GA)",
    "Country": "USA",
    "IATA code": "SAV"
  },
  {
    "City/Airport": "Savonlinna",
    "Country": "Finland",
    "IATA code": "SVL"
  },
  {
    "City/Airport": "Scarborough",
    "Country": "Trinidad and Tobago",
    "IATA code": "TAB"
  },
  {
    "City/Airport": "Scone",
    "Country": "Australia",
    "IATA code": "NSO"
  },
  {
    "City/Airport": "Scottsdale (AZ)",
    "Country": "USA",
    "IATA code": "SCF"
  },
  {
    "City/Airport": "Seattle/Tacoma (WA)",
    "Country": "USA",
    "IATA code": "SEA"
  },
  {
    "City/Airport": "Sehba",
    "Country": "Libya",
    "IATA code": "SEB"
  },
  {
    "City/Airport": "Seinaejoki",
    "Country": "Finland",
    "IATA code": "SJY"
  },
  {
    "City/Airport": "Selibi Phikwe",
    "Country": "Botswana",
    "IATA code": "PKW"
  },
  {
    "City/Airport": "Sendai",
    "Country": "Japan",
    "IATA code": "SDJ"
  },
  {
    "City/Airport": "Seoul",
    "Country": "South Korea",
    "IATA code": "ICN"
  },
  {
    "City/Airport": "Seoul",
    "Country": "South Korea",
    "IATA code": "SEL"
  },
  {
    "City/Airport": "Sevilla",
    "Country": "Spain",
    "IATA code": "SVQ"
  },
  {
    "City/Airport": "Sfax",
    "Country": "Tunisia",
    "IATA code": "SFA"
  },
  {
    "City/Airport": "Shamattawa, MB",
    "Country": "Canada",
    "IATA code": "ZTM"
  },
  {
    "City/Airport": "Shanghai",
    "Country": "China",
    "IATA code": "SHA"
  },
  {
    "City/Airport": "Shanghai",
    "Country": "China",
    "IATA code": "PVG"
  },
  {
    "City/Airport": "Shannon (Limerick)",
    "Country": "Ireland",
    "IATA code": "SNN"
  },
  {
    "City/Airport": "Sharjah",
    "Country": "United Arab Emirates",
    "IATA code": "SHJ"
  },
  {
    "City/Airport": "Sharm El Sheikh",
    "Country": "Egypt",
    "IATA code": "SSH"
  },
  {
    "City/Airport": "Sheffield, City Airport",
    "Country": "United Kingdom",
    "IATA code": "SZD"
  },
  {
    "City/Airport": "Sheffield, Doncaster, Robin Hood International Airport",
    "Country": "United Kingdom",
    "IATA code": "DSA"
  },
  {
    "City/Airport": "Shenandoah Valley/Stauton (VA)",
    "Country": "USA",
    "IATA code": "SHD"
  },
  {
    "City/Airport": "Shenyang",
    "Country": "Liaoning, PR China",
    "IATA code": "SHE"
  },
  {
    "City/Airport": "Shenzhen",
    "Country": "Guangdong, PR China",
    "IATA code": "SZX"
  },
  {
    "City/Airport": "Sheridan (WY)",
    "Country": "USA",
    "IATA code": "SHR"
  },
  {
    "City/Airport": "Shreveport, La",
    "Country": "USA",
    "IATA code": "SHV"
  },
  {
    "City/Airport": "Shute Harbour",
    "Country": "Australia",
    "IATA code": "JHQ"
  },
  {
    "City/Airport": "Sibu",
    "Country": "Malaysia",
    "IATA code": "SBW"
  },
  {
    "City/Airport": "Sidney (MT)",
    "Country": "USA",
    "IATA code": "SDY"
  },
  {
    "City/Airport": "Silistra",
    "Country": "Bulgaria",
    "IATA code": "SLS"
  },
  {
    "City/Airport": "Simferopol",
    "Country": "Ukraine",
    "IATA code": "SIP"
  },
  {
    "City/Airport": "Sindhri",
    "Country": "Pakistan",
    "IATA code": "MPD"
  },
  {
    "City/Airport": "Singapore",
    "Country": "Singapore",
    "IATA code": "SIN"
  },
  {
    "City/Airport": "Singapore",
    "Country": "Singapore",
    "IATA code": "QPG"
  },
  {
    "City/Airport": "Singapore",
    "Country": "Singapore",
    "IATA code": "XSP"
  },
  {
    "City/Airport": "Singleton",
    "Country": "Australia",
    "IATA code": "SIX"
  },
  {
    "City/Airport": "Sioux City IA",
    "Country": "USA",
    "IATA code": "SUX"
  },
  {
    "City/Airport": "Sioux Falls (SD)",
    "Country": "USA",
    "IATA code": "FSD"
  },
  {
    "City/Airport": "Sishen",
    "Country": "South Africa",
    "IATA code": "SIS"
  },
  {
    "City/Airport": "Sitka (AK)",
    "Country": "USA",
    "IATA code": "SIT"
  },
  {
    "City/Airport": "Sivas",
    "Country": "Turkey",
    "IATA code": "VAS"
  },
  {
    "City/Airport": "Siwa",
    "Country": "Egypt",
    "IATA code": "SEW"
  },
  {
    "City/Airport": "Skagway (AK)",
    "Country": "USA",
    "IATA code": "SGY"
  },
  {
    "City/Airport": "Skardu",
    "Country": "Pakistan",
    "IATA code": "KDU"
  },
  {
    "City/Airport": "Skiathos",
    "Country": "Greece",
    "IATA code": "JSI"
  },
  {
    "City/Airport": "Skopje",
    "Country": "Macedonia",
    "IATA code": "SKP"
  },
  {
    "City/Airport": "Skrydstrup",
    "Country": "Denmark",
    "IATA code": "SKS"
  },
  {
    "City/Airport": "Skukuza",
    "Country": "South Africa",
    "IATA code": "SZK"
  },
  {
    "City/Airport": "Sligo",
    "Country": "Ireland",
    "IATA code": "SXL"
  },
  {
    "City/Airport": "Smithers",
    "Country": "Canada",
    "IATA code": "YYD"
  },
  {
    "City/Airport": "Sodankylae",
    "Country": "Finland",
    "IATA code": "SOT"
  },
  {
    "City/Airport": "Soenderborg",
    "Country": "Denmark",
    "IATA code": "SGD"
  },
  {
    "City/Airport": "Soendre Stroemfjord",
    "Country": "Greenland",
    "IATA code": "SFJ"
  },
  {
    "City/Airport": "Sofia",
    "Country": "Bulgaria",
    "IATA code": "SOF"
  },
  {
    "City/Airport": "Sogndal",
    "Country": "Norway",
    "IATA code": "SOG"
  },
  {
    "City/Airport": "Southampton",
    "Country": "United Kingdom",
    "IATA code": "SOU"
  },
  {
    "City/Airport": "South Bend (IN)",
    "Country": "USA",
    "IATA code": "SBN"
  },
  {
    "City/Airport": "South Indian Lake, MB",
    "Country": "Canada",
    "IATA code": "XSI"
  },
  {
    "City/Airport": "South Molle Island",
    "Country": "Australia",
    "IATA code": "SOI"
  },
  {
    "City/Airport": "Southend (London)",
    "Country": "United Kingdom",
    "IATA code": "SEN"
  },
  {
    "City/Airport": "Split",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "SPU"
  },
  {
    "City/Airport": "Spokane (WA)",
    "Country": "USA",
    "IATA code": "GEG"
  },
  {
    "City/Airport": "Springbok",
    "Country": "South Africa",
    "IATA code": "SBU"
  },
  {
    "City/Airport": "Springfield (IL)",
    "Country": "USA",
    "IATA code": "SPI"
  },
  {
    "City/Airport": "Springfield (MO)",
    "Country": "USA",
    "IATA code": "SGF"
  },
  {
    "City/Airport": "Srinagar",
    "Country": "India",
    "IATA code": "SXR"
  },
  {
    "City/Airport": "St. Augustin, PQ",
    "Country": "Canada",
    "IATA code": "YIF"
  },
  {
    "City/Airport": "St. Croix",
    "Country": "Virgin Islands (U.S.)",
    "IATA code": "STX"
  },
  {
    "City/Airport": "St. Etienne",
    "Country": "France",
    "IATA code": "EBU"
  },
  {
    "City/Airport": "St. George (UT)",
    "Country": "USA",
    "IATA code": "SGU"
  },
  {
    "City/Airport": "St. John's",
    "Country": "Canada",
    "IATA code": "YYT"
  },
  {
    "City/Airport": "St. Kitts",
    "Country": "St. Kitts and Nevis",
    "IATA code": "SKB"
  },
  {
    "City/Airport": "St. Louis (MO) Lambert–St. Louis International Airport",
    "Country": "USA",
    "IATA code": "STL"
  },
  {
    "City/Airport": "St. Lucia Hewanorra",
    "Country": "Saint Lucia",
    "IATA code": "UVF"
  },
  {
    "City/Airport": "St. Lucia Vigle",
    "Country": "Saint Lucia",
    "IATA code": "SLU"
  },
  {
    "City/Airport": "St. Marteen",
    "Country": "Netherlands Antilles",
    "IATA code": "SXM"
  },
  {
    "City/Airport": "St. Martin",
    "Country": "St. Martin (Guadeloupe)",
    "IATA code": "SFG"
  },
  {
    "City/Airport": "St. Petersburg (Leningrad)",
    "Country": "Russia",
    "IATA code": "LED"
  },
  {
    "City/Airport": "St. Pierre, NF",
    "Country": "Canada",
    "IATA code": "FSP"
  },
  {
    "City/Airport": "St. Thomas",
    "Country": "Virgin Islands (U.S.)",
    "IATA code": "STT"
  },
  {
    "City/Airport": "St. Vincent",
    "Country": "Saint Vincent and the Grenadines",
    "IATA code": "SVD"
  },
  {
    "City/Airport": "Stansted (London)",
    "Country": "United Kingdom",
    "IATA code": "STN"
  },
  {
    "City/Airport": "State College/Belefonte (PA)",
    "Country": "USA",
    "IATA code": "SCE"
  },
  {
    "City/Airport": "Stavanger",
    "Country": "Norway",
    "IATA code": "SVG"
  },
  {
    "City/Airport": "Steamboat Springs (CO)",
    "Country": "USA",
    "IATA code": "HDN"
  },
  {
    "City/Airport": "Stettin",
    "Country": "Poland",
    "IATA code": "SZZ"
  },
  {
    "City/Airport": "Stockholm Metropolitan Area",
    "Country": "Sweden",
    "IATA code": "STO"
  },
  {
    "City/Airport": "Stockholm",
    "Country": "Sweden",
    "IATA code": "ARN"
  },
  {
    "City/Airport": "Stockholm",
    "Country": "Sweden",
    "IATA code": "BMA"
  },
  {
    "City/Airport": "Stockton (CA)",
    "Country": "USA",
    "IATA code": "SCK"
  },
  {
    "City/Airport": "Stornway",
    "Country": "United Kingdom",
    "IATA code": "SYY"
  },
  {
    "City/Airport": "Strasbourg",
    "Country": "France",
    "IATA code": "SXB"
  },
  {
    "City/Airport": "Streaky Bay",
    "Country": "Australia",
    "IATA code": "KBY"
  },
  {
    "City/Airport": "Stuttgart",
    "Country": "Germany",
    "IATA code": "STR"
  },
  {
    "City/Airport": "Sui",
    "Country": "Pakistan",
    "IATA code": "SUL"
  },
  {
    "City/Airport": "Sukkur",
    "Country": "Pakistan",
    "IATA code": "SKZ"
  },
  {
    "City/Airport": "Sumburgh (Shetland)",
    "Country": "United Kingdom",
    "IATA code": "LSI"
  },
  {
    "City/Airport": "Sun Valley (ID)",
    "Country": "USA",
    "IATA code": "SUN"
  },
  {
    "City/Airport": "Sundsvall",
    "Country": "Sweden",
    "IATA code": "SDL"
  },
  {
    "City/Airport": "Sunshine Coast",
    "Country": "Australia",
    "IATA code": "MCY"
  },
  {
    "City/Airport": "Surabaya",
    "Country": "Indonesia",
    "IATA code": "SUB"
  },
  {
    "City/Airport": "Surat",
    "Country": "India",
    "IATA code": "STV"
  },
  {
    "City/Airport": "Suva",
    "Country": "Fiji",
    "IATA code": "SUV"
  },
  {
    "City/Airport": "Swakopmund",
    "Country": "Namibia",
    "IATA code": "SWP"
  },
  {
    "City/Airport": "Sydney",
    "Country": "Australia",
    "IATA code": "SYD"
  },
  {
    "City/Airport": "Sylhet",
    "Country": "Bangladesh",
    "IATA code": "ZYL"
  },
  {
    "City/Airport": "Syracuse (NY)",
    "Country": "USA",
    "IATA code": "SYR"
  },
  {
    "City/Airport": "Tabuk",
    "Country": "Saudi Arabia",
    "IATA code": "TUU"
  },
  {
    "City/Airport": "Taif",
    "Country": "Saudi Arabia",
    "IATA code": "TIF"
  },
  {
    "City/Airport": "Taipei",
    "Country": "Taiwan",
    "IATA code": "TPE"
  },
  {
    "City/Airport": "Taipei",
    "Country": "Taiwan",
    "IATA code": "TAY"
  },
  {
    "City/Airport": "Taiyuan",
    "Country": "Shanxi, PR China",
    "IATA code": "TYN"
  },
  {
    "City/Airport": "Takamatsu",
    "Country": "Japan",
    "IATA code": "TAK"
  },
  {
    "City/Airport": "Talkeetna (AK)",
    "Country": "USA",
    "IATA code": "TKA"
  },
  {
    "City/Airport": "Tallahassee (FL)",
    "Country": "USA",
    "IATA code": "TLH"
  },
  {
    "City/Airport": "Tallinn",
    "Country": "Estonia",
    "IATA code": "QUF"
  },
  {
    "City/Airport": "Tallinn",
    "Country": "Estonia",
    "IATA code": "TLL"
  },
  {
    "City/Airport": "Tampa",
    "Country": "USA",
    "IATA code": "TPA"
  },
  {
    "City/Airport": "Tampere",
    "Country": "Finland",
    "IATA code": "TMP"
  },
  {
    "City/Airport": "Tampico",
    "Country": "Mexico",
    "IATA code": "TAM"
  },
  {
    "City/Airport": "Tamworth",
    "Country": "Australia",
    "IATA code": "TMW"
  },
  {
    "City/Airport": "Tangier",
    "Country": "Morocco",
    "IATA code": "TNG"
  },
  {
    "City/Airport": "Taree",
    "Country": "Australia",
    "IATA code": "TRO"
  },
  {
    "City/Airport": "Targovishte",
    "Country": "Bulgaria",
    "IATA code": "TGV"
  },
  {
    "City/Airport": "Tashkent",
    "Country": "Uzbekistan",
    "IATA code": "TAS"
  },
  {
    "City/Airport": "Tawau",
    "Country": "Malaysia",
    "IATA code": "TWU"
  },
  {
    "City/Airport": "Tbilisi",
    "Country": "Georgia",
    "IATA code": "TBS"
  },
  {
    "City/Airport": "Te Anau",
    "Country": "New Zealand",
    "IATA code": "TEU"
  },
  {
    "City/Airport": "Teesside, Durham Tees Valley",
    "Country": "United Kingdom",
    "IATA code": "MME"
  },
  {
    "City/Airport": "Tegucigalpa",
    "Country": "Honduras",
    "IATA code": "TGU"
  },
  {
    "City/Airport": "Tehran (Teheran)",
    "Country": "Iran",
    "IATA code": "THR"
  },
  {
    "City/Airport": "Tekirdag",
    "Country": "Turkey",
    "IATA code": "TEQ"
  },
  {
    "City/Airport": "Tel Aviv",
    "Country": "Israel",
    "IATA code": "TLV"
  },
  {
    "City/Airport": "Telluride (CO)",
    "Country": "USA",
    "IATA code": "TEX"
  },
  {
    "City/Airport": "Temora",
    "Country": "Australia",
    "IATA code": "TEM"
  },
  {
    "City/Airport": "Tenerife",
    "Country": "Spain",
    "IATA code": "TCI"
  },
  {
    "City/Airport": "Tenerife",
    "Country": "Spain",
    "IATA code": "TFS"
  },
  {
    "City/Airport": "Tenerife",
    "Country": "Spain",
    "IATA code": "TFN"
  },
  {
    "City/Airport": "Tennant Creek",
    "Country": "Australia",
    "IATA code": "TCA"
  },
  {
    "City/Airport": "Terceira",
    "Country": "Portugal",
    "IATA code": "TER"
  },
  {
    "City/Airport": "Teresina",
    "Country": "Brazil",
    "IATA code": "THE"
  },
  {
    "City/Airport": "Termez (Termes)",
    "Country": "Uzbekistan",
    "IATA code": "TMZ"
  },
  {
    "City/Airport": "Terrace",
    "Country": "Canada",
    "IATA code": "YXT"
  },
  {
    "City/Airport": "Terre Haute (IN)",
    "Country": "USA",
    "IATA code": "HUF"
  },
  {
    "City/Airport": "Texarkana (AR)",
    "Country": "USA",
    "IATA code": "TXK"
  },
  {
    "City/Airport": "Thaba'Nchu",
    "Country": "South Africa",
    "IATA code": "TCU"
  },
  {
    "City/Airport": "The Pas",
    "Country": "Canada",
    "IATA code": "YQD"
  },
  {
    "City/Airport": "Thessaloniki",
    "Country": "Greece",
    "IATA code": "SKG"
  },
  {
    "City/Airport": "Thief River Falls (MN)",
    "Country": "USA",
    "IATA code": "TVF"
  },
  {
    "City/Airport": "Thira",
    "Country": "Greece",
    "IATA code": "JTR"
  },
  {
    "City/Airport": "Thiruvananthapuram",
    "Country": "India",
    "IATA code": "TRV"
  },
  {
    "City/Airport": "Thisted",
    "Country": "Denmark",
    "IATA code": "TED"
  },
  {
    "City/Airport": "Thompson",
    "Country": "Canada",
    "IATA code": "YTH"
  },
  {
    "City/Airport": "Thorne Bay (AK)",
    "Country": "USA",
    "IATA code": "KTB"
  },
  {
    "City/Airport": "Thunder Bay",
    "Country": "Canada",
    "IATA code": "YQT"
  },
  {
    "City/Airport": "Thursday Island",
    "Country": "Australia",
    "IATA code": "TIS"
  },
  {
    "City/Airport": "Tianjin",
    "Country": "China",
    "IATA code": "TSN"
  },
  {
    "City/Airport": "Tijuana",
    "Country": "Mexico",
    "IATA code": "TIJ"
  },
  {
    "City/Airport": "Tioman",
    "Country": "Indonesia",
    "IATA code": "TOD"
  },
  {
    "City/Airport": "Tirana",
    "Country": "Albania",
    "IATA code": "TIA"
  },
  {
    "City/Airport": "Tiruchirapally",
    "Country": "India",
    "IATA code": "TRZ"
  },
  {
    "City/Airport": "Tivat",
    "Country": "Montenegro",
    "IATA code": "TIV"
  },
  {
    "City/Airport": "Tobago",
    "Country": "Trinidad and Tobago",
    "IATA code": "TAB"
  },
  {
    "City/Airport": "Tokushima",
    "Country": "Japan",
    "IATA code": "TKS"
  },
  {
    "City/Airport": "Tokyo",
    "Country": "Japan",
    "IATA code": "TYO"
  },
  {
    "City/Airport": "Tokyo",
    "Country": "Japan",
    "IATA code": "HND"
  },
  {
    "City/Airport": "Tokyo",
    "Country": "Japan",
    "IATA code": "NRT"
  },
  {
    "City/Airport": "Toledo (OH)",
    "Country": "USA",
    "IATA code": "TOL"
  },
  {
    "City/Airport": "Tom Price",
    "Country": "Australia",
    "IATA code": "TPR"
  },
  {
    "City/Airport": "Toowoomba",
    "Country": "Australia",
    "IATA code": "TWB"
  },
  {
    "City/Airport": "Toronto",
    "Country": "Canada",
    "IATA code": "YTZ"
  },
  {
    "City/Airport": "Toronto",
    "Country": "Canada",
    "IATA code": "YYZ"
  },
  {
    "City/Airport": "Toronto",
    "Country": "Canada",
    "IATA code": "YTO"
  },
  {
    "City/Airport": "Tortola",
    "Country": "British Virgin Islands",
    "IATA code": "TOV"
  },
  {
    "City/Airport": "Touho",
    "Country": "New Caledonia",
    "IATA code": "TOU"
  },
  {
    "City/Airport": "Toulouse",
    "Country": "France",
    "IATA code": "TLS"
  },
  {
    "City/Airport": "Townsville",
    "Country": "Australia",
    "IATA code": "TSV"
  },
  {
    "City/Airport": "Toyama",
    "Country": "Japan",
    "IATA code": "TOY"
  },
  {
    "City/Airport": "Trabzon",
    "Country": "Turkey",
    "IATA code": "TZX"
  },
  {
    "City/Airport": "Trapani",
    "Country": "Italy",
    "IATA code": "TPS"
  },
  {
    "City/Airport": "Traverse City (MI)",
    "Country": "USA",
    "IATA code": "TVC"
  },
  {
    "City/Airport": "Treasure Cay",
    "Country": "Bahamas",
    "IATA code": "TCB"
  },
  {
    "City/Airport": "Trenton/Princeton (NJ)",
    "Country": "USA",
    "IATA code": "TTN"
  },
  {
    "City/Airport": "Treviso",
    "Country": "Italy",
    "IATA code": "TSF"
  },
  {
    "City/Airport": "Tr",
    "Country": "USA",
    "IATA code": "TRI"
  },
  {
    "City/Airport": "Trieste",
    "Country": "Italy",
    "IATA code": "TRS"
  },
  {
    "City/Airport": "Tripoli",
    "Country": "Libya",
    "IATA code": "TIP"
  },
  {
    "City/Airport": "Tromsoe",
    "Country": "Norway",
    "IATA code": "TOS"
  },
  {
    "City/Airport": "Trondheim",
    "Country": "Norway",
    "IATA code": "TRD"
  },
  {
    "City/Airport": "Tsumeb",
    "Country": "Namibia",
    "IATA code": "TSB"
  },
  {
    "City/Airport": "Tucson (AZ)",
    "Country": "USA",
    "IATA code": "TUS"
  },
  {
    "City/Airport": "Tulepo (MS)",
    "Country": "USA",
    "IATA code": "TUP"
  },
  {
    "City/Airport": "Tulsa (OK)",
    "Country": "USA",
    "IATA code": "TUL"
  },
  {
    "City/Airport": "Tunis",
    "Country": "Tunisia",
    "IATA code": "TUN"
  },
  {
    "City/Airport": "Turbat",
    "Country": "Pakistan",
    "IATA code": "TUK"
  },
  {
    "City/Airport": "Turin",
    "Country": "Italy",
    "IATA code": "TRN"
  },
  {
    "City/Airport": "Turku",
    "Country": "Finland",
    "IATA code": "TKU"
  },
  {
    "City/Airport": "Tuscaloosa (AL)",
    "Country": "USA",
    "IATA code": "TCL"
  },
  {
    "City/Airport": "Tuxtla Gutierrez",
    "Country": "Mexico",
    "IATA code": "TGZ"
  },
  {
    "City/Airport": "Twin Falls (ID)",
    "Country": "USA",
    "IATA code": "TWF"
  },
  {
    "City/Airport": "Tyler (TX)",
    "Country": "USA",
    "IATA code": "TYR"
  },
  {
    "City/Airport": "Ua Huka",
    "Country": "French Polynesia",
    "IATA code": "UAH"
  },
  {
    "City/Airport": "Ua Pou",
    "Country": "French Polynesia",
    "IATA code": "UAP"
  },
  {
    "City/Airport": "Ube",
    "Country": "Japan",
    "IATA code": "UBJ"
  },
  {
    "City/Airport": "Uberaba",
    "Country": "Brazil",
    "IATA code": "UBA"
  },
  {
    "City/Airport": "Uberlandia",
    "Country": "Brazil",
    "IATA code": "UDI"
  },
  {
    "City/Airport": "Ubon Ratchathani",
    "Country": "Thailand",
    "IATA code": "UBP"
  },
  {
    "City/Airport": "Udaipur",
    "Country": "India",
    "IATA code": "UDR"
  },
  {
    "City/Airport": "Uden",
    "Country": "Netherlands",
    "IATA code": "UDE"
  },
  {
    "City/Airport": "Udon Thani",
    "Country": "Thailand",
    "IATA code": "UTH"
  },
  {
    "City/Airport": "Ufa",
    "Country": "Russia",
    "IATA code": "UFA"
  },
  {
    "City/Airport": "Uherske Hradiste",
    "Country": "Czech Republic",
    "IATA code": "UHE"
  },
  {
    "City/Airport": "Uige",
    "Country": "Angola",
    "IATA code": "UGO"
  },
  {
    "City/Airport": "Ujung Pandang",
    "Country": "Indonesia",
    "IATA code": "UPG"
  },
  {
    "City/Airport": "Ukhta",
    "Country": "Russia",
    "IATA code": "UCT"
  },
  {
    "City/Airport": "Ukiah (CA)",
    "Country": "USA",
    "IATA code": "UKI"
  },
  {
    "City/Airport": "Ulaanbaatar",
    "Country": "Mongolia",
    "IATA code": "ULN"
  },
  {
    "City/Airport": "Ula",
    "Country": "Russia",
    "IATA code": "UUD"
  },
  {
    "City/Airport": "Ulanhot",
    "Country": "PR China",
    "IATA code": "HLH"
  },
  {
    "City/Airport": "Ulei",
    "Country": "Vanuatu",
    "IATA code": "ULB"
  },
  {
    "City/Airport": "Ulsan",
    "Country": "South Korea",
    "IATA code": "USN"
  },
  {
    "City/Airport": "Ulundi",
    "Country": "South Africa",
    "IATA code": "ULD"
  },
  {
    "City/Airport": "Umea",
    "Country": "Sweden",
    "IATA code": "UME"
  },
  {
    "City/Airport": "Umiujaq",
    "Country": "Canada",
    "IATA code": "YUD"
  },
  {
    "City/Airport": "Umtata",
    "Country": "South Africa",
    "IATA code": "UTT"
  },
  {
    "City/Airport": "Unalakleet (AK)",
    "Country": "USA",
    "IATA code": "UNK"
  },
  {
    "City/Airport": "Union Island",
    "Country": "Saint Vincent and the Grenadines",
    "IATA code": "UNI"
  },
  {
    "City/Airport": "Unst (Shetland Island)",
    "Country": "United Kingdom",
    "IATA code": "UNT"
  },
  {
    "City/Airport": "Upala",
    "Country": "Costa Rica",
    "IATA code": "UPL"
  },
  {
    "City/Airport": "Upernavik",
    "Country": "Greenland",
    "IATA code": "JUV"
  },
  {
    "City/Airport": "Upington",
    "Country": "South Africa",
    "IATA code": "UTN"
  },
  {
    "City/Airport": "Upolu Point (HI)",
    "Country": "USA",
    "IATA code": "UPP"
  },
  {
    "City/Airport": "Uranium City",
    "Country": "Canada",
    "IATA code": "YBE"
  },
  {
    "City/Airport": "Urgench",
    "Country": "Uzbekistan",
    "IATA code": "UGC"
  },
  {
    "City/Airport": "Uriman",
    "Country": "Venezuela",
    "IATA code": "URM"
  },
  {
    "City/Airport": "Urmiehm (Orumieh)",
    "Country": "Iran",
    "IATA code": "OMH"
  },
  {
    "City/Airport": "Uruapan",
    "Country": "Mexico",
    "IATA code": "UPN"
  },
  {
    "City/Airport": "Urubupunga",
    "Country": "Brazil",
    "IATA code": "URB"
  },
  {
    "City/Airport": "Uruguaiana",
    "Country": "Brazil",
    "IATA code": "URG"
  },
  {
    "City/Airport": "Urumqi",
    "Country": "Xinjiang, PR China",
    "IATA code": "URC"
  },
  {
    "City/Airport": "Uruzgan",
    "Country": "Afghanistan",
    "IATA code": "URZ"
  },
  {
    "City/Airport": "Ushuaia",
    "Country": "Argentina",
    "IATA code": "USH"
  },
  {
    "City/Airport": "Utapao (Pattaya)",
    "Country": "Thailand",
    "IATA code": "UTP"
  },
  {
    "City/Airport": "Utica (NY)",
    "Country": "USA",
    "IATA code": "UCA"
  },
  {
    "City/Airport": "Utila",
    "Country": "Honduras",
    "IATA code": "UII"
  },
  {
    "City/Airport": "Uummannaq",
    "Country": "Greenland",
    "IATA code": "UMD"
  },
  {
    "City/Airport": "Uzhgorod",
    "Country": "Ukraine",
    "IATA code": "UDJ"
  },
  {
    "City/Airport": "Vaasa",
    "Country": "Finland",
    "IATA code": "VAA"
  },
  {
    "City/Airport": "Vaexjoe",
    "Country": "Sweden",
    "IATA code": "VXO"
  },
  {
    "City/Airport": "Vail (CO)",
    "Country": "USA",
    "IATA code": "EGE"
  },
  {
    "City/Airport": "Val d'Or",
    "Country": "Canada",
    "IATA code": "YVO"
  },
  {
    "City/Airport": "Valdez (AK)",
    "Country": "USA",
    "IATA code": "VDZ"
  },
  {
    "City/Airport": "Valdosta (GA)",
    "Country": "USA",
    "IATA code": "VLD"
  },
  {
    "City/Airport": "Valencia",
    "Country": "Spain",
    "IATA code": "VLC"
  },
  {
    "City/Airport": "Valencia",
    "Country": "Venezuela",
    "IATA code": "VLN"
  },
  {
    "City/Airport": "Valladolid",
    "Country": "Spain",
    "IATA code": "VLL"
  },
  {
    "City/Airport": "Valparaiso",
    "Country": "Chile",
    "IATA code": "VAP"
  },
  {
    "City/Airport": "Valverde",
    "Country": "Spain",
    "IATA code": "VDE"
  },
  {
    "City/Airport": "Van",
    "Country": "Turkey",
    "IATA code": "VAN"
  },
  {
    "City/Airport": "Vancouver",
    "Country": "Canada",
    "IATA code": "YVR"
  },
  {
    "City/Airport": "Varadero",
    "Country": "Cuba",
    "IATA code": "VRA"
  },
  {
    "City/Airport": "Varanasi",
    "Country": "India",
    "IATA code": "VNS"
  },
  {
    "City/Airport": "Varkaus",
    "Country": "Finland",
    "IATA code": "VRK"
  },
  {
    "City/Airport": "Varna",
    "Country": "Bulgaria",
    "IATA code": "VAR"
  },
  {
    "City/Airport": "Vasteras",
    "Country": "Sweden",
    "IATA code": "VST"
  },
  {
    "City/Airport": "Velikiye Luki (Welikije Luki)",
    "Country": "Russia",
    "IATA code": "VLU"
  },
  {
    "City/Airport": "Venice",
    "Country": "Italy",
    "IATA code": "VCE"
  },
  {
    "City/Airport": "Veracruz",
    "Country": "Mexico",
    "IATA code": "VER"
  },
  {
    "City/Airport": "Vernal (UT)",
    "Country": "USA",
    "IATA code": "VEL"
  },
  {
    "City/Airport": "Vero Beach/Ft. Pierce (FL)",
    "Country": "USA",
    "IATA code": "VRB"
  },
  {
    "City/Airport": "Verona (Brescia) Montichiari",
    "Country": "Italy",
    "IATA code": "VBS"
  },
  {
    "City/Airport": "Verona",
    "Country": "Italy",
    "IATA code": "VRN"
  },
  {
    "City/Airport": "Victoria",
    "Country": "Canada",
    "IATA code": "YYJ"
  },
  {
    "City/Airport": "Victoria Falls",
    "Country": "Zimbabwe",
    "IATA code": "VFA"
  },
  {
    "City/Airport": "Vidin",
    "Country": "Bulgaria",
    "IATA code": "VID"
  },
  {
    "City/Airport": "Vientiane",
    "Country": "Lao PDR",
    "IATA code": "VTE"
  },
  {
    "City/Airport": "Vigo",
    "Country": "Spain",
    "IATA code": "VGO"
  },
  {
    "City/Airport": "Villahermosa",
    "Country": "Mexico",
    "IATA code": "VSA"
  },
  {
    "City/Airport": "Vilnius",
    "Country": "Lithuania",
    "IATA code": "VNO"
  },
  {
    "City/Airport": "Virgin Gorda",
    "Country": "Virgin Islands (British)",
    "IATA code": "VIJ"
  },
  {
    "City/Airport": "Visalia (CA)",
    "Country": "USA",
    "IATA code": "VIS"
  },
  {
    "City/Airport": "Visby",
    "Country": "Sweden",
    "IATA code": "VBY"
  },
  {
    "City/Airport": "Vitoria",
    "Country": "Spain",
    "IATA code": "VIT"
  },
  {
    "City/Airport": "Vitoria",
    "Country": "Brazil",
    "IATA code": "VIX"
  },
  {
    "City/Airport": "Vryheid",
    "Country": "South Africa",
    "IATA code": "VYD"
  },
  {
    "City/Airport": "Wabush",
    "Country": "Canada",
    "IATA code": "YWK"
  },
  {
    "City/Airport": "Waco (TX)",
    "Country": "USA",
    "IATA code": "ACT"
  },
  {
    "City/Airport": "Wagga",
    "Country": "Australia",
    "IATA code": "WGA"
  },
  {
    "City/Airport": "Walla Walla (WA)",
    "Country": "USA",
    "IATA code": "ALW"
  },
  {
    "City/Airport": "Wallis",
    "Country": "Wallis and Futuna Islands",
    "IATA code": "WLS"
  },
  {
    "City/Airport": "Walvis Bay",
    "Country": "South Africa",
    "IATA code": "WVB"
  },
  {
    "City/Airport": "Warrnambool",
    "Country": "Australia",
    "IATA code": "WMB"
  },
  {
    "City/Airport": "Warsaw",
    "Country": "Poland",
    "IATA code": "WAW"
  },
  {
    "City/Airport": "Washington DC",
    "Country": "USA",
    "IATA code": "BWI"
  },
  {
    "City/Airport": "Washington DC",
    "Country": "USA",
    "IATA code": "IAD"
  },
  {
    "City/Airport": "Washington DC",
    "Country": "USA",
    "IATA code": "DCA"
  },
  {
    "City/Airport": "Washington DC",
    "Country": "USA",
    "IATA code": "WAS"
  },
  {
    "City/Airport": "Waterloo IA",
    "Country": "USA",
    "IATA code": "ALO"
  },
  {
    "City/Airport": "Watertown (SD)",
    "Country": "USA",
    "IATA code": "ATY"
  },
  {
    "City/Airport": "Wausau/Stevens Point (WI)",
    "Country": "USA",
    "IATA code": "CWA"
  },
  {
    "City/Airport": "Weipa",
    "Country": "Australia",
    "IATA code": "WEI"
  },
  {
    "City/Airport": "Welkom",
    "Country": "South Africa",
    "IATA code": "WEL"
  },
  {
    "City/Airport": "Wellington",
    "Country": "New Zealand",
    "IATA code": "WLG"
  },
  {
    "City/Airport": "Wenatchee (WA)",
    "Country": "USA",
    "IATA code": "EAT"
  },
  {
    "City/Airport": "West Palm Beach (FL)",
    "Country": "USA",
    "IATA code": "PBI"
  },
  {
    "City/Airport": "West Yellowstone (MT)",
    "Country": "USA",
    "IATA code": "WYS"
  },
  {
    "City/Airport": "Westerland, Sylt Airport",
    "Country": "Germany",
    "IATA code": "GWT"
  },
  {
    "City/Airport": "Whakatane",
    "Country": "New Zealand",
    "IATA code": "WHK"
  },
  {
    "City/Airport": "Whale Cove, NT",
    "Country": "Canada",
    "IATA code": "YXN"
  },
  {
    "City/Airport": "Whangarei",
    "Country": "New Zealand",
    "IATA code": "WRE"
  },
  {
    "City/Airport": "White Plains (NY)",
    "Country": "USA",
    "IATA code": "HPN"
  },
  {
    "City/Airport": "Whitehorse",
    "Country": "Canada",
    "IATA code": "YXY"
  },
  {
    "City/Airport": "Whitsunday Resort",
    "Country": "Australia",
    "IATA code": "HAP"
  },
  {
    "City/Airport": "Whyalla",
    "Country": "Australia",
    "IATA code": "WYA"
  },
  {
    "City/Airport": "Wichita Falls (TX)",
    "Country": "USA",
    "IATA code": "SPS"
  },
  {
    "City/Airport": "Wichita (KS)",
    "Country": "USA",
    "IATA code": "ICT"
  },
  {
    "City/Airport": "Wick",
    "Country": "United Kingdom",
    "IATA code": "WIC"
  },
  {
    "City/Airport": "Wickham",
    "Country": "Australia",
    "IATA code": "WHM"
  },
  {
    "City/Airport": "Wien (Vienna)",
    "Country": "Austria",
    "IATA code": "VIE"
  },
  {
    "City/Airport": "Wiesbaden, Air Base",
    "Country": "Germany",
    "IATA code": "WIE"
  },
  {
    "City/Airport": "Wilkes Barre/Scranton (PA)",
    "Country": "USA",
    "IATA code": "AVP"
  },
  {
    "City/Airport": "Williamsport (PA)",
    "Country": "USA",
    "IATA code": "IPT"
  },
  {
    "City/Airport": "Williston (ND)",
    "Country": "USA",
    "IATA code": "ISL"
  },
  {
    "City/Airport": "Wilmington (NC)",
    "Country": "USA",
    "IATA code": "ILM"
  },
  {
    "City/Airport": "Wilna (Vilnius)",
    "Country": "Lithuania",
    "IATA code": "VNO"
  },
  {
    "City/Airport": "Wiluna",
    "Country": "Australia",
    "IATA code": "WUN"
  },
  {
    "City/Airport": "Windhoek",
    "Country": "Namibia",
    "IATA code": "ERS"
  },
  {
    "City/Airport": "Windhoek",
    "Country": "Namibia",
    "IATA code": "WDH"
  },
  {
    "City/Airport": "Windsor Ontario",
    "Country": "Canada",
    "IATA code": "YQG"
  },
  {
    "City/Airport": "Winnipeg International",
    "Country": "Canada",
    "IATA code": "YWG"
  },
  {
    "City/Airport": "Wolf Point (MT)",
    "Country": "USA",
    "IATA code": "OLF"
  },
  {
    "City/Airport": "Wollongong",
    "Country": "Australia",
    "IATA code": "WOL"
  },
  {
    "City/Airport": "Woomera",
    "Country": "Australia",
    "IATA code": "UMR"
  },
  {
    "City/Airport": "Worcester (MA)",
    "Country": "USA",
    "IATA code": "ORH"
  },
  {
    "City/Airport": "Worland (WY)",
    "Country": "USA",
    "IATA code": "WRL"
  },
  {
    "City/Airport": "Wrangell (AK)",
    "Country": "USA",
    "IATA code": "WRG"
  },
  {
    "City/Airport": "Wuhan",
    "Country": "Hubei, PR China",
    "IATA code": "WUH"
  },
  {
    "City/Airport": "Wyndham",
    "Country": "Australia",
    "IATA code": "WYN"
  },
  {
    "City/Airport": "Xiamen",
    "Country": "Fujian, PR China",
    "IATA code": "XMN"
  },
  {
    "City/Airport": "Xi'an",
    "Country": "Shaanxi, PR China",
    "IATA code": "XIY"
  },
  {
    "City/Airport": "Yakima (WA)",
    "Country": "USA",
    "IATA code": "YKM"
  },
  {
    "City/Airport": "Yakutat (AK)",
    "Country": "USA",
    "IATA code": "YAK"
  },
  {
    "City/Airport": "Yakutsk",
    "Country": "Russia",
    "IATA code": "YKS"
  },
  {
    "City/Airport": "Yamagata, Junmachi",
    "Country": "Japan",
    "IATA code": "GAJ"
  },
  {
    "City/Airport": "Yamoussoukro",
    "Country": "Côte d'Ivoire",
    "IATA code": "ASK"
  },
  {
    "City/Airport": "Yanbu",
    "Country": "Saudi Arabia",
    "IATA code": "YNB"
  },
  {
    "City/Airport": "Yangon (Rangoon)",
    "Country": "Myanmar",
    "IATA code": "RGN"
  },
  {
    "City/Airport": "Yaounde",
    "Country": "Cameroon",
    "IATA code": "YAO"
  },
  {
    "City/Airport": "Yellowknife",
    "Country": "Canada",
    "IATA code": "YZF"
  },
  {
    "City/Airport": "Yekaterinburg",
    "Country": "Russia",
    "IATA code": "SVX"
  },
  {
    "City/Airport": "Yichang",
    "Country": "Hubei, PR China",
    "IATA code": "YIH"
  },
  {
    "City/Airport": "Yokohama",
    "Country": "Japan",
    "IATA code": "YOK"
  },
  {
    "City/Airport": "Yuma (AZ)",
    "Country": "USA",
    "IATA code": "YUM"
  },
  {
    "City/Airport": "Zacatecas",
    "Country": "Mexico",
    "IATA code": "ZCL"
  },
  {
    "City/Airport": "Zadar",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "ZAD"
  },
  {
    "City/Airport": "Zagreb",
    "Country": "Croatia (Hrvatska)",
    "IATA code": "ZAG"
  },
  {
    "City/Airport": "Zakynthos",
    "Country": "Greece",
    "IATA code": "ZTH"
  },
  {
    "City/Airport": "Zaragoza",
    "Country": "Spain",
    "IATA code": "ZAZ"
  },
  {
    "City/Airport": "Zhob",
    "Country": "Pakistan",
    "IATA code": "PZH"
  },
  {
    "City/Airport": "Zinder",
    "Country": "Niger",
    "IATA code": "ZND"
  },
  {
    "City/Airport": "Zouerate",
    "Country": "Mauritania",
    "IATA code": "OUZ"
  },
  {
    "City/Airport": "Zurich (Zürich)",
    "Country": "Switzerland",
    "IATA code": "ZRH"
  }
]

export default city_data;