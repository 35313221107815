import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState,useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Snackbar } from '@mui/material';
import {Alert} from '@mui/material';
import {useSelector} from 'react-redux'

const PasswordDialog = ({ open, handleClose }) => {
  // const arrival = useSelector((state) => state.departure.arrival)

  const [password, setpassword] = useState("");
  const [password1, setpassword1] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertText,setalertText]=useState({
    title:"",
    state:"success"
  });
  const handleClick = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    setOpenSnackbar(false);
  };
  const handleChangePassword = () => {
    if (password === password1) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/update`, { password: password }).then(res => {
        //alert(res.data.message);
        setalertText({title:res.data.message,state:"success"});
        
      })
    } else {
    //  setalertText('Confirm Password Failed.');
      setalertText({title:'Confirm Password Failed.',state:"warning"});
    //  alert('Confirm Password Failed.')
    }
  };
  useEffect(()=>{
    handleClick();

  },[alertText])
  return (
    
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>


        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Password"
          type="password"
          fullWidth
          onChange={(e) => setpassword(e.target.value)}
          value={password}
        />

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Confirm Password"
          type="password"
          fullWidth
          onChange={(e) => setpassword1(e.target.value)}
          value={password1}
        />

      </DialogContent >
      <DialogActions>
        <Button onClick={handleChangePassword}>OK</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertText.state} sx={{ width: '100%' }}>
          {alertText.title}
        </Alert>
      </Snackbar>
    </Dialog >
  );
}

export default PasswordDialog;