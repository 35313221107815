import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setAirportData } from "../Redux/Actions/Airport";
import { AirportData } from "../data/other";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function FirstPage() {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const [open, setOpen] = useState(false);
  const [AirName, setAirName] = useState([]);
  // const [TerName, setTerName] = useState([]);
  const [WingName, setWingName] = useState([0, 0, 0]);

  const Airport = useSelector((state) => state.Airport.Airport)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick = (e) => {

    e.preventDefault()

    dispath(setAirportData({
      airport: AirportData[WingName[0]].AirName,
      terminal: AirportData[WingName[0]].terminal[WingName[1]].terName,
      wing: WingName[2] === 0 ? "arrival" : "departure",
      set: true
    }));

    if (WingName[2] === 0) {
      navigate("/arrival");
      handleClose();
    }
    else {
      navigate("/departure");
      handleClose();
    }
  }

  const TerminalFilter = (i) => {
    let newArray = []
    for (let index = 0; index < AirportData[i].terminal.length; index++) {
      const element = AirportData[i].terminal[index];
      newArray.push(element.terName)
    }
    // setTerName(newArray);
  }

  useEffect(() => {

    let newArray = []
    for (let index = 0; index < AirportData.length; index++) {
      const element = AirportData[index];
      newArray.push(element.AirName)
    }
    setAirName(newArray);
    TerminalFilter(0);
    if (Airport.airport === "" && Airport.terminal === "") {
      setWingName([0, 0, 0])
    }
    else {
    }
    
  }, [Airport])

  return (
    <div style={{ background: "#E8E8E8" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Card sx={{ minWidth: 275 }}>
          <CardContent style={{ height: 250 }}>
            <FormControl style={{ margin: "50px" }}>
              <Typography variant="h5">Airport</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                {
                  AirName.map((val, index) =>
                    <FormControlLabel
                      key={index}
                      value={index}
                      control={<Radio />}
                      label={val}
                      checked={WingName[0] === index}
                      onChange={() => {
                        TerminalFilter(index);
                        let j = [...WingName];
                        j[0] = index;
                        setWingName(j)
                      }
                      }
                    />)
                }
              </RadioGroup>
            </FormControl>
            <FormControl style={{ margin: "50px" }}>
              <Typography variant="h5">Wing</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="direction1"
                  control={<Radio />}
                  checked={WingName[2] === 0}
                  onChange={() => {
                    let j = [...WingName];
                    j[2] = 0;
                    setWingName(j)
                  }
                  }
                  label="arrival"
                />
                <FormControlLabel
                  value="direction2"
                  control={<Radio />}
                  checked={WingName[2] === 1}
                  onChange={() => {
                    let j = [...WingName];
                    j[2] = 1;
                    setWingName(j)
                  }
                  }
                  label="departure"
                />
              </RadioGroup>
            </FormControl>
          </CardContent>

          <CardActions>
            <Button
              sx={{ margin: "auto", textTransform: "capitalize" }}
              size="small"
              onClick={handleOpen}
            >
              <Typography variant="h5">Confirm</Typography>
            </Button>
          </CardActions>
        </Card>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Review
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, marginBottom: "20px" }} >
            Are you agree this selected options?
          </Typography>

          <div style={{ margin: "auto", display: "flex" }}>
            <Button
              sx={{ margin: "auto", textTransform: "capitalize" }}
              size="mdeium"
              onClick={handleClose}
            >
              <Typography variant="h6">Cancel</Typography>
            </Button>
            <Link to="/" style={{ margin: "auto" }}>
              <Button
                sx={{ textTransform: "capitalize" }}
                size="mdeium"
                onClick={handleClick}
              >
                <Typography variant="h6">Ok</Typography>
              </Button>
            </Link>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
