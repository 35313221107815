import axios from "axios";

import toast from "react-hot-toast";

const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });


export const getAnnouncementData = () => {
    return dispath => {
        AxiosAPI.get('/api/special').then((res) => {
            if (res.data.success) {
                let data_array = [...res.data.data];
                let new_array = [];
                for (var i = 0; i < data_array.length; i++) {
                    let status = "";
                    if (new Date((data_array[i]).Expire) > new Date(Date.now())) {
                        status = "Actived";
                    }
                    else {
                        status = "Expired";
                    }
                    let json_data = {
                        ...data_array[i],
                        Stauts: status
                    }
                    new_array.push(json_data);
                }
                dispath({
                    type: "Set_Announcement_Data",
                    data: new_array
                })
            }
        });
    }

}

export const setAnnouncementData = (returnArray) => {
    return dispath => {
        let data_array = [...returnArray];
        let new_array = [];
        for (var i = 0; i < data_array.length; i++) {
            let status = "";
            if (new Date((data_array[i]).Expire) > new Date(Date.now())) {
                status = "Actived";
            }
            else {
                status = "Expired";
            }
            let json_data = {
                ...data_array[i],
                Stauts: status
            }
            new_array.push(json_data);
        }
        dispath({
            type: "Set_Announcement_Data",
            data: new_array
        })
    }

}

export const deleteAnnouncementData = (id) => {
    return dispath => {
        AxiosAPI.delete(`/api/special/${id}`).then(res => {
            if (res.data.success) {
                dispath({
                    type: "Set_Announcement_Data",
                    data: res.data.data
                })
                //   setAnnouncement(Announcement.filter(val => val._id !== id))
            }
        });
    }
}

export const addAnnouncementData = (Topic, Expire, Post, Type, newArray, numberType) => {
    return dispath => {

        let now_time = new Date();

        if (numberType === "hour") {
            now_time.setHours(now_time.getHours() + Expire);
        }
        else {
            now_time.setMinutes(now_time.getMinutes() + Expire);
        }

        AxiosAPI.post("/api/special", {
            topic: Topic,
            status: "Active",
            expire: now_time,
            post: Post,
            type: Type,
            airportInfo: newArray
        }).then(res => {
            if (res.data.success) {
                if (res.data.message !== "Update is Okay") {
                    dispath({
                        type: "Set_Announcement_Data",
                        data: res.data.data
                    })
                }
                else {
                    dispath({
                        type: "Set_Announcement_Data",
                        data: res.data.data
                    })
                }
            }
        }).catch(err => {
            toast.error("check your input data");
        })
    }
}