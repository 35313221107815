import axios from "axios";

const AxiosAPI = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

export const setAirportData = (inputData) => {
    return dispath => {
        dispath({
            type: "Set_Airport_Data",
            data: inputData
        })
    }
}

