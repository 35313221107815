
export const updateImagesData = [
    { _id: "1", AirName: "Air France", status: false },
    { _id: "2", AirName: "American Airlines", status: false },
    { _id: "3", AirName: "BRITICH AIRWAYS", status: false },//
    { _id: "4", AirName: "Delta Air lines", status: false },
    { _id: "5", AirName: "Ethiopian Airlines", status: false },
    { _id: "6", AirName: "Lufthansa", status: false },
    { _id: "7", AirName: "Qatar Airways", status: false },
    { _id: "8", AirName: "Royal Air Maroc", status: false },
    { _id: "9", AirName: "KLM", status: false },
    { _id: "10", AirName: "Singapore Airlines", status: false },
    { _id: "11", AirName: "South Africa Airways", status: false },
    { _id: "12", AirName: "Turkish AirLines", status: false }
]

export const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'airport', headerName: 'Airport', width: 130 },
    { field: 'wing', headerName: 'Wing', width: 130 }
];

export const rows = [
    { id: 1, airport: 'MMA', wing: 'arrival' },
    { id: 2, airport: 'MMA', wing: 'departure' },
    { id: 3, airport: 'Abuja', wing: 'arrival' },
    { id: 4, airport: 'Abuja', wing: 'departure' },
    { id: 5, airport: 'Kano', wing: 'arrival' },
    { id: 6, airport: 'Kano', wing: 'departure' },
    { id: 7, airport: 'PHC', wing: 'arrival' },
    { id: 8, airport: 'PHC', wing: 'departure' },
];

export const AirportData = [
    {
      AirName: "MMA", terminal: [
        { terName: "1", wing: ["arrival", "departure"] },
        { terName: "2", wing: ["arrival", "departure"] }
      ]
    },
    {
      AirName: "Abuja", terminal: [{ terName: "1", wing: ["arrival", "departure"] }]
    },
    {
      AirName: "Kano", terminal: [{ terName: "1", wing: ["arrival", "departure"] }]
    },
    {
      AirName: "PHC", terminal: [{ terName: "1", wing: ["arrival", "departure"] }]
    }
  ]