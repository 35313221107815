import React from 'react';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Box from '@mui/material/Box';
import Announcement from './Announcement.jsx';
import imageArray from '../data/Airport.js';
import { getArrivalData, setArrivalData } from '../Redux/Actions/Arrival/index.js';
import { useArrival } from '../context/Arrival.js';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Arrival = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [arrivals] = useArrival();
    const [currentTime, setcurrentTime] = useState("");
    const [state, set_state] = useState("image");
    const [video_array, set_video_array] = useState([]);
    const [image_array, set_image_array] = useState([]);
    const [videoState, setVideoState] = useState(0);
    const [tableArray, settableArray] = useState([]);

    const Airport = useSelector(state => state.Airport.Airport);
    const companyInfo = useSelector(state => state.Arrival.Arrival);


    const setTime = () => {
        let hour = new Date(Date.now()).getHours();
        let min = new Date(Date.now()).getMinutes();
        let hourStr = "";
        let minStr = "";
        if (hour < 10) {
            hourStr = "0" + hour;
        } else {
            hourStr = hour;
        }
        if (min < 10) {
            minStr = "0" + min;
        } else {
            minStr = min;
        }
        let string = `${hourStr}:${minStr}`;
        setcurrentTime(string);
    }

    const timeFilter = (str) => {
        // const str = "22:00:00";
        return str?.slice(0, 5);
    }
    const changeState = (index) => {
        if (index < video_array.length - 1) {
            const updateIndex = index + 1;
            setVideoState(updateIndex);
        }
        if (videoState >= video_array.length) {
            let len = image_array.length;
            if (len !== 0) {
                set_state("image")
            }
            else {
                set_state("middle_image");
            }
        }
        if (image_array.length === 0 && index >= video_array.length - 1) {
            setVideoState(0);
        }

        if (image_array.length > 0 && index >= video_array.length - 1) {
            set_state("image");
            setVideoState(0)
        }

    }

    useEffect(() => {
        if (state === "m_image") {
            set_state("image")
        }
        let len = image_array.length;
        // let len1 = video_array.length;
        if (state === "image" && len !== 0) {
            if (video_array.length !== 0) {
                setTimeout(() => {
                    set_state("movie");
                }, 20000 * image_array.length);
            }
        }
    }, [state, image_array, video_array])

    useEffect(() => {
        if (companyInfo.length !== 0) {
            let nwArray = companyInfo.filter(val => {
                if (val.AirportInfo.airport === Airport.airport &&
                    val.AirportInfo.wing === Airport.wing) {
                    return true;
                }
                else {
                    return false;
                }
            })

            let new_array = nwArray.filter(val => val.type === "video");
            set_video_array([...new_array]);
            let new_array1 = nwArray.filter(val => val.type === "image");
            set_image_array(new_array1);
            set_state("m_image");
            if (new_array1.length === 0) {
                set_state("movie");
            }
        }
        else {
            set_video_array([]);
            set_image_array([]);
        }
    }, [companyInfo, Airport])


    useEffect(() => {
        const fff = ["ABJ", "ACC", "COO", "DLA", "FNA", "JNB", "LGW"];

        if (arrivals.length !== 0) {
            
            let filterArray = arrivals.filter((val, index) => {
                if (val.airlineName === "Air Peace" && fff.findIndex((mem) => val.originAirportCode === mem) === -1) {

                    return false;
                }
                else {
                    return true;
                }
            })
            let count = Math.ceil((document.documentElement.clientHeight - 200) / 60);
            let arrayCount = filterArray.length;
            let newArray = [];
            let virtualArray = [];
            for (let index = 0; index < Math.ceil(arrayCount / count); index++) {
                index === 0 ?
                    newArray.push([""].concat(filterArray.slice(0, count))) :
                    newArray.push(filterArray.slice(index * count - 1, Math.min((index + 1) * count - 1, arrayCount)));
            }
            let len = newArray[newArray.length - 1].length;
            for (let i = 0; i <= count - len; i++) {
                newArray[Math.ceil(arrayCount / count) - 1].push(virtualArray);
            }
            settableArray(newArray);
        }

    }, [arrivals])

    useEffect(() => {
        if (!Airport.set) {
            navigate("/");
        }
        dispatch(getArrivalData());
        const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

        socket.on("Arrival", (data) => {
            let sourceArray = [...JSON.parse(data)];
            dispatch(setArrivalData(sourceArray));
        });
        setInterval(() => {
            setTime();
        }, 1000);
    }, []);

    return (
        <div className='back'>

            <div className='fids_container'>
                <Box className="fids_box"
                    style={{
                        flex: 8, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                        overflowY: 'hidden', border: " 5px solid black"
                    }}>
                    < AutoPlaySwipeableViews interval={20000} style={{ overflowY: 'hidden', height: "auto" }}>
                        {
                            tableArray.map((arrayItem, index) =>
                                <div style={{ overflowY: "hidden" }} key={index}>
                                    <table style={{ borderCollapse: 'collapse' }}>
					<thead style={{ backgroundColor: 'white' }}>
                                            <tr style={{ backgroundColor: "white", height: "100px" }}>
                                                <td>
                                                    <img src="/image/landOn.png" className='logo' alt="logo" />
                                                </td>
                                                <td colSpan={6} style={{ textAlign: 'center', }} className='title'>ARRIVAL</td>
                                                <td colSpan={2}>
                                                    <img style={{ height: "100px" }} src="/image/mark.jpeg" className='logo' alt="logo" />
                                                </td>
                                                <td colSpan={4}>
                                                    <div className='time'>{currentTime}</div>
                                                </td>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {arrayItem.map((info, index) =>

                                                index === 0 ?
                                                    <tr key={index} className='header' style={{ backgroundColor: "black", textAlign: 'center', height: "37px" }}>
                                                        <th style={{ textAlign: 'center' }} colSpan={2}>AIRLINE</th>
                                                        <th>FLIGHT</th>
							<th>PARTNER</th>
							<th>ETA</th>
                                                        <th>ATA</th>
                                                        <th>ORIGIN</th>
                                                        <th>TERMINAL</th>
                                                        <th>BAGGAGE</th>
                                                        <th style={{ textAlign: "center" }}>STATUS</th>
                                                        <td rowSpan={arrayItem.length + 1} style={{ alignContent: "start" }} className="fids_carousel">
                                                            <div>
                                                                {
                                                                    state === "image" ? <Box className="fids_carousel" sx={{}}>
                                                                        < AutoPlaySwipeableViews className='carouselContainer' interval={20000} style={{ overflowY: 'hidden' }}>
                                                                            {image_array.length !== 0 ? image_array.map((company, index) => (
                                                                                <div key={index} style={{ border: " 5px solid black" }}>
                                                                                    <img className="carousel_image" style={{ objectFit: 'fill' }}
                                                                                        src={`${process.env.REACT_APP_BACKEND_URL}/${company.changeFileName}`} alt={company.companyName} />
                                                                                    <div className="overlay">{company.companyName}</div>
                                                                                </div>
                                                                            )) : <div></div>}
                                                                        </AutoPlaySwipeableViews>

                                                                    </Box>
                                                                        :
                                                                        <Box className="fids_carousel" id="videocap" sx={{ flex: 2, overflowY: 'hidden' }} >
                                                                            <div style={{ border: " 5px solid black", overflow: "hidden" }}>
                                                                                {
                                                                                    video_array?.map((val, index) => {
                                                                                        if (index === videoState) {
                                                                                            return (
                                                                                                <video className='carouselContainer' id="video1" preload='auto' style={{ objectFit: "fill", overflow: "hidden", border: " 5px solid black" }}
                                                                                                    autoPlay onEnded={() => changeState(videoState)} muted={state === "movie"}>
                                                                                                    <source key={videoState} src={`${process.env.REACT_APP_BACKEND_URL}/${val.changeFileName}`}
                                                                                                        type="video/mp4" autoPlay ></source>
                                                                                                </video>
                                                                                            )
                                                                                        } else {
                                                                                            return null;
                                                                                        }
                                                                                    })}
                                                                            </div>
                                                                        </Box>
                                                                }
                                                            </div>
                                                            <div></div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <tr key={index} style={{ height: "60px", padding: 0, textAlign: 'center' }}>
                                                        <td style={{ height: "auto", textAlign: 'right', width: "60px" }}>{info.airlineName != null ?
                                                            info.airlineName === "Air Peace" ? <img height={"60px"} width={"60px"}
                                                                alt="company logo" src={`/image/${info.airlineName}.png`} /> :
                                                                <img height={"60px"} width={"60px"} alt={info.airlineCode} src={
                                                                    Array.isArray(imageArray.find(val =>
                                                                        val.airlineCode === info.airlineCode)?.URL) ? imageArray.find(val =>
                                                                            val.airlineCode === info.airlineCode)?.URL[0] : imageArray.find(val =>
                                                                                val.airlineCode === info.airlineCode)?.URL} /> : null}
                                                        </td>
                                                        <td style={{ width: "120px", textAlign: "left" }}>{info.airlineName}</td>
                                                        <td>{info.flight}</td>
							<td>
								{info.codeSharesAsCodes ? (Array.isArray(info.codeSharesAsCodes) ? (
								<>
									{info.codeSharesAsCodes.map((code, index) => (<div key={index}>{code}</div>))}
								 </>
									) : (info.codeSharesAsCodes.split(',').map((code, index) => (
								<div key={index}>{code.trim()}</div>)))) : (
								<div> </div>
								)}
							</td>
							<td>{timeFilter(info.scheduledTime)}</td>
                                                        <td style={{ color: "yellow" }}>{timeFilter(info.estimatedTime)}</td>
                                                        <td>{info.displayname}</td>
                                                        <td>{info.terminal}</td>
                                                        <td>{info.baggage}</td>
                                                        <td style={{ alignContent: "center" }}>
                                                            {info.status != null ? <button style={{ fontSize: "18px" }} className={info.status + " button"}>{info.status}</button> : null}
                                                        </td>
                                                    </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    </AutoPlaySwipeableViews>


                </Box>

            </div>
            <Announcement style={{ height: '90px' }} />
        </div >
    )
}

export default Arrival;
