const imageArray = [
  {
    "airlineCode": "0B",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/0B_50x50_bullet.png"
  },
  {
    "airlineCode": "4O",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/4O_50x50_bullet.png"
  },
  {
    "airlineCode": "5J",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/5J_50x50_bullet.png"
  },
  {
    "airlineCode": "6E",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/6E_50x50_bullet.png"
  },
  {
    "airlineCode": "9W",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/9W_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/9W_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "A3",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/A3_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/A3_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AC",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AC_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AC_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AD",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/AD_50x50_bullet.png"
  },
  {
    "airlineCode": "AF",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AF_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AF_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AH",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AH_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AH_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AI",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AI_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AI_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AM",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AM_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AM_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AR",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/AR_50x50_bullet.png"
  },
  {
    "airlineCode": "AS",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/AS_50x50_bullet.png"
  },
  {
    "airlineCode": "AT",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AT_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AT_65x240_trans.png"
    ]
  },
  {
    "airlineCode": "AV",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AV_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AV_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AY",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AY_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AY_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "AZ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/AZ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/AZ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "B2",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/B2_50x50_bullet.png"
  },
  {
    "airlineCode": "B6",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/B6_50x50_bullet.png"
  },
  {
    "airlineCode": "BA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/BA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/BA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "BE",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/BE_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/BE_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "BG",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/BG_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/BG_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "BI",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/BI_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/BI_65x240_trans.png"
    ]
  },
  {
    "airlineCode": "BR",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/BR_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/BR_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "BT",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/BT_50x50_bullet.png"
  },
  {
    "airlineCode": "BW",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/BW_50x50_bullet.png"
  },
  {
    "airlineCode": "CA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/CA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/CA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "CI",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/CI_50x50_bullet.png"
  },
  {
    "airlineCode": "CM",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/CM_50x50_bullet.png"
  },
  {
    "airlineCode": "CX",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/CX_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/CX_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "CZ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/CZ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/CZ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "DL",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/DL_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/DL_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "DY",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/DY_50x50_bullet.png"
  },
  {
    "airlineCode": "EI",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/EI_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/EI_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "EK",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/EK_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/EK_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "EL",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/EL_50x50_bullet.png"
  },
  {
    "airlineCode": "EQ",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/EQ_50x50_bullet.png"
  },
  {
    "airlineCode": "ET",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/ET_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/ET_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "EW",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/EW_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/EW_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "EY",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/EY_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/EY_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "F9",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/F9_50x50_bullet.png"
  },
  {
    "airlineCode": "FB",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/FB_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/FB_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "FI",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/FI_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/FI_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "FJ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/FJ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/FJ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "FL",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/FL_50x50_bullet.png"
  },
  {
    "airlineCode": "FM",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/FM_50x50_bullet.png"
  },
  {
    "airlineCode": "FN",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/FN_50x50_bullet.png"
  },
  {
    "airlineCode": "FR",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/FR_50x50_bullet.png"
  },
  {
    "airlineCode": "FY",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/FY_50x50_bullet.png"
  },
  {
    "airlineCode": "FZ",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/FZ_50x50_bullet.png"
  },
  {
    "airlineCode": "G3",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/G3_50x50_bullet.png"
  },
  {
    "airlineCode": "G4",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/G4_50x50_bullet.png"
  },
  {
    "airlineCode": "G9",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/G9_50x50_bullet.png"
  },
  {
    "airlineCode": "GA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/GA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/GA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "GF",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/GF_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/GF_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "GS",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/GS_50x50_bullet.png",
      "hhttps://uds-static.api.aero/airline_logos/gatelogo/GS_65x240_trans.png"
    ]
  },
  {
    "airlineCode": "HA",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/HA_50x50_bullet.png"
  },
  {
    "airlineCode": "HU",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/HU_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/HU_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "HY",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/HY_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/HY_65x240_trans.png"
    ]
  },
  {
    "airlineCode": "IB",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/IB_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/IB_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "IG",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/IG_50x50_bullet.png"
  },
  {
    "airlineCode": "IR",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/IR_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/IR_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "IT",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/IT_50x50_bullet.png"
  },
  {
    "airlineCode": "J2",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/J2_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/J2_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "JD",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/JD_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/JD_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "JJ",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/JJ_50x50_bullet.png"
  },
  {
    "airlineCode": "JL",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/JL_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/JL_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "JP",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/JP_50x50_bullet.png"
  },
  {
    "airlineCode": "JT",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/JT_50x50_bullet.png"
  },
  {
    "airlineCode": "JU",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/JU_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/JU_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "KC",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/KC_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/KC_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "KE",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/KE_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/KE_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "KL",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/KL_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/KL_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "KM",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/KM_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/KM_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "KQ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/KQ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/KQ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "KU",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/KU_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/KU_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "KX",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/KX_50x50_bullet.png"
  },
  {
    "airlineCode": "LA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/LA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/LA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "LG",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/LG_50x50_bullet.png"
  },
  {
    "airlineCode": "LH",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/LH_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/LH_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "LI",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/LI_50x50_bullet.png"
  },
  {
    "airlineCode": "LN",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/LN_50x50_bullet.png"
  },
  {
    "airlineCode": "LO",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/LO_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/LO_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "LQ",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/LQ_50x50_bullet.png"
  },
  {
    "airlineCode": "LS",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/LS_50x50_bullet.png"
  },
  {
    "airlineCode": "LX",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/LX_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/LX_65x240_trans.png"
    ]
  },
  {
    "airlineCode": "LY",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/LY_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/LY_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "ME",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/ME_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/ME_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "MF",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/MF_50x50_bullet.png"
  },
  {
    "airlineCode": "MH",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/MH_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/MH_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "MK",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/MK_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/MK_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "MS",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/MS_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/MS_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "MU",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/MU_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/MU_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "NH",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/NH_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/NH_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "NZ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/NZ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/NZ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "OB",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/OB_50x50_bullet.png"
  },
  {
    "airlineCode": "OS",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/OS_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/OS_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "OU",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/OU_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/OU_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "OZ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/OZ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/OZ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "PC",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/PC_50x50_bullet.png"
  },
  {
    "airlineCode": "PD",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/PD_50x50_bullet.png"
  },
  {
    "airlineCode": "PK",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/PK_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/PK_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "PR",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/PR_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/PR_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "PS",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/PS_50x50_bullet.png"
  },
  {
    "airlineCode": "PX",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/PX_50x50_bullet.png"
  },
  {
    "airlineCode": "QF",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/QF_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/QF_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "QR",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/QR_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/QR_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "QS",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/QS_50x50_bullet.png"
  },
  {
    "airlineCode": "RJ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/RJ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/RJ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "RO",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/RO_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/RO_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "S7",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/S7_50x50_bullet.png"
  },
  {
    "airlineCode": "SA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/SA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/SA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "SB",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/SB_50x50_bullet.png"
  },
  {
    "airlineCode": "SG",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/SG_50x50_bullet.png"
  },
  {
    "airlineCode": "SK",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/SK_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/SK_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "SN",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/SN_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/SN_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "SQ",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/SQ_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/SQ_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "SU",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/SU_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/SU_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "SV",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/SV_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/SV_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "SY",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/SY_50x50_bullet.png"
  },
  {
    "airlineCode": "TG",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/TG_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/TG_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "TK",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/TK_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/TK_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "TP",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/TP_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/TP_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "TU",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/TU_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/TU_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "TX",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/TX_50x50_bullet.png"
  },
  {
    "airlineCode": "U2",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/U2_50x50_bullet.png"
  },
  {
    "airlineCode": "U6",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/U6_50x50_bullet.png"
  },
  {
    "airlineCode": "UA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/UA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/UA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "UL",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/UL_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/UL_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "UT",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/UT_50x50_bullet.png"
  },
  {
    "airlineCode": "UX",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/UX_50x50_bullet.png"
  },
  {
    "airlineCode": "V7",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/V7_50x50_bullet.png"
  },
  {
    "airlineCode": "VA",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/VA_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/VA_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "VB",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/VB_50x50_bullet.png"
  },
  {
    "airlineCode": "VH",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/VH_50x50_bullet.png"
  },
  {
    "airlineCode": "VJ",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/VJ_50x50_bullet.png"
  },
  {
    "airlineCode": "VN",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/VN_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/VN_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "VS",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/VS_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/VS_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "VT",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/VT_50x50_bullet.png"
  },
  {
    "airlineCode": "VY",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/VY_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/VY_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "W3",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/W3_50x50_bullet.png"
  },
  {
    "airlineCode": "WG",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/WG_50x50_bullet.png"
  },
  {
    "airlineCode": "WN",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/WN_50x50_bullet.png"
  },
  {
    "airlineCode": "WS",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/WS_50x50_bullet.png"
  },
  {
    "airlineCode": "WW",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/WW_50x50_bullet.png"
  },
  {
    "airlineCode": "WX",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/WX_50x50_bullet.png"
  },
  {
    "airlineCode": "WY",
    "URL": [
      "https://uds-static.api.aero/airline_logos/bulletlogo/WY_50x50_bullet.png",
      "https://uds-static.api.aero/airline_logos/gatelogo/WY_65x410_trans.png"
    ]
  },
  {
    "airlineCode": "Y4",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/Y4_50x50_bullet.png"
  },
  {
    "airlineCode": "Z8",
    "URL": "https://uds-static.api.aero/airline_logos/bulletlogo/Z8_50x50_bullet.png"
  }
]

export default imageArray;