// import { Arrival } from "@mui/icons-material";

const initialState = {
    Arrival: []
}

const ArrivalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "Set_Arrival_Data":
            return {
                ...state,
                Arrival: action.data
            }
        default:
            return state;
    }
}

export default ArrivalReducer;