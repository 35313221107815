import React from 'react';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Box from '@mui/material/Box';
import { useDeparture } from '../context/Departure.js';
import { getDepartureData, setDepartureData } from '../Redux/Actions/Departure/index.js';
import Announcement from './Announcement.jsx';
import imageArray from '../data/Airport.js';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const Departure = () => {
    const navigate = useNavigate();

    const [departures, setDepartures] = useDeparture();
    const [airport, setairport] = useState([]);
    const [state, set_state] = useState("image");
    const [currentTime, setcurrentTime] = useState("");
    const [video_array, set_video_array] = useState([]);
    const [image_array, set_image_array] = useState([]);
    const [videoState, setVideoState] = useState(0);
    const [tableArray, settableArray] = useState([]);

    const companyInfo = useSelector(state => state.Departure.Departure);
    const Airport = useSelector(state => state.Airport.Airport);
    const dispatch = useDispatch();

    const setTime = () => {
        let hour = new Date(Date.now()).getHours();
        let min = new Date(Date.now()).getMinutes();
        let hourStr = "";
        let minStr = "";
        if (hour < 10) {
            hourStr = "0" + hour;
        } else {
            hourStr = hour;
        }
        if (min < 10) {
            minStr = "0" + min;
        } else {
            minStr = min;
        }
        let string = `${hourStr}:${minStr}`;
        setcurrentTime(string);
    }

    const timeFilter = (str) => {
        return str?.slice(0, 5);
    }

    const changeState = (index) => {
        if (index < video_array.length - 1) {
            const updateIndex = index + 1;
            setVideoState(updateIndex);
        }
        if (videoState >= video_array.length) {
            let len = image_array.length;
            if (len !== 0) {
                set_state("image")
            }
            else {
                set_state("middle_image");
            }
        }
        if (image_array.length === 0 && index >= video_array.length - 1) {
            setVideoState(0);
        }

        if (image_array.length > 0 && index >= video_array.length - 1) {
            set_state("image");
            setVideoState(0)
        }
    }

    useEffect(() => {
        if (state === "m_image") {
            set_state("image")
        }
        let len = image_array.length;

        if (state === "image" && len !== 0) {
            if (video_array.length !== 0) {
                setTimeout(() => {
                    set_state("movie");
                }, 3000 * image_array.length);
            }
        }

    }, [state, image_array, video_array])

    useEffect(() => {
        if (companyInfo.length !== 0) {
            let nwArray = companyInfo.filter(val => {
                if (val.AirportInfo.airport === Airport.airport &&
                    val.AirportInfo.wing === Airport.wing) {
                    return true;
                }
                else {
                    return false;
                }
            })

            let new_array = nwArray.filter(val => val.type === "video");
            set_video_array([...new_array]);
            let new_array1 = nwArray.filter(val => val.type === "image");
            set_image_array(new_array1);
            set_state("m_image");
            if (new_array1.length === 0) {
                set_state("movie");
            }
        }
        else {
            set_image_array([]);
            set_video_array([]);
        }
    }, [companyInfo, Airport])

    useEffect(() => {
        if (departures.length !== 0) {

            const fff = ["ABJ", "ACC", "COO", "DLA", "FNA", "JNB", "LGW"];

            let filterArray = departures.filter((val, index) => {
                if (val.airlineName === "Air Peace" && fff.findIndex((mem) => val.destinationAirportCode === mem) === -1) {

                    return false;
                }
                else {
                    return true;
                }
            });

            if (airport.length !== 0) {
                let temp = [...filterArray];
                let returnArray = [];
                for (let index = 0; index < temp.length; index++) {
                    const val = temp[index];
                    let aaa = airport.findIndex(value => { return value.FlightNumber === val.flightNumber });
                    if (aaa !== -1) {
                        returnArray.push({
                            ...val,
                            status: airport[aaa].Status
                        });
                    }
                    else {
                        returnArray.push(val)
                    }
                }
                let count = Math.ceil((document.documentElement.clientHeight - 200) / 60);
                let arrayCount = filterArray.length;
                let newArray = [];
                let virtualArray = [];
                for (let index = 0; index < Math.ceil(arrayCount / count); index++) {
                    index === 0 ?
                        newArray.push([""].concat(filterArray.slice(0, count))) :
                        newArray.push(filterArray.slice(index * count - 1, Math.min((index + 1) * count - 1, arrayCount)));
                }
                let len = newArray[newArray.length - 1].length;
                for (let i = 0; i <= count - len; i++) {
                    newArray[Math.ceil(arrayCount / count) - 1].push(virtualArray);
                }
                settableArray(newArray);
            }
            else {
                let count = Math.ceil((document.documentElement.clientHeight - 200) / 60);
                let arrayCount = filterArray.length;
                let newArray = [];
                let virtualArray = [];
                for (let index = 0; index < Math.ceil(arrayCount / count); index++) {
                    index === 0 ?
                        newArray.push([""].concat(filterArray.slice(0, count))) :
                        newArray.push(filterArray.slice(index * count - 1, Math.min((index + 1) * count - 1, arrayCount)));
                }
                let len = newArray[newArray.length - 1].length;
                for (let i = 0; i <= count - len; i++) {
                    newArray[Math.ceil(arrayCount / count) - 1].push(virtualArray);
                }
                settableArray(newArray);
            }
        }

    }, [departures, airport])
    useEffect(() => {
        if (airport.length !== 0) {
            let temp = [...departures];
            let returnArray = [];
            for (let index = 0; index < temp.length; index++) {
                const val = temp[index];
                let aaa = airport.findIndex(value => { return value.FlightNumber === val.flightNumber });
                if (aaa !== -1) {
                    returnArray.push({
                        ...val,
                        status: airport[aaa].Status
                    });
                }
                else {
                    returnArray.push(val)
                }
            }
            setDepartures(returnArray);
        }
    }, [airport])
    useEffect(() => {
        if (!Airport.set) {
            navigate("/");
        }
        dispatch(getDepartureData());
        const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

        socket.on("Departure", (data) => {
            let sourceArray = [...JSON.parse(data)];
            dispatch(setDepartureData(sourceArray));
        });

        socket.on("status", (data) => {
            let sourceArray = [...JSON.parse(data)];
            setairport(sourceArray);
        });

        setInterval(() => {
            setTime();
        }, 1000);
    }, []);


    return (
        <div className='back' >
            <div className='fids_container'>

                <Box className="fids_box" sx={{
                    flex: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between', overflowY: 'hidden'
                }}>
                    < AutoPlaySwipeableViews interval={20000} style={{ overflowY: 'hidden', height: "auto", border: " 5px solid black" }}>

                        {
                            tableArray.map((arrayItem, index) =>

                                <div style={{ overflowY: "hidden" }} key={index}>
                                    <table>
                                        <thead>
                                            <tr style={{ backgroundColor: "white", height: "100px" }}>
                                                <td>
                                                    <img src="/image/takeOff.png" className='logo' alt="logo" />
                                                </td>
                                                <td colSpan={6} style={{ textAlign: "center" }} className='title'>DEPARTURE</td>
                                                <td colSpan={2}>
                                                    <img style={{ height: "100px" }} src="/image/mark.jpeg" className='logo' alt="logo" />
                                                </td>
                                                <td colSpan={4}>
                                                    <div className='time'>{currentTime}</div>
                                                </td>

                                            </tr>


                                        </thead>
                                        <tbody>
                                            {arrayItem.map((info, index) =>
                                                index === 0 ?
                                                    <tr className='header' key={index} style={{ backgroundColor: "black", textAlign: 'center', height: "37px" }}>
                                                        <th style={{ textAlign: 'center' }} colSpan={2}>AIRLINE</th>
                                                        <th>FLIGHT</th>
							<th>PARTNER</th>
							<th>ETD</th>
                                                        <th>ATD</th>
                                                        <th>DESTINATION</th>
                                                        <th>TERMINAL</th>
                                                        <th>GATE</th>
                                                        <th>STATUS</th>
                                                        <td rowSpan={arrayItem.length + 1} style={{ alignContent: "start" }} className="fids_carousel">
                                                            {
                                                                state === "image" ? <Box className="fids_carousel" sx={{}}>
                                                                    < AutoPlaySwipeableViews className='carouselContainer' interval={20000} style={{ overflowY: 'hidden' }}>
                                                                        {Array.isArray(image_array) ? image_array.map((company, index) => (
                                                                            <div key={index} style={{ border: " 5px solid black" }}>
                                                                                <img className="carousel_image" style={{ width: '100%', objectFit: 'fill' }} src={`${process.env.REACT_APP_BACKEND_URL}/${company.changeFileName}`} alt={company.companyName} />
                                                                                <div className="overlay">{company.companyName}</div>
                                                                            </div>
                                                                        )) : <div></div>}
                                                                    </AutoPlaySwipeableViews>

                                                                </Box>
                                                                    :
                                                                    <Box className="fids_carousel" id="videocap" sx={{ flex: 2, overflowY: 'hidden' }} >
                                                                        <div style={{ border: " 5px solid black", overflow: "hidden" }}>
                                                                            {
                                                                                video_array?.map((val, index) => {
                                                                                    if (index === videoState) {
                                                                                        return (
                                                                                            <video className='carouselContainer' id="video1" preload='auto' style={{ objectFit: "fill", overflow: "hidden", border: " 5px solid black" }}
                                                                                                autoPlay onEnded={() => changeState(videoState)} muted={state === "movie"}>
                                                                                                <source key={videoState} src={`${process.env.REACT_APP_BACKEND_URL}/${val.changeFileName}`}
                                                                                                    type="video/mp4" autoPlay ></source>
                                                                                            </video>
                                                                                        )
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })}
                                                                        </div>
                                                                    </Box>

                                                            }
                                                        </td>
                                                    </tr> :
                                                    <tr key={index} style={{ height: "60px", padding: "0px", textAlign: "center" }}>
                                                        <td style={{ height: "auto", textAlign: 'right', width: "60px" }}>{info.airlineName != null ?
                                                            info.airlineName === "Air Peace" ? <img height={"60px"} width={"60px"}
                                                                alt="company logo" src={`/image/${info.airlineName}.png`} /> :
                                                                <img height={"60px"} width={"60px"} alt={info.airlineCode} src={
                                                                    Array.isArray(imageArray.find(val =>
                                                                        val.airlineCode === info.airlineCode)?.URL) ? imageArray.find(val =>
                                                                            val.airlineCode === info.airlineCode)?.URL[0] : imageArray.find(val =>
                                                                                val.airlineCode === info.airlineCode)?.URL} /> : null}
                                                        </td>
                                                        <td style={{ width: "120px", textAlign: "left" }}>{info.airlineName}</td>
							<td>{info.flight}</td>
							<td>
								{info.codeSharesAsCodes ? (Array.isArray(info.codeSharesAsCodes) ? (
							<>
								{info.codeSharesAsCodes.map((code, index) => (
								<div key={index}>{code}</div>))}
							</>
								) : (info.codeSharesAsCodes.split(',').map((code, index) => (
								<div key={index}>{code.trim()}</div>)))) : (
								<div> </div>
								)}
							</td>
                                                        <td>{timeFilter(info.scheduledTime)}</td>
                                                        <td style={{ color: "yellow" }}>{timeFilter(info.estimatedTime)}</td>
                                                        <td>{info.displayname}</td>
                                                        <td>{info.terminal}</td>
                                                        <td>{info.gate}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {info.status != null ? <button style={{ fontSize: "18px" }} className={info.status + " button"}>{info.status}</button> : null}
                                                        </td>
                                                    </tr>
                                            )
                                            }

                                        </tbody>
                                    </table>

                                </div>
                            )
                        }

                    </AutoPlaySwipeableViews>



                </Box>



            </div>
            <Announcement style={{ height: '90px' }} />
        </div>
    )
}

export default Departure;
